import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { enableAutosave } from '../../redux/actions/general/autosave';
import styles from '../../styles/forms/working_set_specifications/AttributesForm.scss';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import * as _ from 'lodash';
import EditIcon from '../../assets/images/edit-16x16.svg';
import Input from '../../components/Input';
import Select from '../../components/Select';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
  toBoolean,
} from '../../core/utils';
import ColorPicker from '../../components/ColorPicker';
import CloseMediumIcon from '../../assets/images/close-middle-15x15.svg';
import classNames from 'classnames';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import { setNotification } from '../../redux/actions/general/notification';
import MaterialPicker from '../../components/MaterialPicker';
import GrabberIcon from '../../assets/images/grabber-16x16.svg';
import { ReactSortable } from 'react-sortablejs';
import autobind from 'autobind-decorator';
import {
  deleteSpecificationProductAttributeField,
  readWorkingSetSpecification,
  reorderSpecificationProductAttributeFields,
} from '../../redux/actions/table/working_set_specifications';
import MultiSelect from '../../components/MultiSelect';
import Tooltip from '../../components/Tooltip';
import TexturePicker from '../../components/TexturePicker';

@reduxForm({
  enableReinitialize: true,
})
@mapStateToProps((state) => ({
  store: state.table.working_set_specifications,
  application_language_id: state.auth.language_id,
}))
@subscribe(Localization, 'localization')
class AttributesForm extends Component {
  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  @autobind
  _changeOrder(attributes) {
    setTableParams('working_set_specifications', {
      flyout: {
        ...this.props.store.flyout,
        attribute_groups: _.map(
          this.props.store.flyout.attribute_groups,
          (attribute_group) => {
            if (attribute_group.id == this.props.groupId) {
              return {
                ...attribute_group,
                attributes: _.map(attributes, (attribute, i) => ({
                  ...attribute,
                  priority: i,
                })),
              };
            } else {
              return attribute_group;
            }
          }
        ),
      },
    });
  }

  render() {
    const boolean_options = [
      {
        label: __('working-set-specifications.attributes-form.yes'),
        value: '1',
      },
      {
        label: __('working-set-specifications.attributes-form.no'),
        value: '0',
      },
    ];

    const {
      handleSubmit,
      expanded,
      store,
      groupId,
      application_language_id,
      isShared,
    } = this.props;
    const { language_id, flyout } = store;
    const { units, product_attribute_fields } = this.props.localization;

    const attributes = _.sortBy(
      _.find(flyout.attribute_groups, ['id', groupId]).attributes,
      'priority'
    );

    return (
      <Form
        onSubmit={handleSubmit}
        className={classNames(styles.wrapper, expanded && styles.expanded)}
      >
        {!_.isEmpty(attributes) && (
          <ReactSortable
            className={styles.attributes}
            list={attributes}
            setList={this._changeOrder}
            animation={200}
            delayOnTouchStart={true}
            onEnd={() => {
              reorderSpecificationProductAttributeFields(
                this.props.groupId,
                _.map(attributes, 'id')
              ).then(() => {
                setNotification(
                  __(
                    'working-set-specifications.attributes-form.specification-attributes-order-saved'
                  )
                );
              });
            }}
            handle={'.' + styles.grabber}
          >
            {_.map(attributes, (attribute) => {
              const currentAttribute = attribute.template_field_id
                ? _.get(
                    product_attribute_fields,
                    attribute.template_field_id,
                    {}
                  )
                : attribute;
              const type = currentAttribute.type;
              let label = getLocalized(
                currentAttribute.label,
                application_language_id
              );
              label +=
                (attribute.unit_id || currentAttribute.unit_id) &&
                _.includes(['numeric', 'range', 'range_selectable'], type)
                  ? ' (' + _.get(units, [attribute.unit_id, 'symbol']) + ')'
                  : '';

              if (
                type == 'range_selectable' &&
                attribute.range_from &&
                attribute.range_to
              ) {
                label += ` [${_.toFinite(attribute.range_from)} ~ ${_.toFinite(
                  attribute.range_to
                )}]`;
              }

              label += !attribute.template_field_id ? '*' : '';

              const labelTooltip = currentAttribute.description
                ? getLocalized(
                    currentAttribute.description,
                    application_language_id
                  )
                : undefined;

              // for some reason, when redux receives a id for initial value key
              // it registers as many fields as big key is, i.e. if initial value
              // key was 2704, there would be 2704 registered fields for that form
              // with 1 - 2703 being just empty value.
              // "attr-" prefix is striped in _handleSubmit method.
              const fieldName = `attr-${attribute.id}`;

              return (
                <div
                  className={styles.attribute}
                  key={[attribute.id, attribute.template_field_id].join('-')}
                >
                  <div className={styles.grabber}>
                    <GrabberIcon />
                  </div>
                  {type == 'boolean' && (
                    <Field
                      name={fieldName}
                      component={Select}
                      label={label}
                      labelTooltip={labelTooltip}
                      options={boolean_options}
                    />
                  )}
                  {type == 'dropdown_string' && (
                    <Field
                      searchable
                      name={fieldName}
                      noSort
                      component={Select}
                      label={label}
                      labelTooltip={labelTooltip}
                      options={_.map(
                        _.find(
                          this.props.localization.product_attribute_fields,
                          ['id', attribute.template_field_id]
                        ).options,
                        (option) => ({
                          value: option.id,
                          label: getLocalized(option.value, language_id),
                          disabled: toBoolean(option.discontinued),
                        })
                      )}
                    />
                  )}
                  {type == 'dropdown_string_multiple' && (
                    <Field
                      searchable
                      name={fieldName}
                      noSort
                      component={MultiSelect}
                      label={label}
                      labelTooltip={labelTooltip}
                      options={_.map(
                        _.find(
                          this.props.localization.product_attribute_fields,
                          ['id', attribute.template_field_id]
                        ).options,
                        (option) => ({
                          value: option.id,
                          label: getLocalized(option.value, language_id),
                          disabled: toBoolean(option.discontinued),
                        })
                      )}
                    />
                  )}
                  {type == 'dropdown_numeric' && (
                    <Field
                      searchable
                      name={fieldName}
                      noSort
                      component={Select}
                      label={label}
                      labelTooltip={labelTooltip}
                      options={_.map(
                        _.find(
                          this.props.localization.product_attribute_fields,
                          ['id', attribute.template_field_id]
                        ).options,
                        (option) => {
                          let unit = _.get(units, [
                            attribute.unit_id,
                            'symbol',
                          ]);

                          return {
                            value: option.id,
                            label:
                              _.toString(
                                getLocalized(option.value, language_id)
                              ) + (unit ? ' (' + unit + ')' : ''),
                            disabled: toBoolean(option.discontinued),
                          };
                        }
                      )}
                    />
                  )}
                  {type == 'dropdown_numeric_multiple' && (
                    <Field
                      searchable
                      name={fieldName}
                      noSort
                      component={MultiSelect}
                      label={label}
                      labelTooltip={labelTooltip}
                      options={_.map(
                        _.find(
                          this.props.localization.product_attribute_fields,
                          ['id', attribute.template_field_id]
                        ).options,
                        (option) => {
                          let unit = _.get(units, [
                            attribute.unit_id,
                            'symbol',
                          ]);

                          return {
                            value: option.id,
                            label:
                              _.toString(
                                getLocalized(option.value, language_id)
                              ) + (unit ? ' (' + unit + ')' : ''),
                            disabled: toBoolean(option.discontinued),
                          };
                        }
                      )}
                    />
                  )}
                  {type == 'string' && (
                    <Field
                      name={fieldName + '.' + language_id}
                      component={Input}
                      label={label}
                      labelTooltip={labelTooltip}
                    />
                  )}
                  {_.includes(['numeric', 'range_selectable'], type) && (
                    <Field
                      numeric
                      decimal
                      name={fieldName}
                      component={Input}
                      label={label}
                      labelTooltip={labelTooltip}
                    />
                  )}
                  {type == 'color' && (
                    <Field
                      name={fieldName}
                      language={store.language_id}
                      component={ColorPicker}
                      label={label}
                      labelTooltip={labelTooltip}
                      myCompanyColors
                    />
                  )}
                  {type == 'material' && (
                    <Field
                      name={fieldName}
                      component={MaterialPicker}
                      language={language_id}
                      label={label}
                      labelTooltip={labelTooltip}
                    />
                  )}
                  {type == 'texture' && (
                    <Field
                      name={fieldName}
                      component={TexturePicker}
                      language={language_id}
                      label={label}
                      labelTooltip={labelTooltip}
                    />
                  )}
                  {type == 'range' && (
                    <div className={styles.range}>
                      <Tooltip text={labelTooltip}>
                        <div className={styles.label}>{label}</div>
                      </Tooltip>
                      <div className={styles.flex}>
                        <div className={styles.f1}>
                          <Field
                            numeric
                            decimal
                            name={fieldName + '.0'}
                            component={Input}
                          />
                        </div>
                        <span className={styles.minus}>-</span>
                        <div className={styles.f1}>
                          <Field
                            numeric
                            decimal
                            name={fieldName + '.1'}
                            component={Input}
                            align='right'
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {!isShared && (
                    <>
                      <div className={styles.edit}>
                        <EditIcon
                          onClick={(e) => {
                            e.stopPropagation();

                            setTableParams('working_set_specifications', {
                              edit_specification_product_attribute_group_field_wizard:
                                attribute.id,
                              template_field_id: attribute.template_field_id,
                            });
                          }}
                        />
                      </div>

                      <div className={styles.remove}>
                        <CloseMediumIcon
                          onClick={(e) => {
                            e.stopPropagation();

                            showAlertbox({
                              color: 'red',
                              title: __('general.alert.are-you-sure'),
                              description: __(
                                'working-set-specifications.attributes-form.are-you-sure-remove-attribute'
                              ),
                              buttons: [
                                {
                                  color: 'lightGray',
                                  text: __('general.alert.no-close'),
                                  onClick: 'close',
                                },
                                {
                                  color: 'gray',
                                  text: __('general.alert.yes-delete'),
                                  onClick: () =>
                                    deleteSpecificationProductAttributeField(
                                      attribute.id
                                    ).then(() => {
                                      hideAlertbox();

                                      readWorkingSetSpecification(
                                        flyout.id
                                      ).then(({ response }) => {
                                        setTableParams(
                                          'working_set_specifications',
                                          {
                                            flyout: response.data,
                                          }
                                        );
                                      });

                                      setNotification(
                                        __(
                                          'working-set-specifications.attributes-form.selected-attribute-deleted'
                                        )
                                      );
                                    }),
                                },
                              ],
                            });
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </ReactSortable>
        )}
      </Form>
    );
  }
}

export default AttributesForm;
