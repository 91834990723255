import React, { Component } from 'react';
import styles from '../styles/components/MaterialPicker.scss';
import classNames from 'classnames';
import SearchIcon from '../assets/images/search-15x15.svg';
import * as _ from 'lodash';
import autobind from 'autobind-decorator';
import { __, getLocalized } from '../core/utils';
import {
  createCompanyMaterial,
  listMyCompanyMaterials,
} from '../redux/actions/companies';
import CloseSmallIcon from '../assets/images/close-small-15x15.svg';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../helpers/Localization';
import MultiSelect from './MultiSelect';
import idToKey from 'id-to-key';
import ArrowDownMiddleIcon from '../assets/images/arrow-down-middle-15x15.svg';
import { setNotification } from '../redux/actions/general/notification';
import FieldComponent from './FieldComponent';
import OutsideClickWrapper from './OutsideClickWrapper';
import ButtonGroup from './ButtonGroup';
import Button from './Button';
import { isMobileOnly } from 'react-device-detect';
import Image from './Image';
import Input from './Input';
import Tooltip from './Tooltip';

@FieldComponent
@subscribe(Localization, 'localization')
class MaterialPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active_tab: 'system',
      query: '',
      raw_material_dropdown_active: false,
      filter_materials: [],
      material_name: {},
      material_thumb: undefined,
    };
  }

  componentDidMount() {
    if (this.props.initialCompanyMaterials) {
      this.setState({
        my_company_materials: this.props.initialCompanyMaterials,
      });
    } else {
      listMyCompanyMaterials(this.props.myCompanyMaterials ? 0 : 1).then(
        ({ response }) => {
          this.setState({ my_company_materials: response.data });
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState != this.state || prevProps.meta != this.props.meta) {
      this.props.recalculateOffset();
    }
  }

  @autobind
  _handleClick() {
    this._handleOpen();
  }

  @autobind
  _handleOpen() {
    if (!this.props.disabled) {
      this.setState({
        query: '',
        raw_material_dropdown_active: false,
      });

      listMyCompanyMaterials(this.props.myCompanyMaterials ? 0 : 1).then(
        ({ response }) => {
          this.setState({ my_company_materials: response.data });
        }
      );

      this.props.input.onFocus();

      if (this.props.input.value) {
        if (_.isInteger(this.props.input.value)) {
          if (
            _.find(this.props.localization.materials, [
              'id',
              this.props.input.value,
            ])
          ) {
            this._changeTab('system');
          } else {
            this._changeTab('my_company');
          }
        }

        if (_.isObject(this.props.input.value)) {
          this._changeTab('new');
        }
      }
    }
  }

  @autobind
  _handleClose() {
    this.props.input.onBlur();
  }

  @autobind
  _chooseMaterial(material_id) {
    this.props.input.onChange(material_id);

    this._handleClose();
  }

  @autobind
  _handleClearSearch() {
    this.setState({
      query: '',
    });

    if (this.state.active_tab == 'system' && this.refs.system_search) {
      this.refs.system_search.focus();
    }

    if (this.state.active_tab == 'my_company' && this.refs.my_company_search) {
      this.refs.my_company_search.focus();
    }
  }

  @autobind
  _handleSearch(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  _changeTab(tab) {
    this.setState(
      {
        active_tab: tab,
      },
      () => {
        this._handleClearSearch();
      }
    );
  }

  @autobind
  _createMaterial() {
    if (_.isEmpty(_.filter(this.state.material_name, _.identity))) {
      this.setState({ material_name_error: true });
    } else {
      this.setState({ material_name_error: false });
    }

    if (_.isEmpty(this.state.filter_materials)) {
      this.setState({ raw_material_error: true });
    } else {
      this.setState({ raw_material_error: false });
    }

    if (!_.has(this.refs.file.files, '0')) {
      setNotification({
        text: __(
          'specifications.working-set.flyout.atributes.please-upload-material-thumb'
        ),
        type: 'warning',
      });
    }

    if (
      _.has(this.refs.file.files, '0') &&
      !_.isEmpty(_.filter(this.state.material_name, _.identity)) &&
      !_.isEmpty(this.state.filter_materials)
    ) {
      createCompanyMaterial({
        name: this.state.material_name,
        raw_materials: this.state.filter_materials,
        thumb: this.refs.file.files[0],
      }).then(({ response }) => {
        listMyCompanyMaterials(this.props.myCompanyMaterials ? 0 : 1).then(
          ({ response }) => {
            this.setState({ my_company_materials: response.data });
          }
        );

        this.props.input.onChange(response.data.id);
        this.props.input.onBlur();
      });
    }
  }

  render() {
    const {
      active_tab,
      query,
      my_company_materials,
      raw_material_dropdown_active,
      filter_materials,
      raw_material_error,
    } = this.state;
    const { name_active, material_name_error } = this.state;
    const { active } = this.props.meta;
    const system_materials = this.props.localization.materials;
    const raw_materials = this.props.localization.raw_materials;
    const { value } = this.props.input;
    const {
      hideBarAndAssist,
      language,
      leftPosition,
      topPosition,
      elementRef,
      dropdownRef,
    } = this.props;

    const filtered_system_materials = _.filter(
      system_materials,
      (material) =>
        _.isEmpty(query) ||
        _.includes(
          getLocalized(material.name, language).toLowerCase(),
          query.toLowerCase()
        )
    );
    const filtered_my_company_materials = _.filter(
      my_company_materials,
      (material) =>
        _.isEmpty(query) ||
        _.includes(
          getLocalized(material.name, language).toLowerCase(),
          query.toLowerCase()
        )
    );

    const active_material = _.isObject(value)
      ? {
          name: getLocalized(_.get(value, 'name'), language),
          thumbnail_url: _.get(
            _.find(
              {
                ...system_materials,
                ...idToKey(my_company_materials),
              },
              ['id', _.toInteger(value)]
            ),
            'thumbnail_url'
          ),
          preview_url: _.get(
            _.find(
              {
                ...system_materials,
                ...idToKey(my_company_materials),
              },
              ['id', _.toInteger(value)]
            ),
            'preview_url'
          ),
        }
      : {
          name: getLocalized(
            _.get(
              _.find(
                {
                  ...system_materials,
                  ...idToKey(my_company_materials),
                },
                ['id', _.toInteger(value)]
              ),
              'name'
            ),
            language
          ),
          thumbnail_url: _.get(
            _.find(
              {
                ...system_materials,
                ...idToKey(my_company_materials),
              },
              ['id', _.toInteger(value)]
            ),
            'thumbnail_url'
          ),
          preview_url: _.get(
            _.find(
              {
                ...system_materials,
                ...idToKey(my_company_materials),
              },
              ['id', _.toInteger(value)]
            ),
            'preview_url'
          ),
        };

    const application_languages = _.transform(
      _.filter(
        this.props.localization.languages,
        (language) => language.application_language == true
      ),
      (languages, language) => {
        languages[language.id] = language.id == 1;

        return languages;
      },
      {}
    );

    return (
      <div
        className={classNames(
          styles.wrapper,
          active && styles.focus,
          this.props.disabled && styles.disabled,
          _.get(this.props, 'meta.error') &&
            _.get(this.props, 'meta.touched') &&
            styles.error
        )}
      >
        {this.props.label && (
          <div className={styles.label}>
            <label onClick={this._handleClick}>
              {this.props.labelTooltip ? (
                <Tooltip text={this.props.labelTooltip}>
                  {this.props.label}
                </Tooltip>
              ) : (
                this.props.label
              )}
            </label>
          </div>
        )}
        <div
          className={styles.inputGroup}
          ref={elementRef}
          onClick={this._handleClick}
        >
          <div className={styles.input}>
            {!!value && (
              <Tooltip
                placement="left"
                text={
                  <Image
                    className={styles.thumbPreview}
                    src={active_material.preview_url}
                  />
                }
                html={true}
                dark={true}
              >
                <Image
                  className={styles.materialThumb}
                  src={active_material.thumbnail_url}
                />
              </Tooltip>
            )}
            {value
              ? active_material.name
              : __(
                  'specifications.working-set.flyout.atributes.select-material'
                )}
            <ArrowDownMiddleIcon />
          </div>
        </div>
        {active && (
          <OutsideClickWrapper
            //closable={active_tab != 'new'}
            onClickOutside={(e) => {
              e.stopPropagation();

              active_tab != 'new' && this._handleClose();
            }}
          >
            <div
              className={styles.dropdown}
              ref={dropdownRef}
              style={{
                top: topPosition + 'px',
                left: leftPosition + 'px',
                marginTop: (this.props.top || 27) + 'px',
                marginLeft: (this.props.left || 0) + 'px',
              }}
            >
              {isMobileOnly && (
                <div className={styles.mobileHeader}>{this.props.label}</div>
              )}
              <div className={styles.tabs}>
                <span
                  className={classNames(
                    active_tab == 'system' && styles.active
                  )}
                  onClick={() => this._changeTab('system')}
                >
                  {__(
                    'specifications.working-set.flyout.atributes.material.system-materials'
                  )}
                </span>
                <span
                  className={classNames(
                    active_tab == 'my_company' && styles.active
                  )}
                  onClick={() => this._changeTab('my_company')}
                >
                  {__(
                    'specifications.working-set.flyout.atributes.material.my-company-materials'
                  )}
                </span>
                <span
                  className={classNames(active_tab == 'new' && styles.active)}
                  onClick={() => this._changeTab('new')}
                >
                  {__(
                    'specifications.working-set.flyout.atributes.material.add-material'
                  )}
                </span>
              </div>
              <div className={styles.content}>
                {active_tab == 'system' && (
                  <>
                    <div className={styles.title}>
                      {__(
                        'specifications.working-set.flyout.atributes.material.available-materials'
                      )}
                    </div>
                    <div className={styles.search}>
                      <SearchIcon className={styles.searchIcon} />
                      <input
                        type="text"
                        value={query}
                        autoFocus={true}
                        ref="system_search"
                        placeholder={__('topbar.tooltip.search')}
                        onChange={this._handleSearch}
                      />
                      {!_.isEmpty(query) && (
                        <CloseSmallIcon
                          className={styles.close}
                          onClick={this._handleClearSearch}
                        />
                      )}
                    </div>
                    <div className={styles.list}>
                      {_.map(filtered_system_materials, (material, i) => (
                        <div
                          className={classNames(
                            styles.material,
                            value == material.id && styles.active
                          )}
                          key={i}
                          onClick={() => this._chooseMaterial(material.id)}
                        >
                          <Tooltip
                            placement="left"
                            text={
                              <Image
                                className={styles.thumbPreview}
                                src={material.preview_url}
                              />
                            }
                            html={true}
                            dark={true}
                          >
                            <Image
                              className={styles.materialThumb}
                              src={material?.thumbnail_url}
                            />
                          </Tooltip>
                          <span className={styles.name}>
                            {getLocalized(material.name, language)}
                          </span>
                          <span className={styles.dot} />
                        </div>
                      ))}
                      {_.isEmpty(filtered_system_materials) && (
                        <div className={styles.noResults}>
                          {__('select.info.no-results')}
                        </div>
                      )}
                    </div>
                  </>
                )}
                {active_tab == 'my_company' && (
                  <>
                    <div className={styles.title}>
                      {__(
                        'specifications.working-set.flyout.atributes.material.available-materials'
                      )}
                    </div>
                    <div className={styles.search}>
                      <SearchIcon className={styles.searchIcon} />
                      <input
                        type="text"
                        value={query}
                        autoFocus={true}
                        ref="my_company_search"
                        placeholder={__('topbar.tooltip.search')}
                        onChange={this._handleSearch}
                      />
                      {!_.isEmpty(query) && (
                        <CloseSmallIcon
                          className={styles.close}
                          onClick={this._handleClearSearch}
                        />
                      )}
                    </div>
                    <div className={styles.list}>
                      {_.map(filtered_my_company_materials, (material, i) => (
                        <div
                          className={classNames(
                            styles.material,
                            value == material.id && styles.active
                          )}
                          key={i}
                          onClick={() => this._chooseMaterial(material.id)}
                        >
                          <span className={styles.name}>
                            {getLocalized(material.name, language)}
                          </span>
                          <span className={styles.dot} />
                        </div>
                      ))}
                      {_.isEmpty(filtered_my_company_materials) && (
                        <div className={styles.noResults}>
                          {__('select.info.no-results')}
                        </div>
                      )}
                    </div>
                  </>
                )}
                {active_tab == 'new' && (
                  <>
                    <div className={classNames(styles.flex, styles.inputs)}>
                      <div className={classNames(styles.f1, styles.file)}>
                        <span>
                          {__(
                            'specifications.working-set.flyout.atributes.add-material.material-thumbnail'
                          )}
                        </span>
                        <input
                          ref="file"
                          accept="image/x-png,image/jpeg"
                          type="file"
                        />
                      </div>
                    </div>
                    <div className={classNames(styles.flex, styles.inputs)}>
                      <Input
                        name="material_name"
                        label={__(
                          'specifications.working-set.flyout.atributes.add-material.material-name'
                        )}
                        localized={application_languages}
                        wrapperClassName={styles.materialNameInput}
                        meta={{
                          form: 'material_form',
                          active: name_active,
                          error: material_name_error,
                          touched: material_name_error,
                        }}
                        input={{
                          value: this.state.material_name,
                          onBlur: () => this.setState({ name_active: false }),
                          onFocus: () => this.setState({ name_active: true }),
                          onChange: (material_name) =>
                            this.setState({ material_name }),
                        }}
                      />
                    </div>
                    <div className={styles.raw}>
                      <span
                        className={classNames(
                          raw_material_dropdown_active && styles.active
                        )}
                      >
                        {__(
                          'specifications.working-set.flyout.atributes.add-material.filter-material'
                        )}
                      </span>
                      <MultiSelect
                        input={{
                          value: filter_materials,
                          onFocus: () =>
                            this.setState({
                              raw_material_dropdown_active: true,
                              raw_material_error: false,
                            }),
                          onBlur: () =>
                            this.setState({
                              raw_material_dropdown_active: false,
                            }),
                          onChange: (filter_materials) =>
                            this.setState({ filter_materials }),
                        }}
                        meta={{
                          active: raw_material_dropdown_active,
                          error: raw_material_error,
                          touched: raw_material_error,
                        }}
                        noSort // options are already sorted.
                        options={_.sortBy(
                          _.map(raw_materials, (raw_material) => ({
                            label: _.get(
                              raw_material.name,
                              language,
                              _.get(raw_material.name, 1)
                            ),
                            priority: raw_material.priority,
                            value: raw_material.id,
                          })),
                          'priority'
                        )}
                      />
                    </div>
                  </>
                )}
              </div>
              {(active_tab == 'new' || isMobileOnly) && (
                <div className={styles.footer}>
                  <ButtonGroup right>
                    <Button
                      lightGray
                      medium
                      middleText={__('button.cancel')}
                      onClick={this._handleClose}
                    />
                    {active_tab == 'new' && (
                      <Button
                        lightBlue
                        medium
                        middleText={__('button.add-and-select')}
                        onClick={this._createMaterial}
                      />
                    )}
                  </ButtonGroup>
                </div>
              )}
            </div>
          </OutsideClickWrapper>
        )}
        {!hideBarAndAssist && (
          <>
            <div className={styles.bar} />
            <div
              className={classNames(
                styles.assist,
                ((_.get(this.props, 'meta.error') &&
                  _.get(this.props, 'meta.touched')) ||
                  this.props.hint) &&
                  styles.hint
              )}
            >
              {((_.get(this.props, 'meta.error') &&
                _.get(this.props, 'meta.touched')) ||
                this.props.hint) && (
                <span>
                  {_.get(this.props, 'meta.error') || this.props.hint}
                </span>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default MaterialPicker;
