import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/working_set_specifications/Attributes.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import autobind from 'autobind-decorator';
import EditIcon from '../../assets/images/edit-16x16.svg';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../../core/utils';
import AttributesForm from '../../forms/working_set_specifications/AttributesForm';
import { setNotification } from '../../redux/actions/general/notification';
import CloseMediumIcon from '../../assets/images/close-middle-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import PlusMiddleIcon from '../../assets/images/plus-middle-15x15.svg';
import ProductAttributeGroupSaveIcon from '../../assets/images/product-attribute-group-save-16x16.svg';
import Tooltip from '../../components/Tooltip';
import { ReactSortable } from 'react-sortablejs';
import GrabberIcon from '../../assets/images/grabber-16x16.svg';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import { SubmissionError } from 'redux-form';
import {
  deleteSpecificationProductAttributesGroup,
  readWorkingSetSpecification,
  reorderSpecificationProductAttributeGroups,
  updateSpecificationProductAttributesGroupFields,
} from '../../redux/actions/table/working_set_specifications';

@mapStateToProps((state) => ({
  store: state.table.working_set_specifications,
  application_language_id: state.auth.language_id,
}))
@subscribe(Localization, 'localization')
class Attributes extends Component {
  last_request_id = null;

  constructor(props) {
    super(props);

    this.state = {
      collapsed_groups: [],
    };
  }

  @autobind
  _toggleCollapseGroup(group) {
    this.setState({
      collapsed_groups: _.xor(this.state.collapsed_groups, [group]),
    });
  }

  @autobind
  _handleSubmit(specification_product_attribute_group_id, formData) {
    const request_id = _.uniqueId('request');

    this.last_request_id = request_id;

    const request = updateSpecificationProductAttributesGroupFields(
      specification_product_attribute_group_id,
      formData
    );

    const validation = request.then(() => {
      setNotification(
        __(
          'working-set-specifications.attributes.specification-attribute-group-fields-updated'
        )
      );

      const { flyout } = this.props.store;

      this.props
        ._refetchData()
        .then(({ response }) => {
          setTableParams('working_set_specifications', {
            flyout: response.data,
          });
        })
        .catch((error) => {
          console.error('Error fetching specification:', error);
        });
    });

    return validation.catch(({ response, status }) => {
      if (request_id === this.last_request_id) {
        if (status === 422 && 'errors' in response) {
          setNotification({
            text: 'Your specification attributes have not been saved! Please revise the marked fields.',
            type: 'warning',
          });

          throw new SubmissionError(
            _.transform(
              response.errors,
              (errors, error, field) => {
                _.set(errors, field, error);
              },
              {}
            )
          );
        }
      }
    });
  }

  @autobind
  _changeOrder(attribute_groups) {
    setTableParams('working_set_specifications', {
      flyout: {
        ...this.props.store.flyout,
        attribute_groups: _.map(attribute_groups, (attribute_group, i) => ({
          ...attribute_group,
          priority: i,
        })),
      },
    });
  }

  render() {
    const { collapsed, store, application_language_id, isShared } = this.props;
    const { flyout } = store;
    const { collapsed_groups } = this.state;

    const attribute_groups = _.sortBy(flyout.attribute_groups, 'priority');

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        <ReactSortable
          className={styles.attributes}
          list={attribute_groups}
          setList={this._changeOrder}
          animation={200}
          delayOnTouchStart={true}
          onEnd={() => {
            reorderSpecificationProductAttributeGroups(
              flyout.id,
              _.map(attribute_groups, 'id')
            ).then(() => {
              setNotification(
                __(
                  'working-set-specifications.attributes.specification-attribute-groups-order-saved'
                )
              );
            });
          }}
          handle={'.' + styles.grabber}
        >
          {_.map(attribute_groups, (group) => (
            <div className={styles.group} key={group.id}>
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseGroup(group.id)}
              >
                <Tooltip
                  text={__(
                    'working-set-specifications.attributes.reorder-attributes-group'
                  )}
                >
                  <div className={styles.grabber}>
                    <GrabberIcon />
                  </div>
                </Tooltip>
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    !_.includes(collapsed_groups, group.id) && styles.collapsed
                  )}
                />
                {getLocalized(group.name, application_language_id)}
                {!isShared && (
                  <Tooltip
                    text={__(
                      'working-set-specifications.attributes.add-attributes-to-group'
                    )}
                  >
                    <PlusMiddleIcon
                      className={styles.add}
                      onClick={(e) => {
                        e.stopPropagation();

                        setTableParams('working_set_specifications', {
                          add_specification_product_attribute_group_field_wizard:
                            group.id,
                        });
                      }}
                    />
                  </Tooltip>
                )}
                {!isShared && (
                  <Tooltip
                    text={__(
                      'working-set-specifications.attributes.edit-group-name'
                    )}
                  >
                    <EditIcon
                      className={styles.edit}
                      onClick={(e) => {
                        e.stopPropagation();

                        setTableParams('working_set_specifications', {
                          edit_specification_product_attribute_group_wizard:
                            group.id,
                        });
                      }}
                    />
                  </Tooltip>
                )}
                {!isShared && (
                  <Tooltip
                    text={__(
                      'working-set-specifications.attributes.save-group-as-template'
                    )}
                  >
                    <ProductAttributeGroupSaveIcon
                      className={styles.saveTemplate}
                      onClick={(e) => {
                        e.stopPropagation();

                        setTableParams('working_set_specifications', {
                          save_specification_product_attribute_group_as_template_wizard:
                            group.id,
                        });
                      }}
                    />
                  </Tooltip>
                )}

                {!isShared && (
                  <Tooltip
                    text={__(
                      'working-set-specifications.attributes.delete-group'
                    )}
                  >
                    <CloseMediumIcon
                      className={styles.delete}
                      onClick={(e) => {
                        e.stopPropagation();

                        showAlertbox({
                          color: 'red',
                          title: __('general.alert.are-you-sure'),
                          description: __(
                            'working-set-specifications.attributes.are-you-sure-delete-attribute group.delete-attributes-belong-to-group.this-process-cannot-be-undone'
                          ),
                          buttons: [
                            {
                              color: 'lightGray',
                              text: __('general.alert.no-close'),
                              onClick: 'close',
                            },
                            {
                              color: 'gray',
                              text: __('general.alert.yes-delete'),
                              onClick: () =>
                                deleteSpecificationProductAttributesGroup(
                                  group.id
                                ).then(() => {
                                  hideAlertbox();

                                  readWorkingSetSpecification(flyout.id).then(
                                    ({ response }) => {
                                      setTableParams(
                                        'working_set_specifications',
                                        {
                                          flyout: response.data,
                                        }
                                      );
                                    }
                                  );

                                  setNotification(
                                    __(
                                      'working-set-specifications.attributes.selected-attributes-group-deleted'
                                    )
                                  );
                                }),
                            },
                          ],
                        });
                      }}
                    />
                  </Tooltip>
                )}
              </div>
              <AttributesForm
                isShared={isShared}
                expanded={!_.includes(collapsed_groups, group.id)}
                onSubmit={(formData) => this._handleSubmit(group.id, formData)}
                form={'specifications.attributes.group' + group.id}
                groupId={group.id}
                initialValues={_.transform(
                  group.attributes,
                  (attributes, attribute) => {
                    const type = attribute.template_field_id
                      ? _.get(
                          this.props.localization.product_attribute_fields,
                          [attribute.template_field_id, 'type']
                        )
                      : attribute.type;

                    // for some reason, when redux receives a id for initial value key
                    // it registers as many fields as big key is, i.e. if initial value
                    // key was 2704, there would be 2704 registered fields for that form
                    // with 1 - 2703 being just empty value.
                    const fieldName = `attr-${attribute.id}`;
                    if (type == 'string') {
                      attributes[fieldName] =
                        _.size(attribute.value) > 0 ? attribute.value : {};
                    } else {
                      attributes[fieldName] = attribute.value;
                    }

                    return attributes;
                  },
                  {}
                )}
              />
            </div>
          ))}
        </ReactSortable>
        {!isShared && (
          <div className={styles.addRow}>
            <button
              onClick={() =>
                setTableParams('working_set_specifications', {
                  add_specification_product_attribute_group_wizard: flyout.id,
                })
              }
            >
              {__('working-set-specifications.attributes.add-group')}
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default Attributes;
