import * as _ from 'lodash';
import {
  change,
  reset,
  stopSubmit,
  SubmissionError,
  submit,
  destroy,
} from 'redux-form';
import React, { useEffect, useRef } from 'react';
import history from './history';
import { Howl } from 'howler';
import convert from 'convert-length';
import store from './store';
import moment from 'moment';
import queryString from 'query-string';
import { connect } from 'react-redux';
import Linkify from 'react-linkify';
import parse from 'url-parse';
import translations from './translations-loader';
import currencyFormatter from 'currency-formatter';

const processString = require('react-process-string');

/**
 * Constants
 */
export const backendURL = process.env.APP_URL;
export const frontendURL = process.env.REACT_APP_URL;
export const socketURL = process.env.SOCKET_URL;
export const app_env = process.env.APP_ENV;

/**
 * Redirect to page
 *
 * @returns {*}
 * @param location
 * @param replace
 */
export const redirect = (location, replace = false) => {
  const { host, pathname, query, hash } = parse(location);
  const same_host = host == window.location.host;

  if (same_host) {
    if (_.isString(location)) {
      location = [pathname, query, hash].join('');
    }

    if (replace) {
      history.replace(location);
    } else {
      history.push(location);
    }
  } else {
    window.location = location;
  }
};

/**
 * Redirect back
 *
 * @returns {*}
 */
export const goBack = () => {
  history.goBack();
};

/**
 * Redirect back
 *
 * @returns {*}
 */
export const clearURLFragment = () => {
  return redirect({ search: store.getState().router.location.search }, true);
};

/**
 * Handling token
 *
 * @param token
 */
export const setToken = (token) => localStorage.setItem('token', token);
export const getToken = () => localStorage.getItem('token');
export const deleteToken = () => localStorage.removeItem('token');

/**
 * Form validations
 *
 * @param action
 * @returns {Promise<T> | *}
 */
export const validateForm = (action) => {
  return action.catch(({ response, status }) => {
    if (status === 403 && 'code' in response) {
      throw new SubmissionError({ code: response.code });
    }

    if (status === 422 && 'errors' in response) {
      throw new SubmissionError(
        _.transform(
          response.errors,
          (errors, error, field) => {
            _.set(errors, field, error);
          },
          {}
        )
      );
    }
  });
};

/**
 * Show/hide preloader
 *
 * @type {HTMLElement | null}
 */
const preloader = document.getElementById('preloader');
export const showPreloader = () => preloader && (preloader.style.display = '');
export const hidePreloader = () =>
  preloader && setTimeout(() => (preloader.style.display = 'none'), 500);

/**
 * Localization
 *
 * @param key
 * @param parameters
 * @param default_translation
 * @returns {*}
 * @private
 */
export const __ = (key, parameters = {}, default_translation = null) => {
  let translation = _.get(store.getState(), [
    'localization',
    'translations',
    key,
  ]);

  if (!_.isEmpty(parameters)) {
    translation = processString([
      {
        regex: /:([\-_A-Za-z0-9]*)/gim,
        fn: (key, result) => {
          return _.get(parameters, result[1], result[0]);
        },
      },
    ])(translation);

    if (_.isEmpty(_.reject(parameters, _.isString))) {
      translation = _.join(translation, '');
    }
  }

  if (localStorage.getItem('debug_translations') == 1) {
    return key;
  } else {
    return translation || default_translation || key;
  }
};

/**
 * Localization (new system)
 *
 * @param key
 * @param parameters
 * @param default_translation
 * @returns {*}
 * @private
 */
export const ___ = (key, parameters = {}, default_translation = null) => {
  const language =
    _.toLower(
      _.get(store.getState(), [
        'localization',
        'languages',
        _.get(store.getState(), ['auth', 'language_id']),
        'code',
      ])
    ) || 'en';
  let translation = _.get(translations, [language, key].join('.'));

  if (!_.isEmpty(parameters)) {
    translation = processString([
      {
        regex: /:([\-_A-Za-z0-9]*)/gim,
        fn: (key, result) => {
          return _.get(parameters, result[1], result[0]);
        },
      },
    ])(translation);

    if (_.isEmpty(_.reject(parameters, _.isString))) {
      translation = _.join(translation, '');
    }
  }

  if (localStorage.getItem('debug_translations') == 1) {
    return key;
  } else {
    return translation || default_translation || key;
  }
};

/**
 * Transform key-value pair to label-value pair for select dropdowns
 *
 * @param items
 * @param label
 * @param key
 * @returns {*}
 * @private
 */
export const transformToListPairs = (
  items,
  label = 'name',
  key = 'id',
  tooltip = 'description'
) => {
  return _.map(items, (item) => {
    return {
      value: item[key],
      label: item[label],
      tooltip: item?.[tooltip],
    };
  });
};

/**
 * Get localized value
 *
 * @param items
 * @param value
 * @param label
 * @param key
 * @returns {*}
 * @private
 */
export const getLocalizedListValue = (
  items,
  value,
  label = 'name',
  key = 'id'
) => {
  return _.get(
    _.find(items, (item) => item[key] == value),
    label
  );
};

/**
 * Get first key in object array
 *
 * @param array
 * @returns {*}
 */
export const getFirstKey = (array) => {
  return _.first(_.keys(array));
};

/**
 * Play sound file
 *
 * @returns {*}
 * @param file
 */
export const play = (file) => {
  new Howl({
    src: [file],
  }).play();
};

/**
 * Bytes to size string converter
 *
 * @param bytes
 * @returns {string}
 */
export const bytesToSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + ' ' + sizes[i];
};

/**
 * Convert pt to mm
 *
 * @param pt
 * @returns {*}
 */
export const ptToMm = (pt) => {
  return convert(_.round(pt, 2), 'pt', 'mm');
};

/**
 * Guess paper size
 *
 * @returns {{size: *, description: string}}
 * @param sourceWidth
 * @param sourceHeight
 */
export const guessPaperSize = (sourceWidth, sourceHeight) => {
  const [width, height] =
    sourceWidth > sourceHeight
      ? [sourceHeight, sourceWidth]
      : [sourceWidth, sourceHeight];

  const paper_sizes = _.get(store.getState(), 'localization.paper_sizes');
  const size = _.find(
    paper_sizes,
    (size) =>
      Math.abs(size.width - width) <= 0.5 && Math.abs(size.height - height)
  );

  return {
    size: _.get(size, 'name', __('plans.custom-paper-size')),
    description:
      _.round(sourceWidth, 2) + 'mm x ' + _.round(sourceHeight, 2) + 'mm',
  };
};
// Helper function to convert minutes to hours and minutes
export const convertMinutesToHoursAndMinutes = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}m`;
};
/**
 * Get datetime format from Date object
 *
 * @param date
 * @returns {*}
 */
export const dateTimeFrom = (date) => {
  return (
    moment.utc(date).local().format('DD.MM.YY') +
    ' · ' +
    moment.utc(date).local().format('HH:mm')
  );
};

/**
 * Get date format from Date object
 *
 * @param date
 * @param full
 * @returns {*}
 */
export const dateFrom = (date, full = false) => {
  return moment
    .utc(date)
    .local()
    .format(full ? 'DD.MM.YYYY' : 'DD.MM.YY');
};

/**
 * Get tome format from Date object
 *
 * @param date
 * @returns {*}
 */
export const timeFrom = (date) => {
  return moment.utc(date).local().format('HH:mm');
  // return moment(date).format('HH:mm');
};
// Helper function to convert hours and minutes to minutes
export const convertHoursAndMinutesToMinutes = (hours = 0, minutes = 0) => {
  return hours * 60 + _.toInteger(minutes);
};
/**
 * Print a human readable duration string
 *
 * @param duration
 * @param time
 * @param defaultNull
 * @returns {*|number|moment.Moment}
 */
export const durationToString = (
  duration,
  time = false,
  defaultNull = '0d'
) => {
  const array = [
    {
      value: duration.years(),
      label: 'Y',
    },
    {
      value: duration.months(),
      label: 'M',
    },
    {
      value: duration.days(),
      label: 'd',
    },
    {
      value: duration.hours(),
      label: 'h',
    },
    {
      value: duration.minutes(),
      label: 'm',
    },
  ];

  const start = _.findIndex(array, (item) => item.value > 0);

  return duration.asSeconds() > 0
    ? _.map(
        _.slice(array, start, time ? 5 : 3),
        (item) => item.value + item.label
      ).join(' ')
    : defaultNull;
};

/**
 * Scroll to bottom
 *
 * @param element
 */
export const scrollToBottom = (element) => {
  const scrollHeight = element.scrollHeight;
  const height = element.clientHeight;
  const maxScrollTop = scrollHeight - height;

  element.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
};

/**
 * Set URL param
 *
 * @param key
 * @param value
 */
export const setURLParam = (key, value) => {
  const params = {
    ...queryString.parse(_.get(store.getState(), 'router.location.search')),
  };

  params[key] = value;

  redirect({ search: queryString.stringify(params, { encode: false }) }, true);
};

/**
 * Get URL parameter
 *
 * @returns {string | null}
 * @param key
 */
export const getURLParam = (key) => {
  return _.get(
    queryString.parse(_.get(store.getState(), 'router.location.search')),
    key,
    ''
  );
};

/**
 * Determine if URL has a parameter
 *
 * @returns boolean
 * @param key
 */
export const hasURLParam = (key) => {
  return _.has(
    queryString.parse(_.get(store.getState(), 'router.location.search')),
    key
  );
};

/**
 * Get URL fragment
 *
 * @returns {string | null}
 */
export const getURLFragment = () => {
  const fragment = _.trimStart(
    _.get(store.getState(), 'router.location.hash'),
    '#'
  );

  return !_.isEmpty(fragment) ? fragment : undefined;
};

/**
 * Set table params
 *
 * @param table
 * @param params
 */
export const setTableParams = (table, params) => {
  store.dispatch({
    type: 'table.' + table + '/SET_TABLE_PARAMS',
    params,
  });
};

/**
 * Map state to props decorator
 *
 * @param mapStateToProps
 * @returns {*}
 */
export const mapStateToProps = (mapStateToProps) => {
  return connect(mapStateToProps);
};

/**
 * Set view title
 *
 * @returns {*}
 * @param title
 */
export const setPageTitle = (title) => {
  if (_.isNull(title)) {
    document.title = __(
      'general.document-title.volum3-collaboration-platform',
      {},
      'VOLUM3 Collaboration Platform'
    );
  } else if (_.isObject(title)) {
    document.title =
      _.join(title, ' | ') +
      ' · ' +
      __(
        'general.document-title.volum3-collaboration-platform',
        {},
        'VOLUM3 Collaboration Platform'
      );
  } else {
    document.title =
      title +
      ' · ' +
      __(
        'general.document-title.volum3-collaboration-platform',
        {},
        'VOLUM3 Collaboration Platform'
      );
  }
};

/**
 * Get active project ID
 *
 * @returns {*}
 */
export const getActiveProject = () => {
  return _.get(window, 'active_project');
};

/**
 * Get active stage ID
 *
 * @returns {*}
 */
export const getActiveStage = () => {
  return _.get(window, 'active_stage');
};

/**
 * Set active project ID
 *
 * @returns {*}
 */
export const setActiveProject = (project_id) => {
  return (window.active_project = project_id);
};

/**
 * Set active stage ID
 *
 * @returns {*}
 */
export const setActiveStage = (stage_id) => {
  return (window.active_stage = stage_id);
};

/**
 * Screen resolution comparison
 *
 * @returns {*}
 */
export const screenIs = (operator, pixels = 0) => {
  switch (operator) {
    case '<':
      return window.innerWidth < pixels;
    case '<=':
      return window.innerWidth <= pixels;
    case '==':
      return window.innerWidth == pixels;
    case '>=':
      return window.innerWidth >= pixels;
    case '>':
      return window.innerWidth > pixels;
    default:
      throw false;
  }
};

/**
 * Reset form
 *
 * @param form
 */
export const resetForm = (form) => {
  return store.dispatch(reset(form));
};

/**
 * Submit form
 *
 * @param form
 */
export const submitForm = (form) => {
  return store.dispatch(submit(form));
};

/**
 * Destroy form
 *
 * @param form
 */
export const destroyForm = (form) => {
  return store.dispatch(destroy(form));
};

/**
 * Set form errors
 *
 * @param form
 * @param errors
 */
export const setFormErrors = (form, errors) => {
  setTimeout(() => store.dispatch(stopSubmit(form, errors)), 30);
};

/**
 * Set form field value
 *
 * @param form
 * @param field
 * @param value
 */
export const setFormValue = (form, field, value) => {
  return store.dispatch(change(form, field, value));
};

/**
 * Linkify text
 *
 * @param text
 * @returns {*}
 */
export const linkifyText = (text = '') => {
  const linkWrapper = (href, text, key) => {
    const { host, pathname, query, hash } = parse(href);
    const same_host = host == window.location.host;

    return same_host ? (
      <a
        onClick={(e) => {
          e.preventDefault();

          redirect([pathname, query, hash].join(''));
        }}
        href={href}
        key={key}
      >
        {text}
      </a>
    ) : (
      <a href={href} key={key} target='_blank'>
        {text}
      </a>
    );
  };

  return <Linkify componentDecorator={linkWrapper}>{text}</Linkify>;
};

/**
 * Get localized string from object
 *
 * @param localized_object
 * @param language_id
 * @param fallback_language_id
 * @returns string
 */
export const getLocalized = (
  localized_object,
  language_id,
  fallback_language_id = 1
) => {
  // object has already been localized, i.e. we probably invoked
  // getLocalized with string, and not localization object,
  // i.e. getLocalized('translated string', 2), instead of
  // getLocalized({ 2: 'translated string' }, 2). In this case
  // we will just return that string.
  if (typeof localized_object === 'string') {
    return localized_object;
  }

  let output = _.get(
    localized_object,
    language_id,
    _.get(localized_object, fallback_language_id)
  );

  return _.size(output) > 0 ? output : _.head(_.map(localized_object));
};

/**
 * Cast to boolean
 *
 * @returns boolean
 * @param input
 */
export const toBoolean = (input) => {
  return !!input;
};

/**
 * Sort values by looking at in the array order
 *
 * @returns boolean
 * @param array
 * @param value
 */
export const sortValuesByArray = (array, value) => {
  const index = array.indexOf(value);

  return index == -1 ? Infinity : index;
};

/**
 * Format money
 */
export const formatMoney = (number) => {
  return _.trim(
    currencyFormatter.format(number, {
      locale: 'de-DE',
      symbol: '',
    })
  );
};

/**
 * Unformat money (convert to float)
 */
export const unformatMoney = (number) => {
  return currencyFormatter.unformat(number, {
    locale: 'de-DE',
  });
};

/**
 * Format url - adding protocol to each URL
 */
export const formatUrlToOpenExternalLink = (url, name) => {
  if (!url.match(/^https?:\/\//i)) {
    url = 'http://' + url;
  }
  return url;
};

/**
 * Format percentage money
 */
export const formatPercentageMoney = (number) => {
  const value = parseFloat(number * 100).toFixed(2) + ' %';

  return number > 0 ? '+' + value : value;
};

/**
 * Use Previous props
 */
export const usePreviousProps = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes

  return ref.current; //in the end, return the current ref value.
};
