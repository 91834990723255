import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/working_set_specifications/LinkedSpecifications.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import {
  __,
  dateFrom,
  dateTimeFrom,
  getLocalized,
  mapStateToProps,
  setTableParams,
  formatMoney,
} from '../../core/utils';
import Tooltip from '../../components/Tooltip';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import MakeMainIcon from '../../assets/images/make-main-16x16.svg';
import CloseMiddleIcon from '../../assets/images/close-middle-15x15.svg';
import EditIcon from '../../assets/images/edit-16x16.svg';
import autobind from 'autobind-decorator';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import { setNotification } from '../../redux/actions/general/notification';
import {
  deleteLinkedSpecificationCode,
  readWorkingSetSpecification,
  updateLinkedSpecificationCode,
} from '../../redux/actions/table/working_set_specifications';
import ArrowDoubleRightSmallIcon from '../../assets/images/arrow-double-right-small-15x15.svg';
import TableNA from '../../components/TableNA';
import TickIcon from '../../assets/images/tick-15x15.svg';
import NumericInput from '../../views/boq/helpers/NumericInput';
import UncheckedIcon from '../../assets/images/unchecked-15x15.svg';
import CheckedIcon from '../../assets/images/checked-15x15.svg';
import DefaultImage from '../../assets/images/default.png';
import Image from '../../components/Image';
import ParentLinkedSpecifications from './ParentLinkedSpecifications';

@mapStateToProps((state) => ({
  store: state.table.working_set_specifications,
}))
@subscribe(Localization, 'localization')
class LinkedSpecifications extends Component {
  @autobind
  _unlinkSpecificationCode(e, specification_code_id) {
    e.stopPropagation();

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __(
        'working-set-specifications.linked-specifications.are-you-sure-unlink-specification'
      ),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-unlink'),
          onClick: () =>
            deleteLinkedSpecificationCode(
              this.props.store.flyout.specification_code_id,
              {
                specification_code_id,
              }
            ).then(() =>
              readWorkingSetSpecification(this.props.store.flyout.id).then(
                ({ response }) => {
                  hideAlertbox();

                  setNotification(
                    __(
                      'working-set-specifications.linked-specifications.selected-specification-unlinked'
                    )
                  );

                  setTableParams('working_set_specifications', {
                    flyout: response.data,
                  });
                }
              )
            ),
        },
      ],
    });
  }

  @autobind
  _editLinkedSpecification(e, specification_code_id, included_in_price) {
    e.stopPropagation();

    setTableParams('working_set_specifications', {
      edit_linked_specification_wizard: specification_code_id,
      included_in_price,
    });
  }

  @autobind
  _handleUpdateRow(specification, source, value) {
    const {
      linked_description,
      specification_code_id,
      quantity,
      included_in_price,
    } = specification;
    const { id } = this.props.store.flyout;

    let formData = {
      description: linked_description,
      specification_code_id: specification_code_id,
      new_specification_code_id: specification_code_id,
      quantity: source == 'quantity' ? value : quantity,
      included_in_price:
        source == 'included_in_price' ? value : included_in_price,
    };

    updateLinkedSpecificationCode(
      this.props.store.flyout.specification_code_id,
      formData
    ).then(() =>
      readWorkingSetSpecification(id).then(({ response }) => {
        setNotification('A linked specification has been updated.');

        setTableParams('working_set_specifications', {
          flyout: response.data,
        });
      })
    );
  }

  render() {
    const { collapsed, store, readOnly } = this.props;
    const { flyout } = store;
    const { specification_groups, specification_subgroups, units } =
      this.props.localization;

    const linked_specs = _.sortBy(
      _.map(flyout.linked, (spec) => {
        const specification_subgroup =
          _.get(specification_subgroups, [spec.specification_subgroup_id]) ||
          {};
        const specification_group =
          _.get(
            specification_groups,
            _.get(specification_subgroup, 'specification_group_id')
          ) || {};

        const code =
          getLocalized(specification_group.code, flyout.project_language) +
          specification_subgroup.code +
          _.padStart(spec.code, 2, '0');
        const title = _.filter(
          [_.get(spec.description, store.language_id), spec.model, spec.brand],
          _.identity
        ).join(' / ');

        return {
          ...spec,
          code,
          title,
        };
      }),
      'code'
    );

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        {_.isEmpty(flyout.linked) ? (
          <div className={styles.empty}>
            {__('working-set-specifications.images.no-linked-specifications')}
          </div>
        ) : (
          ''
        )}
        {!_.isEmpty(flyout.linked) && (
          <>
            <div className={styles.header}>
              <span className={styles.code}>
                {__(
                  'specifications.working-set.flyout.linked-specifications.code'
                )}
              </span>
              <span className={styles.titleAndDescription}>
                {__(
                  'specifications.working-set.flyout.linked-specifications.title-and-description'
                )}
              </span>
              <span className={styles.included}>
                {__(
                  'specifications.working-set.linked-specification.included-in-price'
                )}
              </span>
              <span className={styles.quantity}>
                {__('specifications.working-set.linked-specification.quantity')}
              </span>
              <span className={styles.unit}>
                {__('specifications.working-set.linked-specification.unit')}
              </span>

              <span className={styles.price}>
                {__('specifications.working-set.linked-specification.price')}
              </span>

              <span className={styles.subtotal}>
                {__('specifications.working-set.linked-specification.subtotal')}
              </span>

              {!readOnly && <span className={styles.actions} />}
            </div>
            {_.map(linked_specs, (specification, i) => (
              <div className={styles.row} key={i}>
                <span
                  className={styles.code}
                  onClick={() =>
                    setTableParams('working_set_specifications', {
                      clicked_row: specification.id,
                    })
                  }
                >
                  {specification.code}
                </span>
                <span className={styles.thumbnail}>
                  <Tooltip
                    placement='left'
                    text={
                      <Image
                        default={DefaultImage}
                        className={styles.thumbPreview}
                        src={specification.thumb_url}
                      />
                    }
                    html={true}
                    dark={true}
                  >
                    <Image
                      default={DefaultImage}
                      src={specification.thumb_url}
                      className={styles.icon}
                    />
                  </Tooltip>
                </span>

                <span className={styles.titleAndDescription}>
                  <Tooltip text={specification.title}>
                    <span
                      className={styles.title}
                      onClick={() =>
                        setTableParams('working_set_specifications', {
                          clicked_row: specification.id,
                        })
                      }
                    >
                      {_.size(specification.title) > 0 ? (
                        <span>{specification.title}</span>
                      ) : (
                        <TableNA />
                      )}
                      <ArrowDoubleRightSmallIcon />
                    </span>
                  </Tooltip>

                  <Tooltip text={specification.linked_description}>
                    <span className={styles.description}>
                      {specification.linked_description || <TableNA />}
                    </span>
                  </Tooltip>
                </span>

                <span className={styles.included}>
                  {specification.included_in_price ? (
                    <CheckedIcon
                      className={classNames(
                        styles.checked,
                        readOnly && styles.checkedDisabled
                      )}
                      onClick={(e) => {
                        e.stopPropagation();

                        this._handleUpdateRow(
                          specification,
                          'included_in_price',
                          false
                        );
                      }}
                    />
                  ) : (
                    <UncheckedIcon
                      className={classNames(
                        styles.unchecked,
                        readOnly && styles.checkedDisabled
                      )}
                      onClick={(e) => {
                        e.stopPropagation();

                        this._handleUpdateRow(
                          specification,
                          'included_in_price',
                          true
                        );
                      }}
                    />
                  )}
                  {/* {_.random(0, 1) == 1 ? <TickIcon /> : <TableNA />} */}
                </span>

                {/* <Tooltip text={specification.linked_description}>
                  <div className={styles.description}>
                    {specification.linked_description || <TableNA />}
                  </div>
                </Tooltip> */}
                <span className={styles.quantity}>
                  <NumericInput
                    key={_.uniqueId()}
                    readOnly={readOnly}
                    onBlur={(value) =>
                      this._handleUpdateRow(specification, 'quantity', value)
                    }
                    defaultValue={specification.quantity}
                  />
                </span>
                <span className={styles.unit}>
                  {_.get(units, [specification.unit_id, 'symbol']) || 'n/a'}
                </span>

                <span className={styles.price}>
                  {!specification.included_in_price && specification.price != 0
                    ? formatMoney(specification.price)
                    : ''}
                </span>

                <span className={styles.subtotal}>
                  {!specification.included_in_price &&
                    formatMoney(specification.price * specification.quantity)}
                </span>
                {!readOnly && (
                  <span className={styles.actions}>
                    <Tooltip
                      text={__(
                        'working-set-specifications.linked-specifications.edit-linked-specification'
                      )}
                    >
                      <EditIcon
                        onClick={(e) =>
                          this._editLinkedSpecification(
                            e,
                            specification.specification_code_id,
                            specification.included_in_price
                          )
                        }
                      />
                    </Tooltip>
                    <Tooltip
                      text={__(
                        'working-set-specifications.linked-specifications.unlink-linked-specification'
                      )}
                    >
                      <CloseMiddleIcon
                        onClick={(e) =>
                          this._unlinkSpecificationCode(
                            e,
                            specification.specification_code_id
                          )
                        }
                      />
                    </Tooltip>
                  </span>
                )}
              </div>
            ))}
          </>
        )}
        {!readOnly && (
          <div className={styles.addRow}>
            <button
              onClick={() =>
                setTableParams('working_set_specifications', {
                  add_linked_specification_wizard: flyout.specification_code_id,
                })
              }
            >
              {__(
                'working-set-specifications.linked-specifications.add-linked-specification'
              )}
            </button>
            <button
              onClick={() =>
                setTableParams('working_set_specifications', {
                  wizard_active: true,
                  linked_specification_code_id: flyout.specification_code_id,
                })
              }
            >
              {__(
                'working-set-specifications.linked-specifications.create-new-linked-specification'
              )}
            </button>
          </div>
        )}

        {/* --- PRENT LINKED SPECIFICATIONS --- */}
        {!_.isEmpty(flyout.parent_linked) && (
          <ParentLinkedSpecifications
            flyout={flyout}
            localization={this.props.localization}
            language_id={store.language_id}
          />
        )}
      </div>
    );
  }
}

export default LinkedSpecifications;
