import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/MultipleTaskTagsEditWizard.scss';
import autobind from 'autobind-decorator';
import TagIcon from '../assets/images/tag-64x64.svg';
import {
  __,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import { setNotification } from '../redux/actions/general/notification';
import { withRouter } from 'react-router';
import {
  listAllTasks,
  multipleEditTaskTags,
} from '../redux/actions/table/all_tasks';
import MultipleTaskTagsEditForm from '../forms/wizard_multiple_task_tags_edit/MultipleTaskTagsEditForm';

@mapStateToProps((state) => ({
  task_ids: _.get(state.table, 'all_tasks.wizard_multiple_task_tags_edit'),
}))
@withRouter
class MultipleTaskTagsEditWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    const { task_ids } = this.props;

    return validateForm(
      multipleEditTaskTags(task_ids, _.get(formData, 'tags')).then(() => {
        listAllTasks(this.props.match.params.stage);

        setTableParams('all_tasks', {
          selected_rows: [],
        });

        setNotification(__('Multiple task tags have been saved.'));

        this.props.onClose();
      })
    );
  }

  render() {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>{__('Multiple Edit Task Tags')}</span>
            <span>{__('Choose the tags for selected tasks')}</span>
          </div>
          <div className={styles.right}>
            <TagIcon />
          </div>
        </div>
        <div className={styles.body}>
          <MultipleTaskTagsEditForm onSubmit={this._handleSubmit} />
        </div>
      </>
    );
  }
}

export default MultipleTaskTagsEditWizard;
