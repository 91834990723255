import React, { useEffect } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../../components/Table';
import TableNA from '../../components/TableNA';
import {
  __,
  dateFrom,
  dateTimeFrom,
  redirect,
  convertMinutesToHoursAndMinutes,
} from '../../core/utils';
import * as _ from 'lodash';
import { createSidebar } from '../../redux/actions/general/sidebar';
import { setPlusActions } from '../../redux/actions/general/plus';

import {
  activateProjects,
  deactivateProjects,
  listAllProjects,
} from '../../redux/actions/table/all_projects';
import { listTimesheets } from '../../redux/actions/table/timesheets';
import {
  listActiveModules,
  enableProjectsDropdown,
  enableStagesDropdown,
} from '../../redux/actions/general/active_modules';
import AddToMeetingIcon from '../../assets/images/add-to-meeting-16x16.svg';
import Tooltip from '../../components/Tooltip';

const mapStateToProps = (state) => {
  return {
    table: state.table['timesheets'],
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.timesheets/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class Timesheets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      team_roles: [],
    };
  }

  componentDidMount() {
    !_.isEmpty(this.state.team_roles.editor) &&
      setPlusActions([
        {
          title: __('timesheets.table.button.create'),
          onClick: () =>
            this.props.actions.setTableParams({
              wizard_active: true,
            }),
        },
      ]);

    // enableProjectsDropdown(({ stage_id }) =>
    //   redirect('/stages/' + stage_id + '/tasks')
    // );
    // enableStagesDropdown(({ stage_id }) =>
    //   redirect('/stages/' + stage_id + '/tasks')
    // );

    createSidebar({
      title: __('submenu.title.timesheets'),
      items: [
        {
          title: __('submenu.title.all-timesheets'),
          link: () => '/timesheets',
          active: true,
        },
      ],
    });

    (!this.props.auth.company ||
      _.includes(['administrator', 'owner'], this.props.auth.role)) &&
      // !_.isEmpty(this.state.team_roles.editor) &&
      setPlusActions([
        {
          title: __('button.create-new-timesheet'),
          onClick: () =>
            this.props.actions.setTableParams({
              wizard_active: true,
            }),
        },
      ]);
  }

  _handleOpacity(value, row) {
    return !row.deactivated ? (
      value
    ) : (
      <div className={styles.hidden}>{value}</div>
    );
  }

  render() {
    const columns = [
      {
        key: 'user_name',
        name: __('table.columns.timesheet-user'),
        width: 170,
        required: true,
        value: (value) => <Tooltip text={value}>{value}</Tooltip>,
      },
      {
        key: 'project_name',
        name: __('table.columns.timesheet-project'),
        width: 150,
        default: true,
        value: (value) =>
          value ? <Tooltip text={value}>{value}</Tooltip> : <TableNA />,
      },
      {
        key: 'stage_name',
        name: __('table.columns.timesheet-stage'),
        width: 150,
        default: true,
        value: (value) =>
          value ? <Tooltip text={value}>{value}</Tooltip> : <TableNA />,
      },
      {
        key: 'task_title',
        name: __('table.columns.timesheet-task'),
        width: 300,
        default: true,
        value: (x, row) =>
          x ? (
            <Tooltip
              text={`T-${row.task_identifier}: ${row.task_title}`}
            >{`T-${row.task_identifier}: ${row.task_title}`}</Tooltip>
          ) : (
            <TableNA />
          ),
      },
      {
        key: 'description',
        name: __('table.columns.timesheet-description'),
        default: true,
        value: (value) =>
          value ? <Tooltip text={value}>{value}</Tooltip> : <TableNA />,
      },
      {
        key: 'minutes',
        name: __('table.columns.timesheet-minutes'),
        width: 90,
        value: (value) => convertMinutesToHoursAndMinutes(value),
        required: true,
      },
      {
        key: 'date',
        name: __('table.columns.timesheet-date'),
        width: 75,
        required: true,
        value: (value) => (
          <Tooltip text={dateTimeFrom(value)}>{dateFrom(value)}</Tooltip>
        ),
      },
      {
        key: 'created_at',
        name: __('table.columns.timesheet-created-at'),
        width: 75,
        value: (value) => (
          <Tooltip text={dateTimeFrom(value)}>{dateFrom(value)}</Tooltip>
        ),
        active: false,
      },
      {
        key: 'updated_at',
        name: __('table.columns.timesheet-updated-at'),
        width: 75,
        value: (value) => (
          <Tooltip text={dateTimeFrom(value)}>{dateFrom(value)}</Tooltip>
        ),
        default: true,
      },
      {
        key: 'work_site_name',
        name: __('table.columns.timesheet-work-site'),
        width: 150,
        value: (value) =>
          value ? <Tooltip text={value}>{value}</Tooltip> : <TableNA />,
        default: true,
      },
      {
        key: 'is_billable',
        name: __('table.columns.timesheet-is-billable'),
        width: 50,
        value: (value) =>
          value
            ? __('table.timesheets.columns.is-billable-yes')
            : __('table.timesheets.columns.is-billable-no'),
        active: false,
      },
      {
        key: 'is_overtime',
        name: __('table.columns.timesheet-is-overtime'),
        width: 50,
        value: (value) =>
          value
            ? __('table.timesheets.columns.is-overtime-yes')
            : __('table.timesheets.columns.is-overtime-no'),
        active: false,
      },
    ];

    const singleActions = () => {
      let actions = [];
      actions.push([
        <Tooltip
          text={__('tasks.tooltip.icon.add-tasks-to-meeting')}
          key={_.uniqueId()}
        >
          <AddToMeetingIcon
            onClick={(e) => {
              e.stopPropagation();

              // setTableParams('all_meetings', {
              //   meeting_from_tasks: task_ids,
              // });
            }}
          />
        </Tooltip>,
      ]);

      return actions;
    };

    return (
      <Table
        listAction={listTimesheets}
        onRowClick={(id) => {
          const deactivated = _.get(
            _.find(this.props.table.data, { id }),
            'deactivated'
          );

          //   if (!deactivated) {
          //     redirect('/projects/' + id + '/stages');
          //   }
        }}
        name='timesheets'
        title={__('submenu.title.timesheets')}
        columns={columns}
        //singleActions={singleActions}
        //groupActions={groupActions}
        //gridOptions={gridOptions}
        defaultView='list'
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default Timesheets;
