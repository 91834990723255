import * as React from 'react';
import { Component } from 'react';

import { default as _, get, map } from 'lodash';
import Table from '../../components/Table';
import { connect } from 'react-redux';
import styles from '../../styles/views/profile/Notifications.scss';
import {
  createSidebar,
  updateSidebar,
} from '../../redux/actions/general/sidebar';
import TenderIcon from '../../assets/images/tender-15x15.svg';
import ProjectIcon from '../../assets/images/project-15x15.svg';
import PlanSetIcon from '../../assets/images/plan-set-15x15.svg';
import {
  listAllNotifications,
  markNotificationsAsRead,
  markNotificationsAsUnread,
  readNotification,
  readNotificationState,
  markAllNotificationsAsRead,
  markAllNotificationsAsUnread,
} from '../../redux/actions/table/notifications';
import classNames from 'classnames';
import {
  __,
  dateTimeFrom,
  getURLParam,
  dateFrom,
  setURLParam,
  timeFrom,
  getLocalized,
  hasURLParam,
} from '../../core/utils';
import { listActiveModules } from '../../redux/actions/general/active_modules';
import Tooltip from '../../components/Tooltip';
import { setNotification } from '../../redux/actions/general/notification';
import MessageMarkReadIcon from '../../assets/images/message-mark-read-16x16.svg';
import MessageMarkUnreadIcon from '../../assets/images/message-mark-unread-16x16.svg';
import CompanyIcon from '../../assets/images/company-16x16.svg';
import TasksIcon from '../../assets/images/tasks-16x16.svg';
import NotificationsFlyout from '../../flyouts/notifications/NotificationsFlyout';
import MeetingIcon from '../../assets/images/meeting-16x16.svg';
import Avatar from '../../components/Avatar';
import SpecificationsIcon from '../../assets/images/specifications-16x16.svg';
import { isMobile, isMobileOnly } from 'react-device-detect';
import TableNA from '../../components/TableNA';
import LogoIcon from '../../assets/images/logo-50x50.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import moment from 'moment';

const mapStateToProps = (state) => {
  return {
    table: state.table['notifications'],
    application_language_id: state.auth.language_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.notifications/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class Notifications extends Component {
  componentDidMount() {
    listActiveModules();

    createSidebar({
      title: __('submenu.title.profile-settings'),
      items: [
        {
          title: __('submenu.title.personal-details'),
          link: () => '/profile/details',
        },
        {
          title: __('submenu.title.security'),
          link: () => '/profile/security',
        },
        {
          title: __('submenu.title.notifications'),
          link: () => '/profile/notifications?filter.module=',
          active: true,
        },
        {
          title: __('submenu.title.notifications.settings'),
          link: () => '/profile/notifications/settings',
        },
      ],
    });
  }

  componentDidUpdate(prevProps) {
    //if there are new filter options available, update sidebar
    if (!_.isEqual(this.props.table.filters, prevProps.table.filters)) {
      const items = _.get(this.props.table.filters, 'module.items');

      const all_ids = _.keys(items);

      updateSidebar('items.2.items', [
        {
          title: __('submenu.title.all-modules'),
          onClick: () => setURLParam('filter.module', ''),
          active: () => {
            const active_modules = _.split(getURLParam('filter.module'), '|');

            const intersected = _.intersection(active_modules, _.keys(items));

            return (
              _.isEmpty(getURLParam('filter.module')) ||
              _.size(intersected) == _.size(items)
            );
          },
          checkbox: {
            status: () => {
              const active_modules = _.split(getURLParam('filter.module'), '|');

              const intersected = _.intersection(active_modules, _.keys(items));

              if (_.isEmpty(getURLParam('filter.module'))) {
                return 'checked';
              }

              if (_.isEmpty(intersected)) {
                return 'unchecked';
              } else if (_.size(intersected) == _.size(items)) {
                return 'checked';
              } else {
                return 'neutral';
              }
            },
            onClick: () => {
              const active_modules = _.split(getURLParam('filter.module'), '|');

              const intersected = _.intersection(active_modules, _.keys(items));

              if (
                _.size(intersected) == _.size(items) ||
                _.isEmpty(getURLParam('filter.module'))
              ) {
                setURLParam('filter.module', 'n/a');
              } else {
                setURLParam('filter.module', '');
              }
            },
          },
        },
        ..._.map(items, (x, module) => ({
          title: __('notifications.table.module-' + module),
          onClick: () => setURLParam('filter.module', module),
          active: () => {
            const active_modules = _.split(getURLParam('filter.module'), '|');

            return (
              _.isEmpty(getURLParam('filter.module')) ||
              _.includes(active_modules, module)
            );
          },
          checkbox: {
            status: () => {
              const active_modules = _.split(getURLParam('filter.module'), '|');

              if (_.isEmpty(getURLParam('filter.module'))) {
                return 'checked';
              }

              return _.includes(active_modules, module)
                ? 'checked'
                : 'unchecked';
            },
            onClick: () => {
              const setModules = (modules) => {
                modules = _.trim(
                  _.join(
                    _.reject(modules, (module) => module == 'n/a'),
                    '|'
                  ),
                  '|'
                );

                setURLParam(
                  'filter.module',
                  _.isEmpty(modules) ? 'n/a' : modules
                );
              };

              const active_modules =
                getURLParam('filter.module') == ''
                  ? all_ids
                  : _.split(getURLParam('filter.module'), '|');

              if (_.includes(active_modules, module)) {
                setModules(_.reject(active_modules, (row) => row == module));
              } else {
                active_modules.push(module);

                setModules(active_modules);
              }
            },
          },
        })),
      ]);
    }

    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      // if (!prevProps.table.clicked_row) {
      //   this.setState({ preview: undefined });
      // }

      this.setState({ preview: undefined });

      readNotification(this.props.table.clicked_row).then(({ response }) => {
        this.setState({ preview: response.data });

        if (prevProps.table.clicked_row) {
          markNotificationsAsRead(prevProps.table.clicked_row).then(() =>
            readNotificationState(prevProps.table.clicked_row)
          );
        }
      });
    }
  }

  _handleModuleIdentifier({ module, module_identifier, data }) {
    const specification = data?.specification;

    if (module === 'specifications' && specification?.subgroup_id) {
      const { specification_groups, specification_subgroups } =
        this.props.localization;

      const { subgroup_id, subgroup_code, code } = specification;

      const specification_subgroup =
        _.get(specification_subgroups, [subgroup_id]) || {};
      const specification_group =
        _.get(
          specification_groups,
          _.get(specification_subgroup, 'specification_group_id')
        ) || {};

      return (
        getLocalized(
          specification_group.code,
          this.props.application_language_id,
          1
        ) +
        subgroup_code +
        code
      );
    }

    return module_identifier;
  }

  _actionToColumnValues(action, data, row = 'title') {
    if (action == 'task_comment_added') {
      return row === 'title'
        ? __('notification.notification-title.task-comment-added', {
            TASK: _.last(_.split(data.task, ': ')),
          })
        : data.comment;
    }

    if (action == 'tasks_report_generated') {
      return row === 'title'
        ? __('notification.notification-title.tasks-report-generated')
        : __('notification.notification-content.tasks-report-generated');
    }

    if (action == 'task_status_changed') {
      return row === 'title'
        ? __('notification.notification-title.task-status-changed', {
            USER: data.user,
            TASK: _.last(_.split(data.task, ': ')),
            STATUS: data.status,
          })
        : data.status;
    }

    if (action == 'tender_status_changed') {
      return row === 'title'
        ? __('notification.notification-title.tender-status-changed', {
            USER: data.user,
            TENDER: _.last(_.split(data.tender, ': ')),
            STATUS: data.status,
          })
        : data.status;
    }

    if (action == 'specification_set_status_changed') {
      return row === 'title'
        ? __(
            'notification.notification-title.specification_set_status_changed',
            {
              USER: data.user,
              SPECIFICATION_SET: _.last(_.split(data.specification_set, ': ')),
              STATUS: data.status,
            }
          )
        : data.status;
    }

    if (action == 'bidder_status_changed') {
      return row === 'title'
        ? __('notification.notification-title.bidder-status-changed', {
            USER: data.user,
            TENDER: _.last(_.split(data.tender, ': ')),
            STATUS: data.status,
          })
        : data.status;
    }

    if (action == 'task_date_changed') {
      const renderDate = (date) => {
        const allDay = data.all_day;

        return allDay ? dateFrom(date) : dateTimeFrom(date);
      };

      let date =
        renderDate(data?.start_date) + ' - ' + renderDate(data?.end_date);

      return row === 'title'
        ? __('notification.notification-title.task-date-changed', {
            USER: data.user,
            TASK: _.last(_.split(data.task, ': ')),
            DATE: date,
          })
        : date;
    }

    if (action == 'task_visibility_changed') {
      return row === 'title'
        ? __('notification.notification-title.task-visibility-changed', {
            USER: data.user,
            TASK: _.last(_.split(data.task, ': ')),
            VISIBILITY: data.visibility,
          })
        : data.visibility;
    }

    if (action == 'added_as_task_assignee') {
      return row === 'title'
        ? __('notification.notification-title.added-as-task-assignee', {
            TASK: _.last(_.split(data.task, ': ')),
          })
        : data.user;
    }

    if (action == 'added_as_task_follower') {
      return row === 'title'
        ? __('notification.notification-title.added-as-task-follower', {
            TASK: _.last(_.split(data.task, ': ')),
          })
        : data.user;
    }

    if (action == 'added_as_tender_follower') {
      return row === 'title'
        ? __('notification.notification-title.added-as-tender-follower', {
            TENDER: _.last(_.split(data.tender, ': ')),
          })
        : data.user;
    }

    if (action == 'task_files_zip_download') {
      return row === 'title'
        ? __('notification.notification-title.task_files_zip_download', {
            TASK: _.last(_.split(data.task, ': ')),
          })
        : data.user;
    }

    if (action == 'added_as_tender_administrator') {
      return row === 'title'
        ? __('notification.notification-title.added-as-tender-administrator', {
            TENDER: _.last(_.split(data.tender, ': ')),
          })
        : data.user;
    }

    if (action == 'tender_date_changed') {
      const renderDate = (date) => {
        const allDay = data.all_day;

        return allDay ? dateFrom(date) : dateTimeFrom(date);
      };

      let date =
        renderDate(data?.start_date) + ' - ' + renderDate(data?.end_date);

      return row === 'title'
        ? __('notification.notification-title.tender-date-changed', {
            USER: data.user,
            TENDER: _.last(_.split(data.tender, ': ')),
            DATE: date,
          })
        : date;
    }

    if (action == 'added_as_stage_discipline_approver') {
      return row === 'title'
        ? __(
            'notification.notification-title.added-as-stage-discipline-approver',
            {
              DISCIPLINE: data.discipline,
              STAGE: data.stage,
              PROJECT: data.project,
            }
          )
        : data.user;
    }

    if (action == 'added_as_partner_company') {
      return row === 'title'
        ? __('notification.notification-title.added-as-partner-company', {
            PARTNER_COMPANY: data.partner_company,
          })
        : __('notification.notification-content.added-as-partner-company', {
            COMPANY: data.company,
          });
    }

    if (action == 'added_as_partner_member') {
      return row === 'title'
        ? __('notification.notification-title.added-as-partner-member', {
            USER: data.user,
            COMPANY: data.company,
          })
        : __('notification.notification-content.added-as-partner-member', {
            USER: data.user,
            COMPANY: data.company,
          });
    }

    if (action == 'partner_member_request') {
      return row === 'title'
        ? __('notification.notification-title.partner-member-request', {
            USER: data.user,
            COMPANY: data.company,
          })
        : __('notification.notification-content.partner-member-request', {
            USER: data.user,
            COMPANY: data.company,
          });
    }

    if (action == 'user_joined_company') {
      return row === 'title'
        ? __('notification.notification-title.user-joined-company', {
            USER: data.user,
          })
        : data.user;
    }

    if (action == 'team_member_invitation') {
      return row === 'title'
        ? __('notification.notification-title.team-member-invitation', {
            TEAM: data.team,
            STAGE: data.stage,
            PROJECT: data.project,
          })
        : __('notification.notification-content.team-member-invitation');
    }

    if (action == 'user_joined_team') {
      return row === 'title'
        ? __('notification.notification-title.user-joined-team', {
            TEAM: data.team,
          })
        : data.team;
    }

    if (action == 'checklist_item_status_changed') {
      return row === 'title'
        ? __('notification.notification-title.checklist-item-status-changed', {
            TASK: _.last(_.split(data.task, ': ')),
          })
        : data.status;
    }

    if (action == 'plan_versions_approved') {
      return row === 'title'
        ? __('notification.notification-title.plan-versions-approved', {
            DELIVERY: data.delivery,
            PROJECT: data.project,
            STAGE: data.stage,
          })
        : `${__('notification.notification-content.plan_versions_approved')} ${
            !_.isNull(data.message) ? '(' + data.message + ')' : ''
          }`;
    }

    if (action == 'current_set_specifications_approved') {
      return row === 'title'
        ? __(
            'notification.notification-title.current-set-specifications-approved',
            {
              DELIVERY: data.delivery,
            }
          )
        : `${__(
            'notification.notification-content.current-set-specifications-approved'
          )} ${!_.isNull(data.message) ? '(' + data.message + ')' : ''}`;
    }

    if (action == 'current_set_specifications_rejected') {
      return row === 'title'
        ? __(
            'notification.notification-title.current-set-specifications-rejected',
            {
              DELIVERY: data.delivery,
            }
          )
        : `${__(
            'notification.notification-content.current-set-specifications-rejected'
          )} ${!_.isNull(data.message) ? '(' + data.message + ')' : ''}`;
    }

    if (action == 'plan_versions_rejected') {
      return row === 'title'
        ? __('notification.notification-title.plan-versions-rejected', {
            DELIVERY: data.delivery,
            PROJECT: data.project,
            STAGE: data.stage,
          })
        : `${__('notification.notification-content.plan_versions_rejected')} ${
            !_.isNull(data.message) ? ' (' + data.message + ')' : ''
          }`;
    }

    if (action == 'current_set_plans_updated') {
      return row === 'title'
        ? __('notification.notification-title.current-set-plans-updated', {
            DISCIPLINE: data.discipline,
            STAGE: data.stage,
            PROJECT: data.project,
          })
        : __('notification.notification-content.current-set-plans-updated');
    }

    if (action == 'current_set_specifications_updated') {
      return row === 'title'
        ? __(
            'notification.notification-title.current-set-specifications-updated',
            {
              DISCIPLINE: data.discipline,
              STAGE: data.stage,
            }
          )
        : __(
            'notification.notification-content.current-set-specifications-updated'
          );
    }

    if (action == 'working_set_specifications_copied') {
      return row === 'title'
        ? __(
            'notification.notification-title.working_set_specifications_copied',
            {
              DISCIPLINE: data.discipline,
              STAGE: data.stage,
            }
          )
        : __(
            'notification.notification-content.working_set_specifications_copied'
          );
    }

    if (action == 'added_as_specification_set_editor') {
      return row === 'title'
        ? __(
            'notification.notification-title.added_as_specification_set_editor',
            {
              SPECIFICATION_SET: _.last(_.split(data.specification_set, ': ')),
            }
          )
        : data.user;
    }

    if (action == 'added_as_specification_set_administrator') {
      return row === 'title'
        ? __(
            'notification.notification-title.added_as_specification_set_administrator',
            {
              SPECIFICATION_SET: _.last(_.split(data.specification_set, ': ')),
            }
          )
        : data.user;
    }

    // if (action == 'team_membership_reactivated') {
    //   return row === 'title'
    //     ? __('notification.notification-title.team-membership-reactivated', {
    //         TEAM: data.team,
    //         STAGE: data.stage,
    //         PROJECT: data.project,
    //       })
    //     : data.team;
    // }

    // if (action == 'team_membership_deactivated') {
    //   return row === 'title'
    //     ? __('notification.notification-title.team-membership-deactivated', {
    //         TEAM: data.team,
    //         STAGE: data.stage,
    //         PROJECT: data.project,
    //       })
    //     : data.team;
    // }

    if (action == 'meeting_report_generated') {
      return row === 'title'
        ? __('notification.notification-title.meeting-report-generated', {
            MEETING: _.last(_.split(data.meeting, ': ')),
          })
        : _.first(data.reports).filename;
    }

    if (action == 'meeting_report') {
      return row === 'title'
        ? __('notification.notification-title.meeting-report', {
            MEETING: _.last(_.split(data.meeting, ': ')),
          })
        : _.first(data.reports).filename;
    }

    if (action == 'meeting_minutes') {
      return row === 'title'
        ? __('notification.notification-title.meeting-minutes', {
            MEETING: data.meeting,
          })
        : 'Meeting minutes';
    }

    if (action == 'meeting_review') {
      return row === 'title'
        ? __('notification.notification-title.meeting-review', {
            MEETING: _.last(_.split(data.meeting, ': ')),
          })
        : __('notification.notification-content.meeting-review');
    }

    if (action == 'meeting_closed') {
      return row === 'title'
        ? __('notification.notification-title.meeting-closed', {
            MEETING: _.last(_.split(data.meeting, ': ')),
          })
        : __('notification.notification-content.meeting-closed');
    }

    if (action == 'meeting_canceled') {
      return row === 'title'
        ? __('notification.notification-title.meeting-canceled', {
            MEETING: _.last(_.split(data.meeting, ': ')),
          })
        : __('notification.notification-content.meeting-canceled');
    }

    if (action == 'meeting_review_period_reminder') {
      return row === 'title'
        ? __('notification.notification-title.meeting-review-reminder', {
            MEETING: _.last(_.split(data.meeting, ': ')),
          })
        : data.review_period +
            ` ${__(
              'notification.notification-content.meeting-review-reminder'
            )}`;
    }

    if (action == 'plan_processing_finished') {
      return row === 'title'
        ? __('notification.notification-title.plan-processing-finished', {
            FILE: data.filename,
            PROJECT: data.project,
            STAGE: data.stage,
          })
        : __('notification.notification-content.plan-processing-finished');
    }

    if (action == 'added_as_delivery_approver') {
      return row === 'title'
        ? __('notification.notification-title.added-as-delivery-approver', {
            PROJECT: data.project,
            STAGE: data.stage,
          })
        : data.delivery;
    }

    if (action == 'task_file_added') {
      return row === 'title'
        ? __('notification.notification-title.task-file-added', {
            TASK: _.last(_.split(data.task, ': ')),
          })
        : data.file;
    }

    if (action == 'plan_file_added') {
      return row === 'title'
        ? __('notification.notification-title.plan-file-added', {
            PROJECT: data.project,
            STAGE: data.stage,
            PLAN: data.plan,
          })
        : data.file;
    }

    if (action == 'current_set_specifications_book_generated') {
      return row === 'title'
        ? __(
            'notification.notification-title.current-set-specifications-book-generated'
          )
        : __(
            'notification.notification-content.current-set-specifications-book-generated',
            {
              FILE: data.file,
            }
          );
    }

    if (action == 'working_set_specifications_book_generated') {
      return row === 'title'
        ? __(
            'notification.notification-title.working-set-specifications-book-generated'
          )
        : __(
            'notification.notification-content.working-set-specifications-book-generated',
            {
              FILE: data.file,
            }
          );
    }

    if (
      action == 'specification_drawing_added' ||
      action == 'specification_status_changed'
    ) {
      const { specification_groups, specification_subgroups } =
        this.props.localization;

      const { specification } = data;

      let specification_label;

      if (specification?.subgroup_id) {
        const specification_subgroup =
          _.get(specification_subgroups, [specification?.subgroup_id]) || {};
        const specification_group =
          _.get(
            specification_groups,
            _.get(specification_subgroup, 'specification_group_id')
          ) || {};

        specification_label =
          getLocalized(
            specification_group.code,
            this.props.application_language_id,
            1
          ) +
          specification?.subgroup_code +
          specification?.code +
          ' - ' +
          specification?.description;
      } else {
        specification_label = data.specification;
      }

      if (row === 'title') {
        if (action == 'specification_drawing_added') {
          return __(
            'notification.notification-title.specification-drawing-added',
            {
              PROJECT: data.project,
              SPECIFICATION: specification_label,
            }
          );
        } else if (action == 'specification_status_changed') {
          return __(
            'notification.notification-title.specification-status_changed',
            {
              PROJECT: data.project,
              SPECIFICATION: specification_label,
            }
          );
        }
      } else {
        if (action == 'specification_drawing_added') {
          return data.file;
        } else if (action == 'specification_status_changed') {
          return data.status;
        }
      }
    }

    if (action == 'task_checklist_item_status_changed') {
      return row === 'title'
        ? __(
            'notification.notification-title.task_checklist_item_status_changed',
            {
              TASK: _.last(_.split(data.task, ': ')),
            }
          )
        : data.status;
    }

    if (action == 'user_join_invitation_your_company') {
      return row === 'title'
        ? __(
            'notification.notification-title.user_join_invitation_your_company',
            {
              COMPANY: data.company,
              USER: data.user,
            }
          )
        : __(
            'notification.notification-content.user_join_invitation_your_company',
            {
              COMPANY: data.company,
              USER: data.user,
            }
          );
    }

    if (action == 'added_as_project_administrator') {
      return row === 'title'
        ? __('notification.notification-title.added_as_project_administrator', {
            PROJECT: data.project,
            USER: data.user,
          })
        : __(
            'notification.notification-content.added_as_project_administrator',
            {
              PROJECT: data.project,
              USER: data.user,
            }
          );
    }

    if (action == 'import_spreadsheet_failed') {
      return row === 'title'
        ? __('notification.notification-title.import_spreadsheet_failed')
        : __('notification.notification-content.import_spreadsheet_failed');
    }

    if (action == 'import_spreadsheet_finished') {
      return row === 'title'
        ? __('notification.notification-title.import_spreadsheet_finished')
        : __('notification.notification-content.import_spreadsheet_finished');
    }
  }

  render() {
    const columns = [
      {
        key: 'identifier',
        name: __('notifications.table.columns.read_identifier'),
        sortable: false,
        required: true,
        width: 25,
        value: (title, row) => (
          <div className={classNames(styles.status, row.read && styles.read)}>
            <span className={styles.dot} />
          </div>
        ),
      },

      {
        key: 'project',
        name: __('notifications.table.columns.project'),
        width: isMobile ? 30 : 70,
        sortable: false,
        value: (title, row) =>
          row.project ? (
            <Tooltip text={_.last(_.split(row.project, ': '))}>
              <div
                className={classNames(styles.project, row.read && styles.read)}
              >
                {_.first(_.split(row.project, ': '))}
              </div>
            </Tooltip>
          ) : (
            <TableNA />
          ),
      },

      {
        key: 'stage',
        name: __('notifications.table.columns.stage'),
        width: 50,
        sortable: false,
        value: (title, row) =>
          row.stage ? (
            <Tooltip text={_.last(_.split(row.stage, ': '))}>
              <div
                className={classNames(styles.stage, row.read && styles.read)}
              >
                {_.first(_.split(row.stage, ': '))}
              </div>
            </Tooltip>
          ) : (
            <TableNA />
          ),
      },

      {
        key: 'module',
        name: __('notifications.table.columns.module'),
        width: 50,
        fullWidth: true,
        sortable: false,
        value: (module) => (
          <Tooltip
            className={classNames(styles.module, isMobile && styles.mobile)}
            text={__('notifications.table.module-' + module)}
          >
            <div
              className={classNames(styles.module, isMobile && styles.mobile)}
            >
              {module === 'company' && (
                <CompanyIcon className={styles.companyIcon} />
              )}
              {module == 'tasks' && <TasksIcon className={styles.tasksIcon} />}
              {module == 'plans' && (
                <PlanSetIcon className={styles.plansIcon} />
              )}

              {module == 'meetings' && (
                <MeetingIcon className={styles.meetingIcon} />
              )}
              {module == 'projects' && (
                <ProjectIcon className={styles.projectIcon} />
              )}
              {module == 'specifications' && (
                <SpecificationsIcon className={styles.specificationsIcon} />
              )}

              {module == 'tenders' && <TenderIcon />}
            </div>
          </Tooltip>
        ),
      },

      {
        key: 'module_identifier',
        mobileVisible: true,
        name: __('notifications.table.columns.module_identifier'),
        sortable: false,
        value: (value, row) =>
          value ? (
            <div
              className={classNames(
                styles.module_identifier,
                row.read && styles.read
              )}
            >
              {this._handleModuleIdentifier(row)}
            </div>
          ) : (
            <TableNA />
          ),
        width: 80,
      },

      {
        key: 'title',
        name: __('notifications.table.columns.title'),
        sortable: false,
        required: true,
        default: true,
        value: (title, row) => (
          <div className={classNames(styles.status, row.read && styles.read)}>
            {row.count_of_group_notifications && (
              <div className={styles.count_of_group_notifications}>
                ({row.count_of_group_notifications}){' '}
              </div>
            )}

            <span>
              {this._actionToColumnValues(row.action, {
                ...row.data,
                project: row.project,
                stage: row.stage,
              })}
            </span>
          </div>
        ),
        default: true,
        mobileVisible: true,
      },

      {
        key: 'activity',
        name: __('notifications.table.columns.activity'),
        sortable: false,
        default: true,
        value: (title, row) => (
          <div className={classNames(styles.activity, row.read && styles.read)}>
            {__('notifications.table.activity-name.' + row.action)}
          </div>
        ),
        mobileVisible: true,
        required: true,
      },

      {
        key: 'content',
        name: __('notifications.table.columns.content'),
        sortable: false,
        value: (title, row) => (
          <div className={classNames(styles.status, row.read && styles.read)}>
            <span>
              {this._actionToColumnValues(row.action, row.data, 'content')}
            </span>
          </div>
        ),
        required: true,
      },

      {
        key: 'triggered_by',
        sortable: false,
        name: __('notifications.table.columns.triggered-by'),
        value: (user, row) =>
          user ? (
            <Avatar
              avatar_url={user.avatar_url}
              name={user.fullname + '\n' + user.company_name}
              active={user.active}
              tooltip={true}
            />
          ) : (
            <Tooltip text={'System'}>
              <LogoIcon className={styles.logo} />
            </Tooltip>
          ),

        sortable: false,
        width: 50,
      },

      {
        key: 'date_time',
        name: __('notifications.table.columns.when'),
        default: true,
        sortable: false,
        value: (value, row) => (
          <Tooltip text={timeFrom(moment.unix(row.created_at))}>
            {dateFrom(moment.unix(row.created_at))}
          </Tooltip>
        ),
        width: 80,
      },
    ];

    const singleActions = (notification_id) => {
      const findNotification = _.find(this.props.table.data, {
        id: notification_id,
      });

      const { read } = findNotification;

      const actions = read
        ? [
            <Tooltip
              text={__(
                'profile-settings.notifications.flyout.tooltip.mark-notification-as-unread'
              )}
              key={_.uniqueId()}
            >
              <MessageMarkUnreadIcon
                onClick={(e) => {
                  e.stopPropagation();

                  markNotificationsAsUnread([notification_id]).then(() => {
                    setNotification(
                      __(
                        'profile-settings.flyout.notifications.selected-notification-marked-unread'
                      )
                    );

                    readNotificationState(notification_id, false);

                    this.props.actions.setTableParams({
                      actioned_row: undefined,
                    });
                  });
                }}
              />
            </Tooltip>,
          ]
        : [
            <Tooltip
              text={__(
                'profile-settings.notifications.flyout.tooltip.mark-notification-as-read'
              )}
              key={_.uniqueId()}
            >
              <MessageMarkReadIcon
                onClick={(e) => {
                  e.stopPropagation();

                  markNotificationsAsRead(notification_id).then(() => {
                    setNotification(
                      __(
                        'profile-settings.flyout.notifications.selected-notification-marked-read'
                      )
                    );

                    readNotificationState(notification_id);

                    this.props.actions.setTableParams({
                      actioned_row: undefined,
                    });
                  });
                }}
              />
            </Tooltip>,
          ];

      return actions;
    };

    const groupActions = (ids) => {
      return (
        <Tooltip
          text={__(
            'profile-settings.notifications.flyout.tooltip.mark-selected-notifications-as-read'
          )}
          key={_.uniqueId()}
        >
          <MessageMarkReadIcon
            onClick={(e) => {
              e.stopPropagation();

              markNotificationsAsRead(ids).then(() => {
                setNotification(
                  __(
                    'profile-settings.notifications.flyout.tooltip.selected-notifications-marked-read'
                  )
                );

                readNotificationState(ids);

                this.props.actions.setTableParams({
                  selected_rows: [],
                });
              });
            }}
          />
        </Tooltip>
      );
    };

    const viewActions = [
      // <Tooltip
      //   key={_.uniqueId()}
      //   text={__('profile-settings.notifications.unread-all-notifications')}
      // >
      //   <MessageMarkUnreadIcon
      //     onClick={(e) => {
      //       e.stopPropagation();

      //       markAllNotificationsAsUnread().then(() => {
      //         setNotification(
      //           __(
      //             'profile-settings.notifications.flyout.tooltip.all-notifications-marked-unread'
      //           )
      //         );

      //         listAllNotifications();

      //         this.props.actions.setTableParams({
      //           selected_rows: [],
      //         });
      //       });
      //     }}
      //   />
      // </Tooltip>,

      <Tooltip
        key={_.uniqueId()}
        text={__('profile-settings.notifications.read-all-notifications')}
      >
        <MessageMarkReadIcon
          onClick={(e) => {
            e.stopPropagation();

            showAlertbox({
              color: 'red',
              title: __('general.alert.are-you-sure'),
              description: __('notifications.alert.read-all-notifications'),
              buttons: [
                {
                  color: 'lightGray',
                  text: __('general.alert.no-close'),
                  onClick: 'close',
                },
                {
                  color: 'gray',
                  text: __('general.alert.yes-read-all-notifications'),
                  onClick: () => {
                    markAllNotificationsAsRead().then(() => {
                      hideAlertbox();

                      setNotification(
                        __(
                          'profile-settings.notifications.flyout.tooltip.all-notifications-marked-read'
                        )
                      );

                      listAllNotifications();

                      this.props.actions.setTableParams({
                        selected_rows: [],
                      });
                    });
                  },
                },
              ],
            });
          }}
        />
      </Tooltip>,
    ];

    const preview = (
      <NotificationsFlyout
        data={_.get(this.state, 'preview', {})}
        readAction={(id) => {
          return new Promise((resolve) => {
            readNotification(getURLParam('id') || id).then(({ response }) => {
              readNotificationState(response.data.id);
              this.setState({ preview: response.data });
              resolve(response);
            });
          });
        }}
      />
    );

    const filtersSort = (filter, value) => {
      switch (filter) {
        case 'project':
          return _.sortBy(value);
      }
    };

    return (
      <Table
        filtersSort={filtersSort}
        listAction={listAllNotifications}
        name='notifications'
        title={__('submenu.title.notifications')}
        columns={columns}
        groupActions={!isMobileOnly && groupActions}
        singleActions={singleActions}
        viewActions={viewActions}
        preview={preview}
      />
    );
  }
}

export default Notifications;
