import * as React from 'react';
import { Component } from 'react';
import Table from '../../components/Table';
import { connect } from 'react-redux';
import { createSidebar } from '../../redux/actions/general/sidebar';
import { __, dateFrom, redirect, setURLParam } from '../../core/utils';
import { listActiveModules } from '../../redux/actions/general/active_modules';
import * as _ from 'lodash';
import { listSharedProjects } from '../../redux/actions/table/all_projects';

const mapStateToProps = (state) => {
  return {
    table: state.table['all_projects'],
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.all_projects/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class SharedProjects extends Component {
  componentDidMount() {
    createSidebar({
      title: __('submenu.title.projects'),
      items: [
        {
          title: __('submenu.title.all-projects'),
          link: () => '/projects',
          active: false,
        },
        {
          title: __('submenu.title.shared-projects'),
          link: () => '/projects/shared',
          active: true,
        },
      ],
    });
  }

  _handleOpacity(value, row) {
    return !row.deactivated ? (
      value
    ) : (
      <div className={styles.hidden}>{value}</div>
    );
  }

  render() {
    const columns = [
      {
        key: 'code',
        name: __('table.columns.project-number'),
        width: 80,
        value: (value, row) => this._handleOpacity(value, row),
      },
      {
        key: 'name',
        name: __('table.columns.project-title'),
        required: true,
        default: true,
        value: (value, row) => this._handleOpacity(value, row),
      },
      {
        key: 'description',
        name: __('table.columns.project-scope'),
        sortable: false,
        value: (value, row) => this._handleOpacity(value, row),
      },
      {
        key: 'company',
        name: __('table.columns.company'),
        value: (value, row) => this._handleOpacity(value, row),
      },
      {
        key: 'created_at',
        name: __('table.columns.created'),
        value: (value, row) => this._handleOpacity(dateFrom(value), row),
        width: 80,
      },
      {
        key: 'updated_at',
        name: __('table.columns.modified'),
        value: (value, row) => this._handleOpacity(dateFrom(value), row),
        width: 80,
      },
    ];

    const gridOptions = {
      section: __('submenu.title.shared-projects'),
      image: (id, row) => row.image_url,
      title: (row) => row.name,
      subTitle: (row) => row.description,
      content: (row) => [
        'created ' + dateFrom(row.created_at) + ' by ' + row.company,
      ],
    };

    return (
      <Table
        listAction={listSharedProjects}
        onRowClick={(id, row) => {
          redirect('/shared_with_me?filter.project=' + id);
        }}
        gridOptions={gridOptions}
        name='all_projects'
        title={__('submenu.title.shared-projects')}
        columns={columns}
        defaultView='list'
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default SharedProjects;
