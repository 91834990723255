import React, { memo, useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import styles from '../styles/components/Slider.scss';
import classNames from 'classnames';

const RangeSlider = memo(({ value, onChange, disabled }) => {
  const [sliderVal, setSliderVal] = useState(value);

  const debouncedUpdate = useCallback(
    _.debounce((newValue) => {
      onChange(newValue);
    }, 500),
    []
  );

  useEffect(() => {
    setSliderVal(value);
  }, [value]);

  const handleChange = (e) => {
    const newValue = Number(e.target.value);
    setSliderVal(newValue);
    debouncedUpdate(newValue);
  };

  const getSliderBackground = () => {
    return `linear-gradient(to right, #029DFC ${sliderVal}%, #ccc ${sliderVal}%)`; // Boja se menja do `thumb`
  };

  return (
    <div className={styles.rangeSlider}>
      <input
        type='range'
        value={sliderVal}
        min='0'
        style={{
          background: getSliderBackground(), // Dinamička pozadina
        }}
        max='100'
        disabled={disabled}
        step='1'
        className={classNames(
          styles.rangeSliderInput,
          disabled && styles.disabled
        )}
        onChange={handleChange}
      />
    </div>
  );
});

export default RangeSlider;
