import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/EditSpecificationsStatusWizard.scss';
import autobind from 'autobind-decorator';
import OCRIcon from '../assets/images/ocr-64x64.svg';
import {
  __,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import { withRouter } from 'react-router';
import OCRPlansForm from '../forms/ocr_plans_wizard/OCRPlansForm';
import { setNotification } from '../redux/actions/general/notification';
import EditSpecificationsStatusForm from '../forms/edit_specifications_status_wizard/EditSpecificationsStatusForm';
import TaskStatusIcon from '../assets/images/task-status-15x15.svg';
import {
  editSpecificationsStatus,
  readSpecificationSetItems,
} from '../redux/actions/table/specification_sets';
import { hideAlertbox, showAlertbox } from '../redux/actions/general/alertbox';
import { SubmissionError } from 'redux-form';

@withRouter
@mapStateToProps((state) => ({
  selected_specification_ids:
    state.table.specification_sets.selected_specification_ids,
}))
class EditSpecificationsStatusWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    if (!formData.status) {
      throw new SubmissionError({ status: ['Field required'] });
    }

    return showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __(
        'specification-set-items.alert.update-specifications-status-confirmation'
      ),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes'),
          onClick: () => {
            return validateForm(
              editSpecificationsStatus(
                this.props.selected_specification_ids,
                formData.status
              ).then(() => {
                setNotification(
                  __(
                    'wizard.specification-set-items.message.specifications-status-updated'
                  )
                );

                this.props.onClose();

                readSpecificationSetItems(
                  this.props.match.params.specification_set
                );

                hideAlertbox();

                setTableParams('specification_sets', {
                  selected_rows: [],
                });
              })
            );
          },
        },
      ],
    });
  }

  render() {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__(
                'wizard.specification-set-items.title.edit-status-for-selected-specifications'
              )}
            </span>
            <span>
              {__(
                'wizard.specification-set-items.subtitle.edit-status-for-selected-specifications'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <TaskStatusIcon />
          </div>
        </div>
        <div className={styles.body}>
          <EditSpecificationsStatusForm onSubmit={this._handleSubmit} />
        </div>
      </>
    );
  }
}

export default EditSpecificationsStatusWizard;
