import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/containers/LeftBar.scss';
import LogoIcon from '../assets/images/logo-50x50.svg';
import ProjectsIcon from '../assets/images/projects-24-24.svg';
import ProjectSettingsIcon from '../assets/images/project-settings-24x24.svg';
import PlansIcon from '../assets/images/plans-20x20.svg';
import TasksIcon from '../assets/images/tasks-20x20.svg';
import HelpIcon from '../assets/images/help-24x24.svg';
import BookCompaniesUsersIcon from '../assets/images/book-companies-users-20x20.svg';
import DocumentsIcon from '../assets/images/documents-24x24.svg';
import BOQIcon from '../assets/images/boq-24x24.svg';
import TenderIcon from '../assets/images/tender-24x24.svg';
import MeetingsIcon from '../assets/images/meetings-24x24.svg';
import SpaceAllocationIcon from '../assets/images/space-allocation-24x24.svg';
import ProductsIcon from '../assets/images/products-24x24.svg';
import SpecificationsIcon from '../assets/images/specifications-24x24.svg';
import SuperadminIcon from '../assets/images/superadmin-24x24.svg';
import SharedWithMeIcon from '../assets/images/share-with-me-20x20.svg';
import TimesheetIcon from '../assets/images/timesheet-icon.svg';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import { __, app_env, getActiveStage, redirect } from '../core/utils';
import { connect } from 'react-redux';
import Tooltip from '../components/Tooltip';

const mapStateToProps = (state) => {
  return {
    active_modules: state.general.active_modules,
    projects: state.table['all_projects'],
    auth: state.auth,
  };
};

@connect(mapStateToProps, null)
@withRouter
class LeftBar extends Component {
  constructor(props) {
    super(props);
  }

  matches(paths) {
    return _.includes(paths, this.props.match.path);
  }

  render() {
    const active_stage = getActiveStage();
    const { auth } = this.props;
    const company = !_.isEmpty(auth.company);

    return (
      <div className={styles.wrapper}>
        <Tooltip placement='right' text={__('mainmenu.tooltip.dashboard')}>
          <LogoIcon
            className={classNames(
              styles.logo,
              app_env == 'development' && styles.dev,
              app_env == 'local' && styles.local
            )}
            onClick={() => redirect('/')}
          />
        </Tooltip>
        <div className={styles.menu}>
          <Tooltip placement='right' text={__('mainmenu.tooltip.projects')}>
            <ProjectsIcon
              className={classNames(
                this.matches(['/(projects)?', '/projects/shared']) &&
                  styles.active,
                !company && styles.disabled
              )}
              onClick={() => company && redirect('/projects')}
            />
          </Tooltip>
          <Tooltip
            placement='right'
            text={__('mainmenu.tooltip.active-project-settings')}
          >
            <ProjectSettingsIcon
              className={classNames(
                this.matches([
                  '/projects/:project/stages',
                  '/stages/:stage/approvers',
                  '/stages/:stage/teams',
                  '/projects/:project/general',
                  '/import/:stage',
                ]) && styles.active,
                (!active_stage || !company) && styles.disabled
              )}
              onClick={() =>
                active_stage &&
                company &&
                redirect('/stages/' + active_stage + '/teams')
              }
            />
          </Tooltip>
          <Tooltip placement='right' text={__('mainmenu.tooltip.meetings')}>
            <MeetingsIcon
              className={classNames(
                this.matches([
                  '/stages/:stage/meetings',
                  '/meetings/:meeting',
                ]) && styles.active,
                (!active_stage || !company) && styles.disabled
              )}
              onClick={() =>
                active_stage &&
                company &&
                redirect('/stages/' + active_stage + '/meetings')
              }
            />
          </Tooltip>
          <Tooltip placement='right' text={__('mainmenu.tooltip.tasks')}>
            <TasksIcon
              className={classNames(
                this.matches(['/stages/:stage/tasks']) && styles.active,
                (!active_stage || !company) && styles.disabled
              )}
              onClick={() =>
                active_stage &&
                company &&
                redirect('/stages/' + active_stage + '/tasks')
              }
            />
          </Tooltip>
          <Tooltip placement='right' text={__('mainmenu.tooltip.plans')}>
            <PlansIcon
              className={classNames(
                this.matches([
                  '/stages/:stage/plans/uploads',
                  '/stages/:stage/ocr_templates',
                  '/ocr_templates/:ocr_template',
                  '/stages/:stage/plans/working',
                  '/plans/versions/:plan_version',
                  '/stages/:stage/deliveries/my',
                  '/stages/:stage/deliveries',
                  '/stages/:stage/deliveries/:type(my|public)/:delivery',
                  '/stages/:stage/approvals/:status(pending|approved|rejected)?',
                  '/stages/:stage/plans/current',
                  '/plans/revisions/:plan_revision',
                ]) && styles.active,
                (!active_stage || !company) && styles.disabled
              )}
              onClick={() =>
                active_stage &&
                company &&
                redirect('/stages/' + active_stage + '/plans/current')
              }
            />
          </Tooltip>
          <Tooltip placement='right' text={__('mainmenu.tooltip.products')}>
            <ProductsIcon
              className={classNames(
                this.matches(['/products']) && styles.active,
                !company && styles.disabled
              )}
              onClick={() => company && redirect('/products')}
            />
          </Tooltip>
          <Tooltip
            placement='right'
            text={__('mainmenu.tooltip.specifications')}
          >
            <SpecificationsIcon
              className={classNames(
                this.matches([
                  '/stages/:stage/specifications/working',
                  '/stages/:stage/specifications/current',
                  '/stages/:stage/specifications/sets/:specification_set',
                  '/stages/:stage/specification_deliveries/my',
                  '/stages/:stage/specification_deliveries',
                  '/stages/:stage/specification_deliveries/:type(my|public)/:specification_delivery',
                  '/stages/:stage/specification_approvals/:status(pending|approved|rejected)?',
                ]) && styles.active,
                (!active_stage || !company) && styles.disabled
              )}
              onClick={() =>
                active_stage &&
                company &&
                redirect('/stages/' + active_stage + '/specifications/current')
              }
            />
          </Tooltip>
          <Tooltip
            placement='right'
            text={__('mainmenu.tooltip.bill-of-quantities')}
          >
            <BOQIcon
              className={classNames(
                this.matches([
                  '/stages/:stage/boq/working',
                  '/stages/:stage/boq/import',
                ]) && styles.active,
                (!active_stage || !company) && styles.disabled
              )}
              onClick={() =>
                active_stage &&
                company &&
                redirect('/stages/' + active_stage + '/boq/working')
              }
            />
          </Tooltip>
          <Tooltip placement='right' text={__('mainmenu.tooltip.tenders')}>
            <TenderIcon
              className={classNames(
                this.matches([
                  '/stages/:stage/tenders',
                  '/stages/:stage/tenders/:tender/boq',
                ]) && styles.active,
                (!active_stage || !company) && styles.disabled
              )}
              onClick={() =>
                active_stage &&
                company &&
                redirect('/stages/' + active_stage + '/tenders')
              }
            />
          </Tooltip>
          <Tooltip
            placement='right'
            text={__('mainmenu.tooltip.space-allocation')}
          >
            <SpaceAllocationIcon
              className={classNames(
                this.matches([
                  '/stages/:stage/space_allocation/sites',
                  '/stages/:stage/space_allocation/buildings',
                  '/stages/:stage/space_allocation/storeys',
                  '/stages/:stage/space_allocation/zones',
                  '/stages/:stage/space_allocation/expansion_joints',
                  '/stages/:stage/space_allocation/entrances',
                  '/stages/:stage/space_allocation/rooms',
                  '/stages/:stage/space_allocation/room_groups',
                  '/stages/:stage/space_allocation/room_types',
                ]) && styles.active,
                (!active_stage || !company) && styles.disabled
              )}
              onClick={() =>
                active_stage &&
                company &&
                redirect('/stages/' + active_stage + '/space_allocation/rooms')
              }
            />
          </Tooltip>
          {/* <Tooltip placement='right' text={__('mainmenu.tooltip.documents')}>
            <DocumentsIcon
              className={classNames(
                this.matches(['/stages/:stage/documents']) && styles.active,
                (!active_stage || !company) && styles.disabled
              )}
              onClick={() =>
                active_stage &&
                company &&
                redirect('/stages/' + active_stage + '/documents')
              }
            />
          </Tooltip> */}
          <Tooltip
            placement='right'
            text={__('mainmenu.tooltip.companies-and-users')}
          >
            <BookCompaniesUsersIcon
              className={classNames(
                this.matches([
                  '/companies',
                  '/companies/partner',
                  '/companies/partner_members',
                  '/companies/my/members',
                  '/address_book/companies',
                  '/address_book/contacts',
                ]) && styles.active,
                !company && styles.disabled
              )}
              onClick={() => company && redirect('/companies/my/members')}
            />
          </Tooltip>

          <Tooltip
            placement='right'
            text={__('mainmenu.tooltip.shared_with_me')}
          >
            <SharedWithMeIcon
              className={classNames(
                this.matches(['/shared_with_me']) && styles.active
              )}
              onClick={() => redirect('/shared_with_me')}
            />
          </Tooltip>
          {auth.superadmin && (
            <Tooltip placement='right' text={__('mainmenu.tooltip.superadmin')}>
              <SuperadminIcon
                className={classNames(
                  this.matches([
                    '/superadmin/users',
                    '/superadmin/companies',
                    '/superadmin/materials',
                    '/superadmin/raw_materials',
                    '/superadmin/languages',
                    '/superadmin/translations',
                    '/superadmin/note_categories',
                    '/superadmin/notes',
                    '/superadmin/colors',
                    '/superadmin/paper_sizes',
                    '/superadmin/product_attribute_template_groups',
                    '/superadmin/product_attribute_template_fields',
                    '/superadmin/countries',
                    '/superadmin/units',
                    '/superadmin/regions',
                    '/superadmin/unit_groups',
                    '/superadmin/building_types',
                    '/superadmin/functional_spaces',
                    '/superadmin/disciplines',
                    '/superadmin/raw_colors',
                    '/superadmin/product_categories',
                    '/superadmin/product_groups',
                    '/superadmin/product_subgroups',
                    '/superadmin/specification_groups',
                    '/superadmin/specification_subgroups',
                    '/superadmin/boq_template_groups',
                    '/superadmin/boq_template_subgroups',
                    '/superadmin/boq_template_calculations',
                  ]) && styles.active
                )}
                onClick={() => {
                  if (auth.superadmin.list_companies) {
                    redirect('/superadmin/companies');
                  } else if (auth.superadmin.list_users) {
                    redirect('/superadmin/users');
                  } else if (auth.superadmin.list_materials) {
                    redirect('/superadmin/materials');
                  } else if (auth.superadmin.list_raw_materials) {
                    redirect('/superadmin/raw_materials');
                  } else if (auth.superadmin.list_languages) {
                    redirect('/superadmin/languages');
                  } else if (auth.superadmin.list_translations) {
                    redirect('/superadmin/translations');
                  } else if (auth.superadmin.list_note_categories) {
                    redirect('/superadmin/note_categories');
                  } else if (auth.superadmin.list_notes) {
                    redirect('/superadmin/notes');
                  } else if (auth.superadmin.list_colors) {
                    redirect('/superadmin/colors');
                  } else if (auth.superadmin.list_paper_sizes) {
                    redirect('/superadmin/paper_sizes');
                  } else if (
                    auth.superadmin.list_product_attribute_template_groups
                  ) {
                    redirect('/superadmin/product_attribute_template_groups');
                  } else if (
                    auth.superadmin.list_product_attribute_template_fields
                  ) {
                    redirect('/superadmin/product_attribute_template_fields');
                  } else if (auth.superadmin.list_countries) {
                    redirect('/superadmin/countries');
                  } else if (auth.superadmin.list_units) {
                    redirect('/superadmin/units');
                  } else if (auth.superadmin.list_regions) {
                    redirect('/superadmin/regions');
                  } else if (auth.superadmin.list_unit_groups) {
                    redirect('/superadmin/unit_groups');
                  } else if (auth.superadmin.list_building_types) {
                    redirect('/superadmin/building_types');
                  } else if (auth.superadmin.list_functional_spaces) {
                    redirect('/superadmin/functional_spaces');
                  } else if (auth.superadmin.list_disciplines) {
                    redirect('/superadmin/disciplines');
                  } else if (auth.superadmin.list_raw_colors) {
                    redirect('/superadmin/raw_colors');
                  } else if (auth.superadmin.list_product_categories) {
                    redirect('/superadmin/product_categories');
                  } else if (auth.superadmin.list_product_groups) {
                    redirect('/superadmin/product_groups');
                  } else if (auth.superadmin.list_product_subgroups) {
                    redirect('/superadmin/product_subgroups');
                  } else if (auth.superadmin.list_specification_groups) {
                    redirect('/superadmin/specification_groups');
                  } else if (auth.superadmin.list_specification_subgroups) {
                    redirect('/superadmin/specification_subgroups');
                  } else if (auth.superadmin.list_boq_template_groups) {
                    redirect('/superadmin/boq_template_groups');
                  } else if (auth.superadmin.list_boq_template_subgroups) {
                    redirect('/superadmin/boq_template_subgroups');
                  } else if (auth.superadmin.list_boq_template_calculations) {
                    redirect('/superadmin/boq_template_calculations');
                  } else if (auth.superadmin.list_boq_supply_types) {
                    redirect('/superadmin/boq_supply_types');
                  } else if (auth.superadmin.list_boq_supply_type_groups) {
                    redirect('/superadmin/boq_supply_type_groups');
                  } else if (auth.superadmin.list_filters) {
                    redirect('/superadmin/filters');
                  } else if (auth.superadmin.list_products) {
                    redirect('/superadmin/products');
                  }
                }}
              />
            </Tooltip>
          )}
          <Tooltip placement='right' text={__('mainmenu.tooltip.timesheets')}>
            <TimesheetIcon
              className={classNames(
                this.matches(['/timesheets']) && styles.active,
                !company && styles.disabled
              )}
              onClick={() => company && redirect('/timesheets')}
              style={{ fill: '#CCCCCA' }}
            />
          </Tooltip>
        </div>
        <div className={styles.info}>{__('mainmenu.help')}</div>
        <div className={styles.separator} />
        <Tooltip placement='right' text={__('mainmenu.tooltip.help')}>
          <a href='https://volum3.com/en/help/' target='_blank'>
            <HelpIcon className={styles.bug} />
          </a>
        </Tooltip>
      </div>
    );
  }
}

export default LeftBar;
