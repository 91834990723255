import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/views/company/CompanyMarket.scss';
import Image from '../../components/Image';
import { connect } from 'react-redux';
import { __, validateForm } from '../../core/utils';
import { bindActionCreators } from 'redux';
import MainLayout from '../../layouts/MainLayout';
import PhotoIcon from '../../assets/images/photo-15x15.svg';
import autobind from 'autobind-decorator';
import {
  readCompany,
  updateCompanyMarket,
  uploadLogo,
} from '../../redux/reducers/companies';
import CompanyMarketForm from '../../forms/company/CompanyMarketForm';
import { setNotification } from '../../redux/actions/general/notification';
import { createSidebar } from '../../redux/actions/general/sidebar';
import Tooltip from '../../components/Tooltip';
import DefaultImage from '../../assets/images/default.png';
import { listActiveModules } from '../../redux/actions/general/active_modules';
import TitleBar from '../../containers/TitleBar';

const mapStateToProps = (state) => {
  return {
    company: _.get(state.companies, state.auth.company.id, []),
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        readCompany,
        updateCompanyMarket,
        uploadLogo,
      },
      dispatch
    ),
  };
};
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class CompanyMarket extends Component {
  componentDidMount() {
    listActiveModules();

    createSidebar({
      title: __('topbar.account.company-settings'),
      items: [
        {
          title: __('submenu.title.company-details'),
          link: () => '/company/details',
        },
        {
          title: __('submenu.title.offices'),
          link: () => '/company/offices',
        },
        {
          title: __('company-settings.submenu.title.brands'),
          link: () => '/company/brands',
        },
        {
          title: __('company-settings.submenu.title.disciplines'),
          link: () => '/company/disciplines',
        },
        {
          title: __('submenu.title.market'),
          link: () => '/company/market',
          active: true,
        },
        // {
        //     title: __("submenu.title.subscription-and-billing"),
        //     link: () => "/company/subscription"
        // }
      ],
    });

    this.props.actions.readCompany(this.props.auth.company.id);
  }

  _handleSubmit(formData) {
    return validateForm(
      this.props.actions
        .updateCompanyMarket(this.props.auth.company.id, formData)
        .then(() => {
          this.props.actions.readCompany(this.props.auth.company.id);
          setNotification(__('company.notification.market-saved'));
        })
    );
  }

  _handleUploadLogo(e) {
    if (e.target.files && e.target.files.length) {
      setNotification(__('company.notification.logo-is-being-uploaded'));

      this.props.actions
        .uploadLogo(this.props.auth.company.id, e.target.files[0])
        .then(() => {
          setNotification(__('company.notification.company-logo-updated'));
          this.props.actions.readCompany(this.props.auth.company.id);
        });
    }
  }

  render() {
    const company = this.props.company;
    const headquarters = _.get(company, ['offices', '0'], {});

    return (
      <>
        <TitleBar toolbar title={__('market')} />
        <div className={styles.header}>
          <div className={styles.logo}>
            <Image src={company.logo_url} default={DefaultImage} />
            <div className={styles.actions}>
              <Tooltip text={__('content.tooltip.account.company-logo')}>
                <PhotoIcon onClick={() => this.refs.logo.click()} />
              </Tooltip>
              <input
                type='file'
                ref='logo'
                onChange={this._handleUploadLogo}
                accept='image/x-png,image/jpeg'
              />
            </div>
          </div>
          <div className={styles.user}>
            <div className={styles.name}>{company.name}</div>
            <div className={styles.info}>
              <span>
                {company.description &&
                  _.get(company.description, this.props.auth.language_id, '') +
                    ' '}
              </span>
              <span>
                {headquarters.address}, {headquarters.zip} {headquarters.city},{' '}
                {_.get(
                  _.find(this.props.localization.countries, {
                    id: headquarters.country_id,
                  }),
                  'name'
                )}{' '}
                · {_.first(headquarters.emails)}
              </span>
            </div>
          </div>
          {/*<div className={styles.item}>*/}
          {/*<div className={styles.count}>0</div>*/}
          {/*<div className={styles.description}>{__("Active projects")}</div>*/}
          {/*</div>*/}
          {/*<div className={styles.item}>*/}
          {/*<div className={styles.count}>0</div>*/}
          {/*<div className={styles.description}>{__("Unresolved tasks")}</div>*/}
          {/*</div>*/}
          {/*<div className={styles.item}>*/}
          {/*<div className={styles.count}>0</div>*/}
          {/*<div className={styles.description}>{__("Unread messages")}</div>*/}
          {/*</div>*/}
        </div>
        <div className={styles.content}>
          {!_.isEmpty(company) && (
            <CompanyMarketForm
              onSubmit={this._handleSubmit}
              initialValues={_.pick(company, ['market'])}
            />
          )}
        </div>
      </>
    );
  }
}

export default CompanyMarket;
