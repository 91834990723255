import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/views/profile/PersonalDetails.scss';
import Image from '../../components/Image';
import { connect } from 'react-redux';
import PersonalDetailsForm from '../../forms/profile/PersonalDetailsForm';
import {
  deleteAvatar,
  updateProfile,
  uploadAvatar,
} from '../../redux/reducers/profile';
import {
  __,
  getLocalizedListValue,
  setPageTitle,
  validateForm,
} from '../../core/utils';
import { bindActionCreators } from 'redux';
import MainLayout from '../../layouts/MainLayout';
import AvatarImage from '../../assets/images/avatar.png';
import PhotoIcon from '../../assets/images/photo-15x15.svg';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import autobind from 'autobind-decorator';
import { setNotification } from '../../redux/actions/general/notification';
import { createSidebar } from '../../redux/actions/general/sidebar';
import Tooltip from '../../components/Tooltip';
import { getUser } from '../../redux/actions/auth';
import { listLocalization } from '../../redux/actions/localization';
import { listActiveModules } from '../../redux/actions/general/active_modules';
import { readProfile } from '../../redux/actions/profile';
import TitleBar from '../../containers/TitleBar';

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        updateProfile,
        uploadAvatar,
        deleteAvatar,
      },
      dispatch
    ),
  };
};
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class PersonalDetails extends Component {
  componentDidMount() {
    listActiveModules();

    createSidebar({
      title: __('submenu.title.profile-settings'),
      items: [
        {
          title: __('submenu.title.personal-details'),
          link: () => '/profile/details',
          active: true,
        },
        {
          title: __('submenu.title.security'),
          link: () => '/profile/security',
        },
        {
          title: __('submenu.title.notifications'),
          link: () => '/profile/notifications',
        },
        {
          title: __('submenu.title.notifications.settings'),
          link: () => '/profile/notifications/settings',
        },
      ],
    });

    readProfile();
  }

  _handleSubmit(formData) {
    return validateForm(
      this.props.actions
        .updateProfile(
          _.pick(formData, [
            'title',
            'firstname',
            'lastname',
            'phone_number',
            'disciplines',
            'password',
            'password_confirmation',
            'language_id',
            'timezone_id',
          ])
        )
        .then(() => {
          if (this.props.profile.language_id != formData.language_id) {
            listLocalization().then(() => {
              getUser();
            });
          } else {
            readProfile();
            getUser();
          }

          setNotification(__('personal-details.notification.saved'));
        })
    );
  }

  _handleUploadAvatar(e) {
    if (e.target.files && e.target.files.length) {
      setNotification(__('personal-details.notification.avatar-uploading'));

      this.props.actions.uploadAvatar(e.target.files[0]).then(() => {
        setNotification(__('personal-details.notification.avatar-uploaded'));
        readProfile();
        getUser();
      });
    }
  }

  _handleDeleteAvatar() {
    this.props.actions.deleteAvatar().then(() => {
      setNotification(__('personal-details.notification.avatar-deleted'));
      readProfile();
      getUser();
    });
  }

  render() {
    return (
      <>
        <TitleBar toolbar title={__('personal_details')} />
        <div className={styles.header}>
          <div className={styles.avatar}>
            <Image src={this.props.profile.avatar_url} default={AvatarImage} />
            <div className={styles.actions}>
              <Tooltip text={__('content.tooltip.account.avatar-add')}>
                <PhotoIcon onClick={() => this.refs.avatar.click()} />
              </Tooltip>
              <input
                type='file'
                ref='avatar'
                onChange={this._handleUploadAvatar}
                accept='image/x-png,image/jpeg'
              />
              {this.props.profile.avatar_url && (
                <>
                  <div className={styles.separator} />
                  <Tooltip text={__('content.tooltip.account.avatar-delete')}>
                    <DeleteIcon onClick={this._handleDeleteAvatar} />
                  </Tooltip>
                </>
              )}
            </div>
          </div>
          <div className={styles.user}>
            <div className={styles.name}>
              {this.props.profile.title &&
                _.upperFirst(this.props.profile.title) + ' '}
              {this.props.profile.firstname} {this.props.profile.lastname}
            </div>
            <div className={styles.info}>
              {!_.isEmpty(this.props.profile.disciplines) &&
                _.map(this.props.profile.disciplines, (discipline) =>
                  getLocalizedListValue(
                    this.props.localization.disciplines,
                    discipline,
                    'profession'
                  )
                ).join(', ') + ' · '}
              {this.props.profile.company} · {this.props.profile.email}
            </div>
          </div>
          {/*<div className={styles.item}>*/}
          {/*<div className={styles.count}>0</div>*/}
          {/*<div className={styles.description}>{__("Active projects")}</div>*/}
          {/*</div>*/}
          {/*<div className={styles.item}>*/}
          {/*<div className={styles.count}>0</div>*/}
          {/*<div className={styles.description}>{__("Unresolved tasks")}</div>*/}
          {/*</div>*/}
          {/*<div className={styles.item}>*/}
          {/*<div className={styles.count}>0</div>*/}
          {/*<div className={styles.description}>{__("Unread messages")}</div>*/}
          {/*</div>*/}
        </div>
        <div className={styles.content}>
          <PersonalDetailsForm
            onSubmit={this._handleSubmit}
            initialValues={_.pick(this.props.profile, [
              'title',
              'firstname',
              'lastname',
              'disciplines',
              'email',
              'phone_number',
              'company',
              'language_id',
              'timezone_id',
            ])}
          />
        </div>
      </>
    );
  }
}

export default PersonalDetails;
