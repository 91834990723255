import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import {
  createSidebar,
  updateSidebar,
} from '../../redux/actions/general/sidebar';
import {
  __,
  backendURL,
  dateFrom,
  getActiveStage,
  getURLParam,
  hasURLParam,
  redirect,
  setTableParams,
  setURLParam,
  sortValuesByArray,
} from '../../core/utils';
import Table from '../../components/Table';
import TargetIcon from '../../assets/images/target-15x15.svg';
import { listAllTasks, readTask } from '../../redux/actions/table/all_tasks';
import { setPlusActions } from '../../redux/actions/general/plus';
import styles from '../../styles/views/tasks/AllTasks.scss';
import TaskDecisionIcon from '../../assets/images/task-decision-15x15.svg';
import TaskTodoIcon from '../../assets/images/task-todo-15x15.svg';
import TaskRfiIcon from '../../assets/images/task-rfi-15x15.svg';
import TaskFyiIcon from '../../assets/images/task-fyi-15x15.svg';
import TaskIssueIcon from '../../assets/images/task-issue-15x15.svg';
import TaskMilestoneIcon from '../../assets/images/task-milestone-15x15.svg';
import AddToMeetingIcon from '../../assets/images/add-to-meeting-16x16.svg';
import ExportIcon from '../../assets/images/export-15x15.svg';
import PrintIcon from '../../assets/images/print-16x16.svg';
import classNames from 'classnames';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import TasksFlyout from '../../flyouts/tasks/TasksFlyout';
import Tooltip from '../../components/Tooltip';
import { downloadFile } from '../../redux/actions/general/downloader';
import { setNotification } from '../../redux/actions/general/notification';
import { getTeamsPermissions } from '../../redux/actions/table/teams';
import ArrowDoubleRightSmallIcon from '../../assets/images/arrow-double-right-small-15x15.svg';
import moment from 'moment';
import TableNA from '../../components/TableNA';
import Avatar from '../../components/Avatar';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import Dropdown from '../../components/Dropdown';
import {
  leaveChannel,
  listenPlanProcessed,
  listenTaskSaved,
} from '../../core/sockets';
import { readWorkingSetPlan } from '../../redux/actions/table/working_set_plans';
import TagIcon from '../../assets/images/tag-16x16.svg';

const mapStateToProps = (state) => {
  return {
    table: state.table['all_tasks'],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.all_tasks/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class AllTasks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      team_roles: [],
    };
  }

  componentDidMount() {
    getTeamsPermissions(this.props.match.params.stage).then(({ response }) => {
      this.setState({ team_roles: _.get(response.data, 'teams', {}) });

      !_.isEmpty(this.state.team_roles.editor) &&
        setPlusActions([
          {
            title: __('tasks.table.button.create'),
            onClick: () =>
              this.props.actions.setTableParams({
                wizard_active: true,
                x: undefined,
                y: undefined,
                stage_id: undefined,
              }),
          },
        ]);

      enableProjectsDropdown(({ stage_id }) =>
        redirect('/stages/' + stage_id + '/tasks')
      );
      enableStagesDropdown(({ stage_id }) =>
        redirect('/stages/' + stage_id + '/tasks')
      );

      createSidebar({
        title: __('submenu.title.tasks'),
        items: [
          {
            title: __('submenu.title.task-list'),
            link: () => '/stages/' + getActiveStage() + '/tasks?filter.type=',
            active: true,
          },
        ],
      });

      if (!hasURLParam('filter.type')) {
        setURLParam('filter.type', '');
      }

      setURLParam(
        'filter.status',
        'created|started|finished|closed|reopened|not-started'
      );
    });
  }

  componentDidUpdate(prevProps) {
    //if there are new filter options available, update sidebar
    if (!_.isEqual(this.props.table.filters, prevProps.table.filters)) {
      const items = _.keys(_.get(this.props.table.filters, 'type.items'));

      const all_ids = items;

      updateSidebar('items.0.items', [
        {
          title: __('submenu.title.all-tasks'),
          onClick: () => setURLParam('filter.type', ''),
          active: () => {
            const active_types = _.split(getURLParam('filter.type'), '|');

            const intersected = _.intersection(active_types, items);

            return (
              _.isEmpty(getURLParam('filter.type')) ||
              _.size(intersected) == _.size(items)
            );
          },
          checkbox: {
            status: () => {
              const active_types = _.split(getURLParam('filter.type'), '|');

              const intersected = _.intersection(active_types, items);

              if (_.isEmpty(getURLParam('filter.type'))) {
                return 'checked';
              }

              if (_.isEmpty(intersected)) {
                return 'unchecked';
              } else if (_.size(intersected) == _.size(items)) {
                return 'checked';
              } else {
                return 'neutral';
              }
            },
            onClick: () => {
              const active_types = _.split(getURLParam('filter.type'), '|');

              const intersected = _.intersection(active_types, items);

              if (
                _.size(intersected) == _.size(items) ||
                _.isEmpty(getURLParam('filter.type'))
              ) {
                setURLParam('filter.type', 'n/a');
              } else {
                setURLParam('filter.type', '');
              }
            },
          },
        },
        ..._.map(items, (type) => ({
          title: __('tasks.tooltip.icon.' + type),
          onClick: () => setURLParam('filter.type', type),
          active: () => {
            const active_types = _.split(getURLParam('filter.type'), '|');

            return (
              _.isEmpty(getURLParam('filter.type')) ||
              _.includes(active_types, type)
            );
          },
          checkbox: {
            status: () => {
              const active_types = _.split(getURLParam('filter.type'), '|');

              if (_.isEmpty(getURLParam('filter.type'))) {
                return 'checked';
              }

              return _.includes(active_types, type) ? 'checked' : 'unchecked';
            },
            onClick: () => {
              const setTypes = (types) => {
                types = _.trim(
                  _.join(
                    _.reject(types, (type) => type == 'n/a'),
                    '|'
                  ),
                  '|'
                );

                setURLParam('filter.type', _.isEmpty(types) ? 'n/a' : types);
              };

              const active_types =
                getURLParam('filter.type') == ''
                  ? all_ids
                  : _.split(getURLParam('filter.type'), '|');

              if (_.includes(active_types, type)) {
                setTypes(_.reject(active_types, (row) => row == type));
              } else {
                active_types.push(type);

                setTypes(active_types);
              }
            },
          },
        })),
      ]);
    }

    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        setTableParams('all_tasks', {
          flyout: undefined,
        });
      }

      readTask(this.props.table.clicked_row);
    }
  }

  _openCommentsTab(id) {
    this.props.actions.setTableParams({
      clicked_row: id,
      showFilters: undefined,
      tab: 'comments',
    });
  }

  _openFilesTab(id) {
    this.props.actions.setTableParams({
      clicked_row: id,
      showFilters: undefined,
      tab: 'files',
    });
  }

  _openMeetingsTab(id) {
    this.props.actions.setTableParams({
      clicked_row: id,
      showFilters: undefined,
      tab: 'meetings',
    });
  }

  render() {
    const columns = [
      {
        key: 'identifier',
        name: __('table.columns.task-identifier'),
        value: (value) => 'T-' + value,
        width: 70,
        default: true,
        desc: true,
      },
      {
        key: 'type',
        name: __('table.columns.task-type'),
        value: (type, row) => {
          switch (type) {
            case 'rfi':
              return (
                <Tooltip text={__('tasks.tooltip.icon.rfi')}>
                  <TaskRfiIcon
                    className={classNames(
                      styles.rfiIcon,
                      this.props.table.clicked_row == row.id && styles.active
                    )}
                  />
                </Tooltip>
              );

            case 'fyi':
              return (
                <Tooltip text={__('tasks.tooltip.icon.fyi')}>
                  <TaskFyiIcon
                    className={classNames(
                      styles.fyiIcon,
                      this.props.table.clicked_row == row.id && styles.active
                    )}
                  />
                </Tooltip>
              );

            case 'decision':
              return (
                <Tooltip text={__('tasks.tooltip.icon.decision')}>
                  <TaskDecisionIcon
                    className={classNames(
                      styles.decisionIcon,
                      this.props.table.clicked_row == row.id && styles.active
                    )}
                  />
                </Tooltip>
              );

            case 'todo':
              return (
                <Tooltip text={__('tasks.tooltip.icon.todo')}>
                  <TaskTodoIcon
                    className={classNames(
                      styles.todoIcon,
                      this.props.table.clicked_row == row.id && styles.active
                    )}
                  />
                </Tooltip>
              );

            case 'milestone':
              return (
                <Tooltip text={__('tasks.tooltip.icon.milestone')}>
                  <TaskMilestoneIcon
                    className={classNames(
                      styles.milestoneIcon,
                      this.props.table.clicked_row == row.id && styles.active
                    )}
                  />
                </Tooltip>
              );

            case 'issue':
              return (
                <Tooltip text={__('tasks.tooltip.icon.issue')}>
                  <TaskIssueIcon
                    className={classNames(
                      styles.issueIcon,
                      this.props.table.clicked_row == row.id && styles.active
                    )}
                  />
                </Tooltip>
              );
          }
        },
        width: 50,
      },
      {
        key: 'title',
        name: __('table.columns.task-title'),
        required: true,
        mobileVisible: true,
      },
      {
        key: 'area',
        overflow: true,
        name: __('table.columns.area'),
        value: (x, row) => {
          if (
            !_.reduce(
              row.area,
              (sum, area) => {
                return sum + _.size(area);
              },
              0
            )
          ) {
            return <TableNA />;
          } else {
            return (
              <Dropdown
                top={23}
                right={-5}
                name={'area.' + row.id}
                header={
                  <div className={styles.targetDropdownHeader}>
                    {__('table.columns.area')}
                    <TargetIcon />
                  </div>
                }
                content={
                  <div className={styles.targetDropdownContent}>
                    {_.map(row.area, (area, i) => {
                      return _.map(area, (data, j) => {
                        return (
                          <div
                            className={styles.item}
                            key={j}
                            onClick={(e) => {
                              e.preventDefault();
                              redirect(
                                `/stages/${data.stage_id}/space_allocation/${i}?id=${data.id}`
                              );
                            }}
                          >
                            <Tooltip
                              text={`${data.code}: ${_.first(
                                _.values(data.name)
                              )}`}
                            >
                              <span>
                                {data.code}: {_.first(_.values(data.name))}
                              </span>
                            </Tooltip>
                            <div className={styles.dot} />
                          </div>
                        );
                      });
                    })}
                  </div>
                }
              >
                <div className={styles.target}>
                  {_.reduce(
                    row.area,
                    (sum, area) => {
                      return sum + _.size(area);
                    },
                    0
                  )}
                  <ArrowDownSmallIcon />
                </div>
              </Dropdown>
            );
          }
        },
        sortable: false,
        width: 60,
      },
      {
        key: 'target',
        overflow: true,
        name: __('table.columns.goto'),
        value: (x, row) => {
          if (_.isEmpty(row.plan_versions) && _.isEmpty(row.plan_revisions)) {
            return <TableNA />;
          } else {
            return (
              <Dropdown
                top={23}
                right={-5}
                name={'target.' + row.id}
                header={
                  <div className={styles.targetDropdownHeader}>
                    {__('table.columns.goto')}
                    <TargetIcon />
                  </div>
                }
                content={
                  <div className={styles.targetDropdownContent}>
                    {_.map(row.plan_versions, (version, i) => (
                      <div
                        className={styles.item}
                        key={i}
                        onClick={(e) => {
                          e.preventDefault();
                          redirect(
                            '/plans/versions/' +
                              version.id +
                              '?task_id=' +
                              row.id
                          );
                        }}
                      >
                        <Tooltip
                          text={version.name + (' (V' + version.number + ')')}
                        >
                          <span>
                            {version.name + (' (V' + version.number + ')')}
                          </span>
                        </Tooltip>
                        <div className={styles.dot} />
                      </div>
                    ))}
                    {_.map(row.plan_revisions, (revision, i) => (
                      <div
                        className={styles.item}
                        key={i}
                        onClick={(e) => {
                          e.preventDefault();
                          redirect(
                            '/plans/revisions/' +
                              revision.id +
                              '?task_id=' +
                              row.id
                          );
                        }}
                      >
                        <Tooltip
                          text={revision.name + (' (V' + revision.number + ')')}
                        >
                          <span>
                            {revision.name + (' (V' + revision.number + ')')}
                          </span>
                        </Tooltip>
                        <div className={styles.dot} />
                      </div>
                    ))}
                  </div>
                }
              >
                <div className={styles.target}>
                  {_.size(row.plan_versions) + _.size(row.plan_revisions)}
                  <ArrowDownSmallIcon />
                </div>
              </Dropdown>
            );
          }
        },
        // <>
        //     {target.type == 'PlanVersion' && (
        //         <div className={classNames(styles.target, this.props.table.clicked_row == row.id && styles.selectedRow)} onClick={() => redirect('/plans/versions/' + target.id + '?task_id=' + row.id)}>
        //             <Tooltip text={target.name}><PlanIcon /></Tooltip>
        //             {/*<span>{target.name}</span>
        //             <ArrowDoubleRightSmallIcon />*/}
        //         </div>
        //     )}
        //     {target.type == 'PlanRevision' && (
        //         <div className={classNames(styles.target, this.props.table.clicked_row == row.id && styles.selectedRow)} onClick={() => redirect('/plans/revisions/' + target.id + '?task_id=' + row.id)}>
        //             <Tooltip text={target.name}><PlanIcon /></Tooltip>
        //             {/*<span>{target.name}</span>
        //             <ArrowDoubleRightSmallIcon />*/}
        //         </div>
        //     )}
        // </>
        sortable: false,
        width: 60,
      },
      {
        key: 'assignee',
        name: __('table.columns.assignee'),
        value: (assignee) => (
          <div className={styles.assignee}>
            {_.size(assignee) == 0 && <TableNA />}
            {_.size(assignee) == 1 &&
              _.map(_.take(assignee, 1), (user, i) => (
                <Avatar key={i} {...user} />
              ))}
            {_.size(assignee) > 1 && (
              <Tooltip text={_.map(assignee, 'name').join('\n')}>
                <div className={styles.more}>
                  <span>{_.size(assignee)}</span>
                </div>
              </Tooltip>
            )}
          </div>
        ),
        sortable: false,
        width: 50,
        mobileVisible: true,
      },
      {
        key: 'comments',
        name: __('table.columns.comments'),
        width: 30,
        active: false,
        value: (comments, row) =>
          comments == 0 ? (
            0
          ) : (
            <div
              className={styles.goto}
              onClick={() => this._openCommentsTab(row.id)}
            >
              {comments}{' '}
              <Tooltip text={__('tasks.tooltip.icon.goto-comments')}>
                <ArrowDoubleRightSmallIcon />
              </Tooltip>
            </div>
          ),
      },
      {
        key: 'checklist_items',
        name: __('table.columns.checklist-items'),
        width: 60,
        active: false,
        sortable: false,
        value: (checklist_items) =>
          checklist_items.total == 0 ? (
            <TableNA />
          ) : (
            checklist_items.completed + ' / ' + checklist_items.total
          ),
      },
      {
        key: 'files',
        name: __('table.columns.files'),
        width: 30,
        active: false,
        value: (files, row) =>
          files == 0 ? (
            0
          ) : (
            <div
              className={styles.goto}
              onClick={() => this._openFilesTab(row.id)}
            >
              {files}{' '}
              <Tooltip text={__('tasks.tooltip.icon.goto-files')}>
                <ArrowDoubleRightSmallIcon />
              </Tooltip>
            </div>
          ),
      },
      {
        key: 'meetings',
        name: __('table.columns.meetings'),
        width: 30,
        active: false,
        value: (meetings, row) =>
          meetings == 0 ? (
            0
          ) : (
            <div
              className={styles.goto}
              onClick={() => this._openMeetingsTab(row.id)}
            >
              {meetings}{' '}
              <Tooltip text={__('tasks.tooltip.icon.goto-meetings')}>
                <ArrowDoubleRightSmallIcon />
              </Tooltip>
            </div>
          ),
      },
      {
        key: 'start_date',
        name: __('table.columns.task-start'),
        value: (value) => (value ? dateFrom(value) : <TableNA />),
        width: 80,
      },
      {
        key: 'end_date',
        name: __('table.columns.task-end'),
        value: (value, row) => {
          if (value) {
            return moment.utc().local().isAfter(moment.utc(value).local()) &&
              _.includes(['not-started', 'started'], row.status) ? (
              <span className={styles.overdue}>{dateFrom(value)}</span>
            ) : (
              dateFrom(value)
            );
          } else {
            return <TableNA />;
          }
        },
        width: 80,
      },
      {
        key: 'updated_at',
        name: __('table.columns.task-updated_at'),
        value: (value) => (value ? dateFrom(value) : <TableNA />),
        width: 80,
        active: false,
      },
      {
        key: 'created_at',
        name: __('table.columns.task-created_at'),
        value: (value) => (value ? dateFrom(value) : <TableNA />),
        width: 80,
        active: false,
      },
      {
        key: 'created_by',
        name: __('plans.deliveries.columns.created-by'),
        value: (user) => (
          <Avatar
            avatar_url={user.avatar_url}
            name={user.fullname}
            active={user.active}
          />
        ),
        width: 50,
      },
      {
        key: 'status',
        name: __('table.columns.task-status'),
        value: (status) => {
          switch (status) {
            case 'not-started':
              return (
                <Tooltip text={__('table.columns.task-status-notstarted')}>
                  <span
                    className={classNames(styles.balloon, styles.notStarted)}
                  >
                    {__('table.columns.task-status-notstarted-short')}
                  </span>
                </Tooltip>
              );
            case 'started':
              return (
                <Tooltip text={__('table.columns.task-status-started')}>
                  <span className={classNames(styles.balloon, styles.started)}>
                    {__('table.columns.task-status-started-short')}
                  </span>
                </Tooltip>
              );
            case 'finished':
              return (
                <Tooltip text={__('table.columns.task-status-finished')}>
                  <span className={classNames(styles.balloon, styles.finished)}>
                    {__('table.columns.task-status-finished-short')}
                  </span>
                </Tooltip>
              );
            case 'closed':
              return (
                <Tooltip text={__('table.columns.task-status-closed')}>
                  <span className={classNames(styles.balloon, styles.closed)}>
                    {__('table.columns.task-status-closed-short')}
                  </span>
                </Tooltip>
              );
            case 'reopened':
              return (
                <Tooltip text={__('table.columns.task-status-reopened')}>
                  <span className={classNames(styles.balloon, styles.reopened)}>
                    {__('table.columns.task-status-reopened-short')}
                  </span>
                </Tooltip>
              );
            case 'canceled':
              return (
                <Tooltip text={__('table.columns.task-status-canceled')}>
                  <span className={classNames(styles.balloon, styles.canceled)}>
                    {__('table.columns.task-status-canceled-short')}
                  </span>
                </Tooltip>
              );
          }
        },
        width: 50,
      },
      {
        key: 'finished_at',
        name: __('table.columns.task-finished_at'),
        value: (value) => (value ? dateFrom(value) : <TableNA />),
        width: 80,
        active: false,
      },
      {
        key: 'priority',
        name: __('table.columns.task-priority'),
        value: (priority) => {
          switch (priority) {
            case 'low':
              return (
                <Tooltip text={__('table.columns.task-priority-low')}>
                  <span className={classNames(styles.balloon, styles.low)}>
                    {__('table.columns.task-priority-low-short')}
                  </span>
                </Tooltip>
              );
            case 'normal':
              return (
                <Tooltip text={__('table.columns.task-priority-normal')}>
                  <span className={classNames(styles.balloon, styles.normal)}>
                    {__('table.columns.task-priority-normal-short')}
                  </span>
                </Tooltip>
              );
            case 'high':
              return (
                <Tooltip text={__('table.columns.task-priority-high')}>
                  <span className={classNames(styles.balloon, styles.high)}>
                    {__('table.columns.task-priority-high-short')}
                  </span>
                </Tooltip>
              );
            case 'urgent':
              return (
                <Tooltip text={__('table.columns.task-priority-urgent')}>
                  <span className={classNames(styles.balloon, styles.urgent)}>
                    {__('table.columns.task-priority-urgent-short')}
                  </span>
                </Tooltip>
              );
          }
        },
        width: 50,
      },
      {
        key: 'visibility',
        name: __('table.columns.task-visibility'),
        value: (visibility) =>
          visibility ? (
            <Tooltip text={__('table.columns.task-visibility-public')}>
              <span className={classNames(styles.balloon, styles.public)}>
                {__('table.columns.task-visibility-public-short')}
              </span>
            </Tooltip>
          ) : (
            <Tooltip text={__('table.columns.task-visibility-private')}>
              <span className={classNames(styles.balloon, styles.private)}>
                {__('table.columns.task-visibility-private-short')}
              </span>
            </Tooltip>
          ),
        width: 50,
      },
    ];

    const singleActions = (task_id) => {
      const is_editor = !_.isEmpty(this.state.team_roles.editor);

      let actions = [];

      if (is_editor) {
        actions.push(
          <Tooltip
            text={__('tasks.tooltip.icon.add-task-to-meeting')}
            key={_.uniqueId()}
          >
            <AddToMeetingIcon
              onClick={(e) => {
                e.stopPropagation();

                setTableParams('all_meetings', {
                  meeting_from_tasks: [task_id],
                });
              }}
            />
          </Tooltip>
        );
      }

      actions.push(
        <Tooltip text={__('tasks.tooltip.icon.export-tasks-pdf')}>
          <PrintIcon
            onClick={(e) => {
              e.stopPropagation();

              setTableParams('all_tasks', {
                print_tasks_report_wizard: [task_id],
              });
            }}
          />
        </Tooltip>
      );

      return actions;
    };

    const groupActions = (task_ids) => {
      const is_editor = !_.isEmpty(this.state.team_roles.editor);

      let actions = [];

      if (is_editor) {
        actions.push([
          <Tooltip
            text={__('tasks.tooltip.icon.add-tasks-to-meeting')}
            key={_.uniqueId()}
          >
            <AddToMeetingIcon
              onClick={(e) => {
                e.stopPropagation();

                setTableParams('all_meetings', {
                  meeting_from_tasks: task_ids,
                });
              }}
            />
          </Tooltip>,
        ]);
      }

      actions.push(
        <Tooltip text={__('Multiple edit task tags')} key={_.uniqueId()}>
          <TagIcon
            onClick={(e) => {
              e.stopPropagation();

              setTableParams('all_tasks', {
                wizard_multiple_task_tags_edit: task_ids,
              });
            }}
          />
        </Tooltip>
      );

      actions.push(
        <Tooltip
          text={__('tasks.tooltip.icon.export-tasks')}
          key={_.uniqueId()}
        >
          <ExportIcon
            onClick={(e) => {
              e.stopPropagation();

              setNotification('tasks.notification.task-export');

              downloadFile(backendURL + '/tasks/export', {
                tasks: task_ids,
              });
            }}
          />
        </Tooltip>
      );

      actions.push(
        <Tooltip
          text={__('tasks.tooltip.icon.export-tasks-pdf')}
          key={_.uniqueId()}
        >
          <PrintIcon
            onClick={(e) => {
              e.stopPropagation();

              setTableParams('all_tasks', {
                print_tasks_report_wizard: task_ids,
              });
            }}
          />
        </Tooltip>
      );

      return actions;
    };

    const filtersSort = (filter, value, label) => {
      switch (filter) {
        case 'status':
          return sortValuesByArray(
            [
              'not-started',
              'started',
              'reopened',
              'finished',
              'closed',
              'canceled',
            ],
            value
          );

        case 'priority':
          return sortValuesByArray(['low', 'normal', 'high', 'urgent'], value);

        case 'tag':
        case 'status':
          return value;

        case 'storey':
        case 'building':
        case 'room':
        case 'creator':
        case 'assignee':
        case 'meeting':
        case 'stage_discipline':
        case 'zone':
          return label === __('tasks.tasks-list.flyout.unassigned')
            ? -1
            : label;
      }
    };

    const preview = (
      <TasksFlyout
        data={this.props.table.flyout}
        readAction={(task_id) => {
          return new Promise(() => {
            readTask(getURLParam('id') || task_id);
          });
        }}
      />
    );

    return (
      <Table
        listAction={() => listAllTasks(this.props.match.params.stage)}
        name='all_tasks'
        title={__('Tasks')}
        columns={columns}
        singleActions={singleActions}
        groupActions={groupActions}
        filtersSort={filtersSort}
        preview={this.props.table.flyout ? preview : []}
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default AllTasks;
