import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/tasks/Files.scss';
import { withRouter } from 'react-router';
import {
  __,
  bytesToMegaBytes,
  bytesToSize,
  dateFrom,
  dateTimeFrom,
  mapStateToProps,
  scrollToBottom,
  timeFrom,
} from '../../core/utils';
import DownloadIcon from '../../assets/images/download-15x15.svg';
import * as _ from 'lodash';
import ReactDropzone from 'react-dropzone';
import classNames from 'classnames';
import UploadIcon from '../../assets/images/upload-40x40.svg';
import autobind from 'autobind-decorator';
import { setNotification } from '../../redux/actions/general/notification';
import {
  deleteCommentFile,
  deleteTaskFile,
  downloadTaskFiles,
  listAllTasks,
  uploadTaskFiles,
} from '../../redux/actions/table/all_tasks';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import FilePreview from '../../components/FilePreview';
import Avatar from '../../components/Avatar';

@withRouter
@mapStateToProps((state) => ({
  user_id: state.auth.id,
  clicked_row: _.get(state.table, ['all_tasks', 'clicked_row']),
  wizard_active: _.get(state.table, ['all_tasks', 'wizard_active']),
}))
class Files extends Component {
  constructor(props) {
    super(props);
  }

  @autobind
  _handleDrop(acceptedFiles, rejectedFiles) {
    this.setState({ drag: false });

    const { data } = this.props;

    if (!_.isEmpty(acceptedFiles)) {
      if (_.size(rejectedFiles) > 1) {
        setNotification({
          text: __('tasks.files.upload.bulk-not-allowed'),
          type: 'warning',
        });
      } else if (!_.isEmpty(rejectedFiles)) {
        setNotification({
          text: __('tasks.files.upload.incorrect.format'),
          type: 'warning',
        });
      } else if (
        _.find(acceptedFiles, (file) => {
          return file.size >= 100000000;
        })
      ) {
        setNotification({
          text: __('tasks.files.upload.upload-limit'),
          type: 'warning',
        });
      } else {
        setNotification(__('tasks.files.upload.uploading'));

        uploadTaskFiles(data.id, acceptedFiles)
          .then(() => {
            setNotification(__('tasks.files.upload.uploaded'));

            this.props.match.params.stage &&
              listAllTasks(this.props.match.params.stage);

            this.props.readAction(data.id).then(() => {
              scrollToBottom(this.refs.scroll);
            });
          })
          .catch(({ status }) => {
            setNotification({
              text: __('tasks.files.upload.upload-limit'),
              type: 'warning',
            });
          });
      }
    }
  }

  @autobind
  _downloadFile(row, download = false) {
    if (row.ext == 'pdf' && download == false) {
      window.open(row.stream_url, '_blank');
    } else {
      setNotification(__('tasks.files.download.continue'));

      window.open(row.download_url, '_blank');
    }
  }

  @autobind
  _downloadFiles() {
    const { data } = this.props;

    downloadTaskFiles(data.id);

    setNotification(__('tasks.files.zip_file_generation_in_progress'));
  }

  render() {
    const { data, collapsed, user_id } = this.props;
    const { is_editor, is_reviewer } = data;

    const comment_files = _.transform(
      data.comments,
      (files, comment) => {
        _.each(comment.files, (file) => {
          files.push({ ...file, source: 'comment', comment_id: comment.id });
        });
      },
      []
    );

    const files = _.sortBy([...data.files, ...comment_files], ['created_at']);

    const lightbox_images = _.map(
      _.filter(files, (file) =>
        _.includes(['jpg', 'jpeg', 'png'], _.toLower(file.ext))
      ),
      (file) => ({
        id: file.id,
        stream_url: file.stream_url,
        title: [file.name, file.ext].join('.'),
      })
    );

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
        ref="scroll"
      >
        {!_.isEmpty(files) && (
          <span
            className={styles.downloadAllTasks}
            onClick={() => this._downloadFiles()}
          >
            {__('meetings.flyout.files.download-all-tasks')}
            <DownloadIcon />
          </span>
        )}
        <div className={styles.scroller}>
          {/* {_.isEmpty(files) ? (
            <div className={styles.empty}>
              {__('meetings.flyout.files.there-are-no-files')}
            </div>
          ) : (
            ''
          )} */}

          {_.map(files, (row) => (
            <div className={styles.row} key={row.id}>
              <div className={styles.left}>
                <FilePreview
                  onDownloadFile={this._downloadFile}
                  row={row}
                  lightbox_images={lightbox_images}
                />
              </div>
              <div className={styles.right}>
                {bytesToSize(row.size)}

                <div className={styles.avatar}>
                  <Avatar
                    size={24}
                    {...row.creator}
                    avatar_url={row.creator.avatar_url}
                    className={styles.avatar}
                  />
                </div>

                <div className={styles.date_time}>
                  {dateFrom(row.created_at)}
                  <br />
                  {timeFrom(row.created_at)}
                </div>
                <div className={styles.icons}>
                  <DownloadIcon onClick={() => this._downloadFile(row, true)} />
                  {((is_reviewer && user_id == row.creator.id) || is_editor) &&
                    !_.includes(['closed', 'canceled'], data.status) && (
                      <DeleteIcon
                        onClick={() => {
                          showAlertbox({
                            color: 'red',
                            title: __('general.alert.are-you-sure'),
                            description: __(
                              'files.general.alert.want-delete-file-attachment-task.process-cannot-undone'
                            ),
                            buttons: [
                              {
                                color: 'lightGray',
                                text: __('general.alert.no-close'),
                                onClick: 'close',
                              },
                              {
                                color: 'gray',
                                text: __('general.alert.yes-delete'),
                                onClick: () => {
                                  const deleteAction =
                                    row.source == 'comment'
                                      ? deleteCommentFile(
                                          row.comment_id,
                                          row.id
                                        )
                                      : deleteTaskFile(data.id, row.id);

                                  deleteAction.then(() => {
                                    hideAlertbox();

                                    setNotification(
                                      __(
                                        'files.notification.selected-file-attachment-deleted-task'
                                      )
                                    );

                                    this.props.readAction(data.id).then(() => {
                                      scrollToBottom(this.refs.scroll);
                                    });
                                  });
                                },
                              },
                            ],
                          });
                        }}
                      />
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {(is_editor || is_reviewer) &&
          !_.includes(['closed', 'canceled'], data.status) && (
            <ReactDropzone
              className={classNames(
                styles.dropzone,
                _.get(this.state, 'drag') && styles.drag
              )}
              onDrop={this._handleDrop}
              onDragOver={() => this.setState({ drag: true })}
              onDragLeave={() => this.setState({ drag: false })}
              multiple={true}
            >
              <UploadIcon />
              <span className={styles.dropTitle}>
                {__('dropzone.title.drop-your-file-here')}
              </span>
              <span className={styles.dropSubtitle}>
                {__('dropzone.title.or-select')}
              </span>
            </ReactDropzone>
          )}
      </div>
    );
  }
}

export default Files;
