import * as React from 'react';
import styles from '../../styles/flyouts/specification_sets/ForeignEditors.scss';
import { __, setTableParams } from '../../core/utils';

import classNames from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';
import Dropdown from '../../components/Dropdown';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import MeetingStatusIcon from '../../assets/images/meeting-status-15x15.svg';
import { hideAlertbox } from '../../redux/actions/general/alertbox';
import { setNotification } from '../../redux/actions/general/notification';
import { updateforeign_editorDecisionStatus } from '../../redux/actions/table/specification_sets';
import { hideDropdown } from '../../redux/actions/general/dropdown';

const ForeignEditors = (props) => {
  const {
    data: { foreign_editors, status, administrators, is_foreign_editor, id },
    collapsed,
    user_id,
    readAction,
  } = props;

  const is_administrator = _.isObject(_.find(administrators, ['id', user_id]));

  const disabled =
    !is_administrator || _.includes(['closed', 'canceled'], status);

  const statuses = [
    {
      value: 'received',
      label: __('specification_sets.foreign_editor.status.received'),
    },
    {
      value: 'in-progress',
      label: __('specification_sets.foreign_editor.status.in-progress'),
    },
    {
      value: 'submitted',
      label: __('specification_sets.foreign_editor.status.submitted'),
    },
    {
      value: 'withdrawn',
      label: __('specification_sets.foreign_editor.status.withdrawn'),
    },
    {
      value: 'expired',
      label: __('specification_sets.foreign_editor.status.expired'),
    },
  ];

  // const decision_statuses = [
  //   {
  //     value: 'not_selected',
  //     label: __('specification_sets.foreign_editor.decision_status.not_selected'),
  //     color: '#CCCDD1',
  //   },
  //   {
  //     value: 'awarded',
  //     label: __('specification_sets.foreign_editor.decision_status.awarded'),
  //     color: '#5777FC',
  //   },
  //   {
  //     value: 'shortlisted',
  //     label: __('specification_sets.foreign_editor.decision_status.shortlisted'),
  //     color: '#75FCFD',
  //   },
  //   {
  //     value: 'disqualified',
  //     label: __('specification_sets.foreign_editor.decision_status.disqualified'),
  //     color: '#E62A10',
  //   },
  //   {
  //     value: 'withdrawn',
  //     label: __('specification_sets.foreign_editor.decision_status.withdrawn'),
  //     color: '#F19E38',
  //     disabled: true,
  //   },
  //   {
  //     value: 'expired',
  //     label: __('specification_sets.foreign_editor.decision_status.expired'),
  //     color: '#FEFE54',
  //     disabled: true,
  //   },
  // ];

  // const _handleDecisionStatusChange = (foreign_editor_id, decision_status) => {
  //   updateforeignEditorDecisionStatus(id, foreign_editor_id), { decision_status }).then(() => {
  //     if (decision_status === 'awarded') {
  //       const foreignEditorWithAwardedStatus = _.find(
  //         foreign_editors,
  //         (foreign_editor) => foreign_editor.decision_status === 'awarded'
  //       );

  //       if (foreignEditorWithAwardedStatus) {
  //         return updateforeignEditorDecisionStatus(id, foreignEditorWithAwardedStatus.id, {
  //           decision_status: 'not_selected',
  //         }).then(() => {
  //           readAction();
  //         });
  //       }
  //     }

  //     return readAction();
  //   });

  //   hideDropdown();

  //   setNotification(__('specification_sets.properties.saved'));
  // };

  return (
    <div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
      {_.isEmpty(foreign_editors) ? (
        <div className={styles.empty}>
          {__('specification_sets.flyout.no-foreign_editors')}
        </div>
      ) : (
        ''
      )}
      {!_.isEmpty(foreign_editors) && (
        <>
          <div className={styles.header}>
            <span className={styles.company}>
              {__('specification_sets.flyout.foreign_editor-company')}
            </span>
            <span className={styles.name}>
              {__('specification_sets.flyout.foreign_editor-name')}
            </span>
            <span className={styles.email}>
              {__('specification_sets.flyout.foreign_editor-email')}
            </span>
            <span className={styles.status}>
              {__('specification_sets.flyout.foreign_editor-status')}
            </span>
          </div>
          {_.map(
            _.groupBy(foreign_editors, 'company'),
            (group_items, group) => {
              return (
                <div className={styles.groupRow} key={'group_' + group}>
                  {_.map(group_items, (foreign_editor, i) => (
                    <div className={styles.row} key={i}>
                      <span className={styles.company}>
                        {foreign_editor.company}
                      </span>
                      <span className={styles.name}>{foreign_editor.name}</span>
                      <span className={styles.email}>
                        {foreign_editor.email}
                      </span>

                      <span className={styles.status}>
                        {
                          _.find(
                            statuses,
                            (status) => status.value == foreign_editor.status
                          )?.label
                        }
                      </span>
                    </div>
                  ))}
                </div>
              );
            }
          )}
        </>
      )}

      {!disabled && (
        <div className={styles.addRow}>
          <button
            onClick={() =>
              setTableParams('specification_sets', {
                add_foreign_editors_wizard_active: true,
              })
            }
          >
            {__('specification_set.foreign_editors.add_foreign_editor')}
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user_id: state.auth.id,
  };
};

export default connect(mapStateToProps, null)(ForeignEditors);
