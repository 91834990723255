import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/AddTimesheetWizard.scss';
import * as _ from 'lodash';
import AddTimesheetForm from '../forms/add_timesheet_wizard/AddTimesheetForm';
import AddTaskForm from '../forms/add_task_wizard/AddTaskForm';
import { withRouter } from 'react-router';
import {
  __,
  getActiveProject,
  getActiveStage,
  setFormErrors,
  setTableParams,
  validateForm,
  convertHoursAndMinutesToMinutes,
} from '../core/utils';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import { connect } from 'react-redux';
import AddTimesheetIcon from '../assets/images/16pix AddTime.svg';
import {
  createTimesheet,
  listTimesheets,
} from '../redux/actions/table/timesheets';
import { setNotification } from '../redux/actions/general/notification';

const AddTimesheetWizard = function (props) {
  const _handleSubmit = (formData) => {
    formData = {
      ...formData,
      user_id: props.auth.id,
      minutes: convertHoursAndMinutesToMinutes(
        formData?.hours,
        formData?.minutes
      ),
    };

    return validateForm(
      createTimesheet(formData).then(({ response }) => {
        listTimesheets().then(() => {
          // if (edit) {
          //   setTableParams('all_tasks', {
          //     clicked_row: response.data.id,
          //   });
          // }
        });
        setNotification(__('wizard.add-timesheet.confirm'));
        props.onClose();
      })
      // ).catch(({ errors }) => {
      //   if (_.has(errors, 'minutes')) {
      //     return setFormErrors('add_timesheet_wizard.add_timesheet', {
      //       hours: ['Hours is required.'],
      //       ...errors,
      //     });
      //   }

      //   return setFormErrors('add_timesheet_wizard.add_timesheet', errors);
      // });
    );
  };

  const active_project = getActiveProject();
  const active_stage = getActiveStage();

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>{__('wizard.add-timesheet.title.create-new')}</span>
          <span>{__('wizard.add-timesheet.subtitle.create-new')}</span>
        </div>
        <div className={styles.right}>
          <AddTimesheetIcon
            style={{ fill: 'white !important', color: 'white !important' }}
          />
        </div>
      </div>
      <div className={styles.body}>
        <AddTimesheetForm
          onSubmit={_handleSubmit}
          initialValues={{
            project_id: active_project,
            stage_id: active_stage,
          }}
        />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    table: state.table['timesheets'],
    auth: state.auth,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(subscribe(Localization, 'localization')(AddTimesheetWizard));
