import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/working_set_specifications/Files.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import autobind from 'autobind-decorator';
import { __, mapStateToProps, setTableParams } from '../../core/utils';
import CloseMediumIcon from '../../assets/images/close-middle-15x15.svg';
import { setNotification } from '../../redux/actions/general/notification';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import {
  deleteSpecificationFile,
  readWorkingSetSpecification,
  uploadSpecificationFiles,
} from '../../redux/actions/table/working_set_specifications';
import FilePreview from '../../components/FilePreview';
import ReactDropzone from 'react-dropzone';
import UploadIcon from '../../assets/images/upload-40x40.svg';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import { Form, getFormValues, reduxForm } from 'redux-form';
import Select from '../../components/Select';
import Images from './Images';
import EditIcon from '../../assets/images/edit-16x16.svg';

@reduxForm({
  form: 'working_set_specifications.files',
  enableReinitialize: true,
})
@mapStateToProps((state, props) => ({
  store: state.table.products,
  values: getFormValues('working_set_specifications.files')(state),
  flyout: state.table.working_set_specifications.flyout,
}))
class Files extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
      files: [],
      active_category: null,
      category_errors: null,
    };
  }

  @autobind
  _toggleCollapseCategory(category) {
    this.setState({
      expanded: _.xor(this.state.expanded, [category]),
    });
  }

  componentDidMount() {
    const { files, images } = this.props.flyout;

    this.setState({
      expanded: _.map([...files, ...images], (file) => file.category),
    });

    window.addEventListener('paste', this._handlePaste);
  }

  componentWillUnmount() {
    window.removeEventListener('paste', this._handlePaste);
  }

  @autobind
  _handlePaste(event) {
    const files = event.clipboardData.files;

    this.setState({
      files: [
        ...this.state.files,
        ..._.map(files, (file) => {
          return {
            file: Object.assign(file, {
              stream_url: URL.createObjectURL(file),
              ext: file.name.split('.').pop(),
            }),
            category: '',
          };
        }),
      ],
    });

    this.props.change('files', files);
  }

  @autobind
  _uploadFiles(category, files) {
    const specification_id = this.props.flyout.id;

    setNotification(
      __('working-set-specifications.files.please-wait-files-uploaded')
    );

    uploadSpecificationFiles(specification_id, this.state.files)
      .then(() => {
        this.props._refetchData().then(({ response }) => {
          setTableParams('working_set_specifications', {
            flyout: response.data,
          });

          const { files, images } = response.data;

          this.setState({
            expanded: _.map([...files, ...images], (file) => file.category),
            files: [],
            category_errors: null,
            active_category: null,
          });
        });
      })
      .catch((error) => {
        this.setState({
          category_errors: _.map(Object.keys(error.response.errors), (key) =>
            _.toNumber(_.first(key.match(/\d+/)))
          ),
        });

        setNotification({
          text: __(
            'working-set-specifications.files.your-files-not-uploaded.duble-check-file-formats-sizes'
          ),
          type: 'warning',
        });
      });
  }

  @autobind
  _downloadFile({ download_url }) {
    setNotification(__('working-set-specifications.files.download.continue'));

    window.open(download_url, '_blank');
  }

  @autobind
  _removeFile(category, attachment_id) {
    const specification_id = this.props.flyout.id;

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __(
        'working-set-specifications.files.are-you-sure-to-remove-this-file'
      ),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-delete'),
          onClick: () =>
            deleteSpecificationFile(
              specification_id,
              category,
              attachment_id
            ).then(() => {
              hideAlertbox();

              this.props._refetchData().then(({ response }) => {
                setTableParams('working_set_specifications', {
                  flyout: response.data,
                });
              });

              setNotification(
                __('working-set-specifications.files.selected-file-deleted')
              );
            }),
        },
      ],
    });
  }

  @autobind
  _handleDrop(acceptedFiles, rejectedFiles) {
    this.setState({ drag: false });

    if (!_.isEmpty(acceptedFiles)) {
      if (_.size(rejectedFiles) > 1) {
        setNotification({
          text: __('specifications.files.upload.bulk-not-allowed'),
          type: 'warning',
        });
      } else if (!_.isEmpty(rejectedFiles)) {
        setNotification({
          text: __('specifications.files.upload.incorrect.format'),
          type: 'warning',
        });
      } else if (
        _.find(acceptedFiles, (file) => {
          return file.size >= 312857600;
        })
      ) {
        setNotification({
          text: __('specifications.files.upload.upload-limit'),
          type: 'warning',
        });
      } else {
        this.setState({
          files: [
            ...this.state.files,
            ..._.map(acceptedFiles, (file) => {
              return {
                file: Object.assign(file, {
                  stream_url: URL.createObjectURL(file),
                  ext: file.name.split('.').pop(),
                }),
                category: '',
              };
            }),
          ],
        });

        this.props.change('files', acceptedFiles);
      }
    }
  }

  render() {
    const { collapsed, flyout, readOnly } = this.props;
    const { expanded } = this.state;

    const categories = [
      {
        label: __('working-set-specifications.images.thumbnail'),
        value: 'thumbnail',
        category: 'images',
      },
      {
        label: __('working-set-specifications.product'),
        value: 'product',
        category: 'images',
      },
      {
        label: __('working-set-specifications.drawing'),
        value: 'drawing',
        category: 'images',
      },
      {
        label: __('working-set-specifications.ambient'),
        value: 'ambient',
        category: 'images',
      },
      {
        label: __('working-set-specifications.technical'),
        value: 'technical',
        category: 'images',
      },
      {
        label: __('working-set-specifications.tileable-texture-for-3d'),
        value: 'tileable',
        category: 'images',
      },
      {
        label: __('working-set-specifications.files.manuals'),
        value: 'manuals',
        category: 'files',
      },
      {
        label: __('working-set-specifications.files.guarantee'),
        value: 'guarantee',
        category: 'files',
      },
      {
        label: __('working-set-specifications.files.certificates'),
        value: 'certificates',
        category: 'files',
      },
      {
        label: __('working-set-specifications.files.catalogs'),
        value: 'catalogs',
        category: 'files',
      },
      {
        label: __('working-set-specifications.files.installation'),
        value: 'installation',
        category: 'files',
      },
      {
        label: __('working-set-specifications.files.bim'),
        value: 'bim',
        category: 'files',
      },
      {
        label: __('working-set-specifications.files.3d'),
        value: '3d',
        category: 'files',
      },
      {
        label: __('working-set-specifications.files.cad'),
        value: 'cad',
        category: 'files',
      },
      {
        label: __('working-set-specifications.files.2d'),
        value: '2d',
        category: 'files',
      },
      {
        label: __('working-set-specifications.files.light-data'),
        value: 'light_data',
        category: 'files',
      },
      {
        label: __('working-set-specifications.files.video'),
        value: 'video',
        category: 'files',
      },
      {
        label: __('working-set-specifications.files.audio'),
        value: 'audio',
        category: 'files',
      },
      {
        label: __('working-set-specifications.files.other'),
        value: 'other',
        category: 'files',
      },
    ];

    const { files, category_errors, active_category } = this.state;

    const lightbox_files = _.map(
      _.filter(files, (row) =>
        _.includes(['jpg', 'jpeg', 'png'], _.toLower(row.file.ext))
      ),
      (row) => ({
        id: row.file.id,
        stream_url: row.file.stream_url,
        title: [row.file.name, row.file.ext].join('.'),
      })
    );

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        {/* Files list */}
        {_.map(categories, (category) => {
          const category_files = _.sortBy(
            _.filter(
              [...flyout.files, ...flyout.images],
              ['category', category.value]
            ),
            ['order']
          );

          const lightbox_images = _.map(
            _.filter(category_files, (file) =>
              _.includes(['jpg', 'jpeg', 'png'], _.toLower(file.ext))
            ),
            (file) => ({
              id: file.id,
              stream_url: file.stream_url,
              title: [file.name, file.ext].join('.'),
            })
          );

          return (
            <>
              {!_.isEmpty(category_files) && (
                <div className={styles.category} key={category.value}>
                  <div
                    className={styles.title}
                    onClick={() => this._toggleCollapseCategory(category.value)}
                  >
                    <ArrowDownMiddleIcon
                      className={classNames(
                        styles.collapse,
                        !_.includes(expanded, category.value) &&
                          styles.collapsed
                      )}
                    />
                    {category.label}{' '}
                    {!_.isEmpty(category_files) &&
                      '(' + _.size(category_files) + ')'}
                  </div>
                  {_.includes(expanded, category.value) &&
                    (_.includes(
                      [
                        'thumbnail',
                        'product',
                        'drawing',
                        'ambient',
                        'technical',
                        'tileable',
                      ],
                      category.value
                    ) ? (
                      <Images
                        data={category_files}
                        readOnly={readOnly}
                        category={category.value}
                        handleDeleteSpecificationFile={(attachment_id) =>
                          this._removeFile(category.value, attachment_id)
                        }
                      />
                    ) : (
                      !_.isEmpty(category_files) && (
                        <div className={styles.files}>
                          {_.map(category_files, (file, i) => (
                            <div className={styles.file} key={i}>
                              <FilePreview
                                onDownloadFile={this._downloadFile}
                                row={file}
                                lightbox_images={lightbox_images}
                              />

                              {(_.isNull(flyout.product_id) || !readOnly) && (
                                <div className={styles.icons}>
                                  <div className={styles.edit}>
                                    <EditIcon
                                      onClick={() =>
                                        setTableParams(
                                          'working_set_specifications',
                                          {
                                            edit_specification_file_category:
                                              category.value,
                                            attachment_id: file.id,
                                            file_ext: file.ext,
                                          }
                                        )
                                      }
                                    />
                                  </div>
                                  <div className={styles.remove}>
                                    <CloseMediumIcon
                                      onClick={() =>
                                        this._removeFile(
                                          category.value,
                                          file.id
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )
                    ))}
                </div>
              )}
            </>
          );
        })}

        {/* Dropzone - upload files */}
        {!readOnly && (
          <ReactDropzone
            className={classNames(
              styles.dropzone,
              _.get(this.state, 'drag') && styles.drag
            )}
            onDrop={this._handleDrop}
            onDragOver={() => this.setState({ drag: true })}
            onDragLeave={() => this.setState({ drag: false })}
            multiple={true}
          >
            <UploadIcon />
            <span className={styles.dropTitle}>
              {__('dropzone.title.drop-your-file-here')}
            </span>
            <span className={styles.dropSubtitle}>
              {__('dropzone.title.or-select')}
            </span>
          </ReactDropzone>
        )}

        {/* Clickboard files */}
        {!_.isEmpty(files) && (
          <Form className={styles.clickboardFiles} onSubmit={this.handleSubmit}>
            {_.map(files, (row, i) => {
              const key = _.toNumber(i);
              return (
                <div className={styles.fileRow} key={key}>
                  <div className={styles.left}>
                    <FilePreview
                      onDownloadFile={this._downloadFile}
                      row={row.file}
                      lightbox_images={lightbox_files}
                    />
                  </div>

                  <div className={styles.right}>
                    <div className={styles.select}>
                      <Select
                        disabled={readOnly}
                        placeholder={__(
                          'specifications.working-set.flyout.select-file-category'
                        )}
                        // label={__(
                        //   'specifications.working-set.flyout.file-category'
                        // )}
                        input={{
                          value:
                            this.state.files && this.state.files[i]?.category
                              ? this.state.files[i].category
                              : '',
                          onChange: (value) => {
                            this.setState({
                              files: {
                                ...this.state.files,
                                [key]: {
                                  ...this.state.files[key],
                                  category: value,
                                },
                              },

                              category_errors: _.filter(
                                category_errors,
                                (error) => error != key
                              ),
                            });
                          },
                          onBlur: () =>
                            this.setState({
                              active_category: { [key]: false },
                            }),
                          onFocus: () =>
                            this.setState({ active_category: { [key]: true } }),
                        }}
                        meta={{
                          active: active_category && active_category[key],
                          error: _.includes(category_errors, key),
                          touched: _.includes(category_errors, key),
                        }}
                        options={
                          !_.includes(
                            ['jpg', 'jpeg', 'png'],
                            _.toLower(row.file.ext)
                          )
                            ? _.filter(
                                categories,
                                (category) =>
                                  !_.includes(
                                    [
                                      'thumbnail',
                                      'product',
                                      'drawing',
                                      'ambient',
                                      'technical',
                                      'tileable',
                                    ],
                                    category.value
                                  )
                              )
                            : categories
                        }
                        top={28}
                        noSort
                        searchable
                      />
                    </div>
                    {!readOnly && (
                      <div className={styles.icons}>
                        <DeleteIcon
                          onClick={() => {
                            this.setState(
                              {
                                files: _.filter(files, (file) => file != row),
                              },
                              () => {
                                this.props.change('files', this.state.files);
                              }
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            {!readOnly && (
              <div className={styles.addRow}>
                <button onClick={this._uploadFiles} type='button'>
                  {__('specifications.working-set.flyout.add-files')}
                </button>
              </div>
            )}
          </Form>
        )}
      </div>
    );
  }
}

export default Files;
