import * as React from 'react';
import { Component, createRef } from 'react';
import {
  createSidebar,
  hideSidebar,
} from '../../redux/actions/general/sidebar';
import {
  __,
  formatMoney,
  getActiveStage,
  getURLParam,
  mapStateToProps,
  redirect,
  setTableParams,
} from '../../core/utils';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import * as _ from 'lodash';
import { getStagesLanguages } from '../../redux/actions/table/stages';
import { withRouter } from 'react-router';
import {
  addBOQCalculation,
  addBOQGroup,
  addBOQItem,
  addBOQPosition,
  addBOQSubgroup,
  getBOQCurrency,
  getBOQArea,
  readWorkingSetBOQ,
  getBOQItem,
  getBOQCalculation,
  filterWorkingSetBOQ,
  reorderBOQGroups,
  reorderBOQSubgroups,
  reorderBOQItems,
  reorderBOQCalculations,
  reorderBOQPositions,
  listPossibleBOQGroups,
  listPossibleBOQSubgroups,
  addBOQTemplateGroup,
  addBOQTemplateSubgroup,
} from '../../redux/actions/table/working_set_boq';
import styles from '../../styles/views/boq/WorkingSetBOQ.scss';
import classNames from 'classnames';
import Tooltip from '../../components/Tooltip';
import autobind from 'autobind-decorator';
import TableCompactView from './helpers/TableCompactView';
import TableDetailedView from './helpers/TableDetailedView';
import { readWorkingSetSpecification } from '../../redux/actions/table/working_set_specifications';
import WorkingSetSpecificationsFlyout from '../../flyouts/working_set_specifications/WorkingSetSpecificationsFlyout';
import { getPreferences, updatePreferences } from '../../redux/actions/profile';
import AddToTenderIcon from '../../assets/images/add-to-tender-16x16.svg';
import SaveAsTemplateIcon from '../../assets/images/boq-save-as-template-16x16.svg';
import BOQFlyout from '../../flyouts/boq/BOQFlyout';
import BOQItemFlyout from '../../flyouts/boq/BOQItemFlyout';
import BOQCalculationFlyout from '../../flyouts/boq/BOQCalculationFlyout';
import Dropdown from '../../components/Dropdown';
import { hideDropdown } from '../../redux/actions/general/dropdown';
import { setPlusActions } from '../../redux/actions/general/plus';
import ExportIcon from '../../assets/images/export-15x15.svg';
import { setNotification } from '../../redux/actions/general/notification';
import { listBOQTemplateGroups } from '../../redux/actions/table/superadmin/boq_template_groups';
import { listBOQTemplateSubgroups } from '../../redux/actions/table/superadmin/boq_template_subgroups';
import queryString from 'query-string';

@mapStateToProps((state) => ({
  store: state.table['working_set_boq'],
  auth: state.auth,
  specification_clicked_row: _.get(state.table, [
    'working_set_specifications',
    'clicked_row',
  ]),
  clicked_row: _.get(state.table, ['working_set_boq', 'clicked_row']),
  dropdown: state.general.dropdown.shown,
  sidebar: state.general.sidebar,
}))
@withRouter
class WorkingSetBOQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused_id: null,
      space_allocation: {},
      expanded_rows: [],
      language_switcher: false,
      possible_boq_groups: [],
      possible_boq_subgroups: [],
    };

    this.tableRef = createRef();
    this.nameRefs = [];

    this.savePreferencesTimeout = null;
  }

  @autobind
  _refreshWorkingSetBOQ(list_active_modules = false) {
    return new Promise((resolve, reject) => {
      readWorkingSetBOQ(this.props.match.params.stage).then(() => {
        resolve();

        list_active_modules && listActiveModules();
      });
    });
  }

  @autobind
  _filterWorkingSetBOQ() {
    filterWorkingSetBOQ();
  }

  componentDidMount() {
    enableProjectsDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/boq/working?filter.subgroup=')
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/boq/working?filter.subgroup=')
    );

    getStagesLanguages(this.props.match.params.stage).then(({ response }) => {
      setTableParams('working_set_boq', {
        project_languages: response.data,
        language_id: _.findKey(response.data, (primary) => primary == true),
      });
    });

    this._refreshWorkingSetBOQ(true);

    getBOQArea(this.props.match.params.stage).then(({ response }) => {
      this.setState({ space_allocation: response.data });
    });

    getBOQCurrency(this.props.match.params.stage).then(({ response }) => {
      setTableParams('working_set_boq', {
        project_currency: _.get(this.props.localization.currencies, [
          response.data,
          'code',
        ]),
      });
    });

    createSidebar({
      title: __('submenu.title.bill-of-quantities'),
      items: [
        {
          title: __('submenu.title.working-set-bill-of-quantities'),
          link: () =>
            '/stages/' + getActiveStage() + '/boq/working?filter.subgroup=',
          active: true,
        },

        {
          title: __('submenu.title.import_spreadsheet'),
          link: () => '/stages/' + getActiveStage() + '/boq/import',
        },
      ],
    });

    const { project_languages } = this.props.store;

    setTableParams('working_set_boq', {
      language_id: _.findKey(project_languages, (primary) => primary == true),
    });

    if (
      getURLParam('boq_item_id') ||
      getURLParam('boq_calculation_id') ||
      getURLParam('specification_id')
    ) {
      const id =
        getURLParam('boq_item_id') ||
        getURLParam('boq_calculation_id') ||
        getURLParam('specification_id');

      if (getURLParam('specification_id'))
        readWorkingSetSpecification(getURLParam('specification_id')).then(
          ({ response }) => {
            setTableParams('working_set_specifications', {
              flyout: response.data,
              clicked_row: response.data.id,
            });
          }
        );
      else
        setTableParams('working_set_boq', {
          clicked_row: id,
        });
    }

    getPreferences(`table.working_set_boq`).then(({ response: { data } }) => {
      const user_preference_params = _.transform(
        data,
        (params, value, key) => {
          if (_.startsWith(key, 'filter_')) {
            params[key.replace('filter_', 'filter.')] = _.toString(value);
          } else {
            params[key] = value;
          }
        },
        {}
      );

      const default_values = {
        ...user_preference_params,
      };

      setTableParams(
        'working_set_boq',
        _.pick(default_values, ['disabled_columns', 'pinned_columns', 'view'])
      );

      const url_parameters = queryString.parse(
        this.props.history.location.search
      );

      if (
        _.isEmpty(url_parameters) ||
        (_.size(url_parameters) == 1 &&
          (_.has(url_parameters, 'id') ||
            !_.isUndefined(
              _.find(url_parameters, (value, key) => _.includes(key, 'filter.'))
            )))
      ) {
        const new_params = _.pickBy(default_values, (value, key) => {
          return _.includes(['query'], key) || _.startsWith(key, 'filter.');
        });

        this._setURLParams({
          ...new_params,
          ...url_parameters,
        });
      } else {
        this._mapUrlParamsToProps();
      }

      const params = queryString.parse(this.props.location.search);

      if (params?.query) {
        setTableParams('working_set_boq', {
          search_active: true,
        });
      }
    });

    getPreferences(`table.working_set_boq`).then(({ response: { data } }) => {
      const view = _.get(data, 'view', 'compact');

      setTableParams('working_set_boq', { view });

      const expanded_rows = _.get(data, 'expanded_rows', []);

      this.tableRef.setExpandedRowKeys(expanded_rows);
      this.setState({ expanded_rows });
    });

    listPossibleBOQGroups().then(({ response }) => {
      this.setState({ possible_boq_groups: response.data });
    });

    listPossibleBOQSubgroups().then(({ response }) => {
      this.setState({ possible_boq_subgroups: response.data });
    });

    hideSidebar();
  }

  @autobind
  _mapUrlParamsToProps() {
    const params = queryString.parse(this.props.location.search);

    setTableParams('working_set_boq', _.pick(params, ['query']));

    _.each(params, (value, key) => {
      //TODO - try to merge this with the upper keys using pickBy()
      if (_.startsWith(key, 'filter.')) {
        const params = {};
        params[key] = value;

        setTableParams('working_set_boq', params);
      }
    });

    this._setURLParams(params);

    refreshData();
  }

  @autobind
  _setURLParams(params) {
    const search = queryString.stringify({
      ...queryString.parse(props.history.location.search),
      ...params,
    });
    redirect({ search }, true);
  }

  @autobind
  _expandToId(id) {
    //cast id to string
    const string_id = _.toString(id);

    //get current expanded rows
    const expanded_rows = _.cloneDeep(this.tableRef.getExpandedRowKeys());

    //get splitted levels
    const splitted = _.split(string_id, '-', 5);

    //return new array including levels to ID
    const final_expanded = _.uniq(
      _.transform(
        splitted,
        (expanded, id, index) => {
          const formed_id = _.slice(splitted, 0, index).join('-');

          !_.isEmpty(formed_id) && expanded.push(formed_id);
        },
        expanded_rows
      )
    );

    this.setState({ expanded_rows: final_expanded });

    //set new array of expanded rows
    this.tableRef.setExpandedRowKeys(final_expanded);
  }

  @autobind
  _scrollAndFocus(id) {
    //cast id to string
    const string_id = _.toString(id);

    //find index in the virtualized table
    const index = _.findIndex(
      this.tableRef._data,
      (row) => row.id === string_id
    );

    //scroll to index in the table
    this.tableRef.scrollToRow(index);

    //set focused row (left-blue border)
    this.setState({ focused_id: string_id });

    //focus the field and highlight the text
    this.nameRefs[string_id].focus();
    this.nameRefs[string_id].select();
  }

  @autobind
  _handleSortEnd(id, level) {
    let all_rows = [...this.tableRef._data];

    let newIndex;
    let collection;

    if (level != _.size(this.state.focused_id.split('-')) - 1) {
      newIndex = 0;
      collection = this.state.focused_id;
    } else {
      newIndex =
        _.findIndex(all_rows, (row) => row.id == this.state.focused_id) + 1;
      collection = this.props.store.rowData?.parentId;
    }

    all_rows.splice(newIndex, 0, {
      id: _.toString(id),
      parentId: collection,
    });

    all_rows = _.map(
      _.filter(all_rows, (row) => row.parentId == collection),
      (row) => _.last(row.id.split('-'))
    );

    const reorder = {
      0: reorderBOQGroups,
      1: reorderBOQSubgroups,
      2: reorderBOQItems,
      3: reorderBOQCalculations,
      4: reorderBOQPositions,
    };

    return reorder[level](
      collection == undefined
        ? getActiveStage()
        : _.last(collection.split('-')),
      all_rows
    );
  }

  @autobind
  _addGroup(reorderGroups = false) {
    addBOQGroup(this.props.match.params.stage).then(({ response }) => {
      const refresh = () => {
        this._refreshWorkingSetBOQ().then(() => {
          this._expandToId(response.data);
          this._scrollAndFocus(response.data);

          hideDropdown();
        });
      };

      if (reorderGroups) {
        return this._handleSortEnd(response.data, 0).then(() => {
          refresh();
        });
      }

      refresh();

      setNotification(__('working-set-boq.boq-group-saved'));
    });
  }

  @autobind
  _addSubgroup(reorderSubgroups = false) {
    const focused_group_id = _.get(_.split(this.state.focused_id, '-', 5), '0');

    focused_group_id &&
      addBOQSubgroup(focused_group_id).then(({ response }) => {
        const refresh = () => {
          this._refreshWorkingSetBOQ().then(() => {
            this._expandToId(response.data);
            this._scrollAndFocus(response.data);

            hideDropdown();
          });
        };

        if (reorderSubgroups) {
          return this._handleSortEnd(response.data, 1).then(() => {
            refresh();
          });
        }

        refresh();

        setNotification(__('working-set-boq.boq-subgroup-saved'));
      });
  }

  @autobind
  _addItem(reorderItems = false) {
    const focused_subgroup_id = _.get(
      _.split(this.state.focused_id, '-', 5),
      '1'
    );

    focused_subgroup_id &&
      addBOQItem(focused_subgroup_id).then(({ response }) => {
        const refresh = () => {
          this._refreshWorkingSetBOQ().then(() => {
            this._expandToId(response.data);
            this._scrollAndFocus(response.data);

            hideDropdown();
          });
        };

        if (reorderItems) {
          return this._handleSortEnd(response.data, 2).then(() => {
            refresh();
          });
        }

        refresh();

        setNotification(__('working-set-boq.boq-item-saved'));
      });
  }

  @autobind
  _addCalculation(reorderCalculations = false) {
    const focused_item_id = _.get(_.split(this.state.focused_id, '-', 5), '2');

    focused_item_id &&
      addBOQCalculation({
        boq_item_id: focused_item_id,
        stage_id: getActiveStage(),
      }).then(({ response }) => {
        const refresh = () => {
          this._refreshWorkingSetBOQ().then(() => {
            this._expandToId(response.data);
            this._scrollAndFocus(response.data);

            hideDropdown();
          });
        };

        if (reorderCalculations) {
          return this._handleSortEnd(response.data, 3).then(() => {
            refresh();
          });
        }

        refresh();

        setNotification(__('working-set-boq.boq-calculation-saved'));
      });
  }

  @autobind
  _addPosition(reorderPositions = false) {
    const focused_calculation_id = _.get(
      _.split(this.state.focused_id, '-', 5),
      '3'
    );

    focused_calculation_id &&
      addBOQPosition(focused_calculation_id).then(({ response }) => {
        const refresh = () => {
          this._refreshWorkingSetBOQ().then(() => {
            this._expandToId(response.data);
            this._scrollAndFocus(response.data);

            hideDropdown();
          });
        };

        if (reorderPositions) {
          return this._handleSortEnd(response.data, 4).then(() => {
            refresh();
          });
        }

        refresh();

        setNotification(__('working-set-boq.boq-position-saved'));
      });
  }

  @autobind
  _addCalculationsFromTemplate() {
    const focused_item_id = _.get(_.split(this.state.focused_id, '-', 5), '2');

    focused_item_id &&
      setTableParams('working_set_boq', {
        attach_templates_to_boq_wizard: focused_item_id,
        level: 2,
      });
  }

  @autobind
  _saveTablePreferences() {
    if (this.savePreferencesTimeout) clearTimeout(this.savePreferencesTimeout);

    this.savePreferencesTimeout = setTimeout(() => {
      updatePreferences(`table.working_set_boq`, {
        expanded_rows: this.state.expanded_rows,
      });
    }, 1000);
  }

  @autobind
  groupActions(selected_rows) {
    const actions = [];

    actions.push([
      <Tooltip
        text={__('boq.tooltip.icon.add-boq-to-tender')}
        key={_.uniqueId()}
      >
        <AddToTenderIcon
          className={styles.addToTenderIcon}
          onClick={(e) => {
            e.stopPropagation();

            setTableParams('working_set_boq', {
              selected_boq_rows: selected_rows,
              create_tender_boq_from_boq: true,
            });
          }}
        />
      </Tooltip>,
    ]);

    actions.push([
      <Tooltip
        text={__('boq.tooltip.icon.save-as-template')}
        key={_.uniqueId()}
      >
        <SaveAsTemplateIcon
          className={styles.saveAsTemplateIcon}
          onClick={(e) => {
            e.stopPropagation();

            setTableParams('working_set_boq', {
              selected_boq_rows: selected_rows,
              save_boq_items_as_template: true,
            });
          }}
        />
      </Tooltip>,
    ]);

    actions.push([
      <Tooltip
        text={__('boq.tooltip.icon.export-coming-soon')}
        key={_.uniqueId()}
      >
        <ExportIcon
          className={classNames(styles.icon, styles.disabled)}
          // onClick={(e) => {
          //   e.stopPropagation();

          //   setTableParams('working_set_boq', {
          //     selected_boq_rows: selected_rows,
          //     save_boq_items_as_template: true,
          //   });
          // }}
        />
      </Tooltip>,
    ]);

    return actions;
  }

  @autobind
  _handleCreateTemplateByLevel(level, name) {
    const actions = {
      0: {
        create: addBOQTemplateGroup,
        fetch: () =>
          listPossibleBOQGroups().then(({ response }) =>
            this.setState({ possible_boq_groups: response.data })
          ),
      },
      1: {
        create: addBOQTemplateSubgroup,
        fetch: () =>
          listPossibleBOQSubgroups().then(({ response }) =>
            this.setState({ possible_boq_subgroups: response.data })
          ),
      },
    };

    const { create, fetch } = actions[level];

    return create({ name }).then(() => fetch());
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.clicked_row &&
      this.props.clicked_row !== prevProps.clicked_row
    ) {
      if (!prevProps.clicked_row) {
        setTableParams('working_set_boq', {
          flyout: undefined,
        });
      }

      if (getURLParam('boq_item_id')) {
        getBOQItem(this.props.clicked_row).then(({ response }) => {
          setTableParams('working_set_boq', {
            flyout: response.data,
          });
        });
      }

      if (getURLParam('boq_calculation_id')) {
        getBOQCalculation(this.props.clicked_row).then(({ response }) => {
          setTableParams('working_set_boq', {
            flyout: response.data,
          });
        });
      }
    }

    if (
      (!this.props.clicked_row &&
        this.props.clicked_row !== prevProps.clicked_row) ||
      (!this.props.specification_clicked_row &&
        this.props.specification_clicked_row !==
          prevProps.specification_clicked_row)
    ) {
      redirect(this.props.match.url);
    }

    const focused_id = this.state.focused_id;
    const focused_id_parts = _.size(_.split(focused_id, '-', 5));

    const plus_actions = [
      {
        label: __('working-set-boq.button.add-category'),
        tooltip: __('working-set-boq.button.add-category'),
        disabled: false,
        onClick: () => this._addGroup(),
      },
      {
        label: __('working-set-boq.button.add-type'),
        tooltip:
          focused_id && focused_id_parts >= 1
            ? __('working-set-boq.button.add-type')
            : __('working-set-boq.button.disabled.add-type'),
        disabled: !focused_id || focused_id_parts < 1,
        onClick: () => this._addSubgroup(),
      },
      {
        label: __('working-set-boq.button.add-item-group'),
        tooltip:
          focused_id && focused_id_parts >= 2
            ? __('working-set-boq.button.add-item-group')
            : __('working-set-boq.button.disabled.add-item-group'),
        disabled: !focused_id || focused_id_parts < 2,
        onClick: () => this._addItem(),
      },
      {
        label: __('working-set-boq.button.add-item'),
        tooltip:
          focused_id && focused_id_parts >= 3
            ? __('working-set-boq.button.add-item')
            : __('working-set-boq.button.disabled.add-item'),
        disabled: !focused_id || focused_id_parts < 3,
        onClick: () => this._addCalculation(),
      },
      {
        label: __('working-set-boq.button.add-item-from-template'),
        tooltip:
          focused_id && focused_id_parts >= 3
            ? __('working-set-boq.button.add-item-from-template')
            : __('working-set-boq.button.disabled.add-item-from-template'),
        disabled: !focused_id || focused_id_parts < 3,
        onClick: () => {
          const focused_item_id = _.get(
            _.split(this.state.focused_id, '-', 5),
            '2'
          );

          setTableParams('working_set_boq', {
            add_boq_calculation_from_template_wizard: true,
            boq_item_id: focused_item_id,
          });
        },
      },
      {
        label: __('working-set-boq.button.add-element'),
        tooltip:
          focused_id && focused_id_parts >= 4
            ? __('working-set-boq.button.add-element')
            : __('working-set-boq.button.disabled.add-element'),
        disabled: !focused_id || focused_id_parts < 4,
        onClick: () => this._addPosition(),
      },
    ];

    setPlusActions(
      _.map(plus_actions, (button, i) => {
        return {
          name: button.label,
          onClick: () => button.onClick(),
          disabled: button.disabled,
        };
      })
    );

    if (this.props.store.view != prevProps.store.view) {
      getPreferences(`table.working_set_boq`).then(({ response: { data } }) => {
        const view = _.get(data, 'view', 'compact');

        setTableParams('working_set_boq', { view });

        const expanded_rows = _.get(data, 'expanded_rows', []);

        this.tableRef.setExpandedRowKeys(expanded_rows);
        this.setState({ expanded_rows });

        const rowIndex = this.props.store?.rowIndex;
        if (rowIndex) {
          this.tableRef.scrollToRow(rowIndex, 'center');
        }
      });
    }
  }

  render() {
    const { focused_id, space_allocation, expanded_rows, language_switcher } =
      this.state;
    const {
      language_id,
      project_languages,
      project_currency,
      filters,
      data,
      rowData,
    } = this.props.store;

    const { localization, specification_clicked_row, clicked_row, dropdown } =
      this.props;

    const focused_id_parts = _.size(_.split(focused_id, '-', 5));

    const add_category_buttons = [
      {
        label: __('working-set-boq.button.add-category'),
        tooltip: __('working-set-boq.button.add-category'),
        disabled: false,
        onClick: () => this._addGroup(),
      },
    ];

    const add_type_buttons = [
      {
        label: __('working-set-boq.button.add-type'),
        tooltip:
          focused_id && focused_id_parts >= 1
            ? __('working-set-boq.button.add-type')
            : __('working-set-boq.button.disabled.add-type'),
        disabled: !focused_id || focused_id_parts < 1,
        onClick: () => this._addSubgroup(),
      },
    ];

    const add_item_group_buttons = [
      {
        label: __('working-set-boq.button.add-item-group'),
        tooltip:
          focused_id && focused_id_parts >= 2
            ? __('working-set-boq.button.add-item-group')
            : __('working-set-boq.button.disabled.add-item-group'),
        disabled: !focused_id || focused_id_parts < 2,
        onClick: () => this._addItem(),
      },
    ];

    const add_item_buttons = [
      {
        label: __('working-set-boq.button.add-item'),
        tooltip:
          focused_id && focused_id_parts >= 3
            ? __('working-set-boq.button.add-item')
            : __('working-set-boq.button.disabled.add-item'),
        disabled: !focused_id || focused_id_parts < 3,
        onClick: () => this._addCalculation(),
      },
      {
        label: __('working-set-boq.button.add-items-from-template'),
        tooltip:
          focused_id && focused_id_parts == 3
            ? __('working-set-boq.button.add-items-from-template')
            : __('working-set-boq.button.disabled.add-items-from-template'),
        disabled: !focused_id || focused_id_parts != 3,
        onClick: () => this._addCalculationsFromTemplate(),
      },
      {
        label: __('working-set-boq.button.convert-to-specification'),
        tooltip:
          focused_id && focused_id_parts != 3 && !rowData?.specification_code_id
            ? __('working-set-boq.button.convert-to-specification')
            : __('working-set-boq.button.disabled.convert-to-specification'),
        disabled:
          !focused_id ||
          focused_id_parts == 3 ||
          rowData?.specification_code_id,
        onClick: () =>
          setTableParams('working_set_boq', {
            add_specification_to_calculation_wizard: focused_id,
            calculation_id: focused_id,
            unit_id: rowData?.unit_id,
            description: rowData?.name,
            project_note: rowData?.description,
          }),
      },
    ];

    const add_element_buttons = [
      {
        label: __('working-set-boq.button.add-element'),
        tooltip:
          focused_id && focused_id_parts >= 4
            ? __('working-set-boq.button.add-element')
            : __('working-set-boq.button.disabled.add-element'),
        disabled: !focused_id || focused_id_parts < 4,
        onClick: () => this._addPosition(),
      },
    ];

    const add_row_buttons = [
      {
        label: __('working-set-boq.button.add-category'),
        disabled: !focused_id || !(focused_id_parts < 2),
        onClick: () => this._addGroup(true),
      },
      {
        label: __('working-set-boq.button.add-type'),
        disabled: !focused_id || !(focused_id_parts <= 2),
        onClick: () => this._addSubgroup(true),
      },
      {
        label: __('working-set-boq.button.add-item-group'),
        disabled:
          !focused_id || !(focused_id_parts >= 2 && focused_id_parts <= 3),
        onClick: () => this._addItem(true),
      },
      {
        label: __('working-set-boq.button.add-item'),
        disabled:
          !focused_id || !(focused_id_parts >= 3 && focused_id_parts <= 4),
        onClick: () => this._addCalculation(true),
      },
      {
        label: __('working-set-boq.button.add-element'),
        disabled:
          !focused_id || !(focused_id_parts >= 4 && focused_id_parts <= 5),
        onClick: () => this._addPosition(true),
      },
    ];

    return (
      <div className={styles.wrapper}>
        <div className={styles.table}>
          {this.props.store.view === 'compact' && (
            <TableCompactView
              name='working_set_boq'
              store={this.props.store}
              filters={filters}
              localization={localization}
              languageId={language_id}
              projectCurrency={project_currency}
              focusedId={focused_id}
              data={data}
              project_languages={project_languages}
              groupActions={this.groupActions}
              spaceAllocationData={space_allocation}
              refreshData={this._refreshWorkingSetBOQ}
              setFocusedId={(focused_id) => this.setState({ focused_id })}
              setRef={(ref) => (this.tableRef = ref)}
              tableRef={this.tableRef}
              nameRefs={this.nameRefs}
              setNameRef={(id, ref) => (this.nameRefs[id] = ref)}
              expandedRows={expanded_rows}
              setExpandedRows={(expanded_rows) => {
                this.setState({ expanded_rows });

                this._saveTablePreferences();
              }}
              filterData={this._filterWorkingSetBOQ}
              languageSwitcher={language_switcher}
              setLanguageSwitcher={(value) =>
                this.setState({ language_switcher: value })
              }
              dropdown={dropdown}
              addRowButtons={add_row_buttons}
              title={__('working_set_boq')}
              sidebar={this.props.sidebar}
              possible_boq_groups={this.state.possible_boq_groups}
              possible_boq_subgroups={this.state.possible_boq_subgroups}
              handleCreateTemplateByLevel={this._handleCreateTemplateByLevel}
            />
          )}

          {this.props.store.view === 'detailed' && (
            <TableDetailedView
              name='working_set_boq'
              store={this.props.store}
              filters={filters}
              localization={localization}
              languageId={language_id}
              projectCurrency={project_currency}
              focusedId={focused_id}
              data={data}
              project_languages={project_languages}
              groupActions={this.groupActions}
              spaceAllocationData={space_allocation}
              refreshData={this._refreshWorkingSetBOQ}
              setFocusedId={(focused_id) => this.setState({ focused_id })}
              setRef={(ref) => (this.tableRef = ref)}
              tableRef={this.tableRef}
              setNameRef={(id, ref) => (this.nameRefs[id] = ref)}
              expandedRows={expanded_rows}
              setExpandedRows={(expanded_rows) => {
                this.setState({ expanded_rows });

                this._saveTablePreferences();
              }}
              filterData={this._filterWorkingSetBOQ}
              languageSwitcher={language_switcher}
              setLanguageSwitcher={(value) =>
                this.setState({ language_switcher: value })
              }
              dropdown={dropdown}
              addRowButtons={add_row_buttons}
              title={__('working_set_boq')}
              sidebar={this.props.sidebar}
              possible_boq_groups={this.state.possible_boq_groups}
              possible_boq_subgroups={this.state.possible_boq_subgroups}
              handleCreateTemplateByLevel={this._handleCreateTemplateByLevel}
              nameRefs={this.nameRefs}
            />
          )}
        </div>

        <div className={styles.footer}>
          {/* Add Category Buttons */}
          <Dropdown
            top={10}
            name='add-category'
            wrapperClassName={styles.dropdownWrapper}
            header={
              <div className={styles.dropdownHeader}>
                {__('boq.footer.button.header.add-category')}
              </div>
            }
            content={
              <div className={styles.dropdownContent}>
                {_.map(add_category_buttons, (button, i) => (
                  <div
                    className={classNames(
                      styles.item,
                      classNames(button.disabled && styles.disabled)
                    )}
                    key={i}
                    onClick={() => {
                      !button.disabled && button.onClick();
                      hideDropdown();
                    }}
                  >
                    <Tooltip text={button.tooltip}>
                      <span>{button.label}</span>
                    </Tooltip>
                  </div>
                ))}
              </div>
            }
          >
            <div className={styles.plus}>
              <strong>+</strong>
              <span>{__('boq.footer.button.label.add-category')}</span>
            </div>
          </Dropdown>

          {/* Add Type Buttons */}
          <Dropdown
            top={10}
            name='add-type'
            wrapperClassName={styles.dropdownWrapper}
            header={
              <div className={styles.dropdownHeader}>
                {__('boq.footer.button.header.add-type')}
              </div>
            }
            content={
              <div className={styles.dropdownContent}>
                {_.map(add_type_buttons, (button, i) => (
                  <div
                    className={classNames(
                      styles.item,
                      classNames(button.disabled && styles.disabled)
                    )}
                    key={i}
                    onClick={() => {
                      !button.disabled && button.onClick();
                      hideDropdown();
                    }}
                  >
                    <Tooltip text={button.tooltip}>
                      <span>{button.label}</span>
                    </Tooltip>
                  </div>
                ))}
              </div>
            }
          >
            <div className={styles.plus}>
              <strong>+</strong>
              <span>{__('boq.footer.button.label.add-type')}</span>
            </div>
          </Dropdown>

          {/* Add Item Group Buttons */}
          <Dropdown
            top={10}
            name='add-item-group'
            wrapperClassName={styles.dropdownWrapper}
            header={
              <div className={styles.dropdownHeader}>
                {__('boq.footer.button.header.add-item-group')}
              </div>
            }
            content={
              <div className={styles.dropdownContent}>
                {_.map(add_item_group_buttons, (button, i) => (
                  <div
                    className={classNames(
                      styles.item,
                      classNames(button.disabled && styles.disabled)
                    )}
                    key={i}
                    onClick={() => {
                      !button.disabled && button.onClick();
                      hideDropdown();
                    }}
                  >
                    <Tooltip text={button.tooltip}>
                      <span>{button.label}</span>
                    </Tooltip>
                  </div>
                ))}
              </div>
            }
          >
            <div className={styles.plus}>
              <strong>+</strong>
              <span>{__('boq.footer.button.label.add-item-group')}</span>
            </div>
          </Dropdown>

          {/* Add Item Buttons */}
          <Dropdown
            top={10}
            name='add-item'
            wrapperClassName={styles.dropdownWrapper}
            header={
              <div className={styles.dropdownHeader}>
                {__('boq.footer.button.header.add-item')}
              </div>
            }
            content={
              <div className={styles.dropdownContent}>
                {_.map(add_item_buttons, (button, i) => (
                  <div
                    className={classNames(
                      styles.item,
                      classNames(button.disabled && styles.disabled)
                    )}
                    key={i}
                    onClick={() => {
                      !button.disabled && button.onClick();
                      hideDropdown();
                    }}
                  >
                    <Tooltip text={button.tooltip}>
                      <span>{button.label}</span>
                    </Tooltip>
                  </div>
                ))}
              </div>
            }
          >
            <div className={styles.plus}>
              <strong>+</strong>
              <span>{__('boq.footer.button.label.add-item')}</span>
            </div>
          </Dropdown>

          {/* Add Element Buttons */}
          <Dropdown
            top={10}
            name='add-element'
            wrapperClassName={styles.dropdownWrapper}
            header={
              <div className={styles.dropdownHeader}>
                {__('boq.footer.button.header.add-element')}
              </div>
            }
            content={
              <div className={styles.dropdownContent}>
                {_.map(add_element_buttons, (button, i) => (
                  <div
                    className={classNames(
                      styles.item,
                      classNames(button.disabled && styles.disabled)
                    )}
                    key={i}
                    onClick={() => {
                      !button.disabled && button.onClick();
                      hideDropdown();
                    }}
                  >
                    <Tooltip text={button.tooltip}>
                      <span>{button.label}</span>
                    </Tooltip>
                  </div>
                ))}
              </div>
            }
          >
            <div className={styles.plus}>
              <strong>+</strong>
              <span>{__('boq.footer.button.label.add-element')}</span>
            </div>
          </Dropdown>
        </div>

        {specification_clicked_row && getURLParam('specification_id') && (
          <div className={styles.flyout}>
            <WorkingSetSpecificationsFlyout
              readAction={(specification_id) => {
                return this._refreshWorkingSetBOQ();

                return new Promise((resolve) => {
                  readWorkingSetSpecification(specification_id).then(
                    ({ response }) => {
                      setTableParams('working_set_specifications', {
                        flyout: response.data,
                        clicked_row: response.data.id,
                      });

                      resolve(response);
                    }
                  );
                });
              }}
            />
          </div>
        )}
        {clicked_row && getURLParam('boq_item_id') && (
          <div className={styles.flyout}>
            <BOQItemFlyout
              project_languages={project_languages}
              readAction={() => {
                const action = getBOQItem(this.props.clicked_row);

                return action.then(({ response }) => {
                  setTableParams('working_set_boq', {
                    flyout: response.data,
                  });

                  this._refreshWorkingSetBOQ();
                });
              }}
            />
          </div>
        )}

        {clicked_row && getURLParam('boq_calculation_id') && (
          <div className={styles.flyout}>
            <BOQCalculationFlyout
              project_languages={project_languages}
              readAction={() => {
                const action = getBOQCalculation(this.props.clicked_row);

                return action.then(({ response }) => {
                  setTableParams('working_set_boq', {
                    flyout: response.data,
                  });

                  this._refreshWorkingSetBOQ();
                });
              }}
            />
          </div>
        )}

        {clicked_row && getURLParam('boq_flyout_id') && (
          <div className={styles.flyout}>
            <BOQFlyout
              project_languages={project_languages}
              readAction={() => {
                // const action = getBOQCalculation(this.props.clicked_row);
                // return action.then(({ response }) => {
                //   setTableParams('working_set_boq', {
                //     flyout: response.data,
                //   });
                //   this._refreshWorkingSetBOQ();
                // });
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default WorkingSetBOQ;
