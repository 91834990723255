const initialState = {};

const _transformData = (data) => {
  const processDeep = (rows, parentId = undefined, level = 0, code = '') => {
    return _.map(rows, (row, i) => {
      const id = parentId ? [parentId, row.id].join('-') : _.toString(row.id);
      const new_code = code.length == 0 ? i + 1 : `${code}.${i + 1}`;

      return {
        ...row,
        code: level < 4 ? new_code : undefined,
        level,
        id,
        parentId,
        ownId: row.id,
        children: row.children
          ? processDeep(
              _.sortBy(row.children, 'order'),
              id,
              level + 1,
              new_code
            )
          : undefined,
      };
    });
  };

  const transformed_data = processDeep(_.sortBy(data.groups, 'order'));

  let last_index = data.groups.length;

  !_.isEmpty(data.unallocated) &&
    transformed_data.push({
      id: 'UN',
      level: 0,
      order: last_index + 1,
    });

  _.each(data.unallocated, (generic_specification) =>
    transformed_data.push({
      ...generic_specification,
      id: ['UN', generic_specification.id].join('-'),
      level: 3,
      order: generic_specification.order + 1,
    })
  );

  return transformed_data;
};

const _filterData = (data, filter_params, language_id) => {
  const unallocatedData = _.filter(data, (row) => _.includes(row.id, 'UN'));
  data = _.cloneDeep(_.filter(data, (row) => !_.includes(row.id, 'UN')));

  const applyChildFilter = (data, filterKey, predicate) => {
    if (_.get(filter_params, filterKey)) {
      const filterValues = _.split(_.get(filter_params, filterKey), '|');

      data = _.filter(data, (group) => {
        group.children = _.filter(group.children, (item) => {
          return predicate(filterValues, item);
        });
        return !_.isEmpty(group.children);
      });
    }
    return data;
  };

  const applyRangeFilter = (data, filterKey, fieldKey) => {
    if (_.get(filter_params, filterKey)) {
      const [start, end] = _.get(filter_params, filterKey)
        .split('|')
        .map(parseFloat);
      data = _.filter(data, (group) => {
        group.children = _.filter(group.children, (item) => {
          const value = item[fieldKey];
          const isStartValid = !start || value >= start;
          const isEndValid = !end || value <= end;
          return isStartValid && isEndValid;
        });
        return !_.isEmpty(group.children);
      });
    }
    return data;
  };

  // Apply status filter
  data = applyChildFilter(data, 'filter.status', (filterValues, spec) =>
    _.includes(filterValues, _.toString(spec.status))
  );

  // Apply unit filter
  data = applyChildFilter(data, 'filter.unit', (filterValues, spec) =>
    _.includes(filterValues, _.toString(spec.unit_id))
  );

  // Apply price filter
  data = applyRangeFilter(data, 'filter.price', 'price');

  // Apply quantity filter
  data = applyRangeFilter(data, 'filter.quantity', 'quantity');

  // Apply area filters
  const areaFilters = ['building', 'site', 'storey', 'room_type', 'room'];
  if (_.some(areaFilters, (key) => _.get(filter_params, `filter.${key}`))) {
    const areaPredicates = _.map(areaFilters, (key) => ({
      type: key,
      values: _.split(_.get(filter_params, `filter.${key}`), '|'),
    })).filter((filter) => !_.isEmpty(filter.values));

    data = _.filter(data, (group) => {
      group.children = _.filter(group.children, (spec) => {
        spec.children = _.filter(spec.children, (position) => {
          const matchingFilter = _.find(
            areaPredicates,
            (filter) => position.area_type === filter.type
          );
          return matchingFilter
            ? _.includes(matchingFilter.values, String(position.area_id))
            : true;
        });
        return !_.isEmpty(spec.children);
      });
      return !_.isEmpty(group.children);
    });
  }

  // Apply responsible user filter
  data = applyChildFilter(
    data,
    'filter.responsible_user',
    (filterValues, spec) =>
      _.includes(filterValues, _.toString(spec.responsible_user_id))
  );

  // Apply supplier company filter
  data = applyChildFilter(
    data,
    'filter.supplier_company',
    (filterValues, spec) =>
      _.includes(filterValues, _.toString(spec.supplier_company_id || '0'))
  );

  // Apply manufacturer company filter
  data = applyChildFilter(
    data,
    'filter.manufacturer_company',
    (filterValues, spec) =>
      _.includes(filterValues, _.toString(spec.manufacturer_company_id || '0'))
  );

  // Apply linked specifications filter
  data = applyChildFilter(
    data,
    'filter.linked_specifications',
    (filterValues, spec) =>
      _.some(spec.linked, (linkedSpec) =>
        _.includes(filterValues, _.toString(linkedSpec.specification_group_id))
      )
  );

  // Return data with unallocated data if no filters were applied
  return _.some(filter_params, (param) => param)
    ? data
    : [...data, ...unallocatedData];
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'table.specification_sets/SET_TABLE_PARAMS':
      return {
        ...state,
        ...action.params,
      };

    case 'table.specification_sets/LIST_SPECIFICATION_SETS_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        filters: action.response.filters,
        ...action.response.pagination,
      };

    case 'table.specification_sets/READ_SPECIFICATION_SET_SUCCESS':
      return {
        ...state,
        flyout: action.response.data,
      };

    case 'table.specification_sets/READ_SPECIFICATION_SET_ITEMS_SUCCESS':
      return {
        ...state,
        items: _filterData(
          _transformData(action.response.data),
          _.pickBy(state, (value, key) => {
            return _.startsWith(key, 'filter.') || _.includes(['query'], key);
          }),
          state?.language_id
        ),
        initialItemsData: _transformData(action.response.data), // Populate unfilteredData
      };

    case 'table.specification_sets/READ_SPECIFICATION_SET_ITEMS_FILTERS_SUCCESS':
      return {
        ...state,
        item_filters: action.response.filters,
      };
    case 'table.specification_sets/READ_SPECIFICATION_SET_DYNAMIC_COLUMNS_SUCCESS':
      return {
        ...state,
        dynamic_columns: action.response.data,
      };

    case 'table.specification_sets/FILTER_SPECIFICATION_SET_ITEMS':
      return {
        ...state,
        items: _filterData(
          state.initialItemsData,
          _.pickBy(state, (value, key) => {
            return _.startsWith(key, 'filter.') || _.includes(['query'], key);
          }),
          state?.language_id
        ),
        initialData: state.initialItemsData,
      };
    default:
      return state;
  }
}
