import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/CopyMeetingItemsToMeetingWizard.scss';
import autobind from 'autobind-decorator';
import { __, mapStateToProps, redirect, setTableParams } from '../core/utils';
import CopyIcon from '../assets/images/AddToMeeting.svg';
import { withRouter } from 'react-router';
import { setNotification } from '../redux/actions/general/notification';
import {
  addTasksToMeeting,
  copyMeetingItemsToMeeting,
} from '../redux/actions/table/all_meetings';
import { SubmissionError } from 'redux-form';
import CopyMeetingItemsToMeetingForm from '../forms/copy_meeting_items_to_meeting/CopyMeetingItemsToMeetingForm';

@withRouter
@mapStateToProps((state) => ({
  selected_rows: _.get(
    state.table,
    'all_meetings.copy_meeting_items_to_meeting_wizard.selected_rows'
  ),
  sorted_items: _.get(
    state.table,
    'all_meetings.copy_meeting_items_to_meeting_wizard.sorted_items'
  ),
}))
class CopyMeetingItemsToMeetingWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    const { new_meeting, child_meeting_id, edit } = formData;
    const {
      selected_rows,
      sorted_items,
      match: {
        params: { meeting },
      },
    } = this.props;

    // if (new_meeting) {
    //   setTableParams('all_meetings', {
    //     meeting_from_tasks: undefined, //close 'CreateMeetingFromTasksWizard'
    //     wizard_active: true, //open 'CreateMeetingWizard'
    //     tasks,
    //   });
    // } else {
    if (child_meeting_id) {
      const meeting_items_ids = sorted_items
        .filter((item) => selected_rows.includes(item.id))
        .map((item) => item.id);

      copyMeetingItemsToMeeting(meeting, {
        meeting_items: meeting_items_ids,
        child_meeting_id,
      }).then(() => {
        this.props.onClose();
        if (edit) {
          redirect('/meetings/' + child_meeting_id);
        }

        setTableParams('all_meetings', {
          forceUpdate: true,
        });

        setTimeout(
          () =>
            setNotification(
              __(
                'meetings.all-meetings.add-new.notification.selected-items-have-been-added-to-the-meeting'
              )
            ),
          25
        );
      });
    } else {
      throw new SubmissionError({ child_meeting_id: ['Field required'] });
    }
  }

  render() {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__(
                'wizard.meeting.title.copy-meeting-items-to-existing-meeting'
              )}
            </span>
            <span>
              {__(
                'wizard.meeting.subtitle.copy-meeting-items-to-existing-meeting'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <CopyIcon />
          </div>
        </div>
        <div className={styles.body}>
          <CopyMeetingItemsToMeetingForm
            initialValues={{
              new_meeting: false,
            }}
            onSubmit={this._handleSubmit}
          />
        </div>
      </>
    );
  }
}

export default CopyMeetingItemsToMeetingWizard;
