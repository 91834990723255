const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'table.timesheets/SET_TABLE_PARAMS':
      return {
        ...state,
        ...action.params,
      };
    case 'table.timesheets/LIST_TIMESHEETS_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        ...action.response.pagination,
      };
    case 'table.timesheets/LIST_TIMESHEETS_FILTERS_SUCCESS':
      return {
        ...state,
        filters: action.response.filters,
      };
    default:
      return state;
  }
}
