import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/tasks/Checklist.scss';
import {
  __,
  dateFrom,
  mapStateToProps,
  scrollToBottom,
} from '../../core/utils';
import { setNotification } from '../../redux/actions/general/notification';
import {
  addTaskChecklistItem,
  deleteTaskChecklistItem,
  reorderTaskChecklistItems,
  updateTaskChecklistItemCompletion,
  updateTaskChecklistItemTitle,
} from '../../redux/actions/table/all_tasks';
import autobind from 'autobind-decorator';
import UncheckedIcon from '../../assets/images/unchecked-15x15.svg';
import CheckedIcon from '../../assets/images/checked-15x15.svg';
import * as _ from 'lodash';
import Tooltip from '../../components/Tooltip';
import classNames from 'classnames';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import { ReactSortable } from 'react-sortablejs';
import GrabberIcon from '../../assets/images/grabber-16x16.svg';

@mapStateToProps((state) => ({
  user_id: state.auth.id,
}))
class Checklist extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    scrollToBottom(this.refs.scroll);
  }

  componentWillMount() {
    this.setState({ data: this.props.data });
  }

  @autobind
  _handleAddItem() {
    const { data } = this.props;

    addTaskChecklistItem(data.id).then(() => {
      this.props.readAction(data.id).then(({ data }) => {
        scrollToBottom(this.refs.scroll);

        _.get(
          this.refs,
          'title-' + _.get(_.last(data.checklist), 'id')
        ).focus();
      });
    });
  }

  @autobind
  _handleEditTitle(e, id) {
    const title = e.target.value;
    const { data } = this.props;

    _.toString(_.find(data.checklist, (item) => item.id == id).title) !=
      title &&
      updateTaskChecklistItemTitle(id, title).then(() => {
        setNotification(__('tasks.notification.checklist.title-saved'));

        this.props.readAction(data.id).then(() => {
          scrollToBottom(this.refs.scroll);
        });
      });
  }

  @autobind
  _handleEnter(e) {
    if (e.keyCode == 13) {
      e.persist();

      e.target.blur();
    }
  }

  @autobind
  _handleUpdateCompletion(id, completion) {
    const { data } = this.props;
    const { is_editor } = data;

    is_editor &&
      !_.includes(['closed', 'canceled'], data.status) &&
      updateTaskChecklistItemCompletion(id, completion).then(() => {
        setNotification(__('tasks.notification.checklist.state-saved'));

        this.props.readAction(data.id);
      });
  }

  @autobind
  _changeOrder(items) {
    const { data } = this.state;

    this.setState({
      data: {
        ...data,
        checklist: _.map(items, (item, i) => ({
          ...item,
          order: i,
        })),
      },
    });
  }

  @autobind
  _handleDelete(id) {
    const { data } = this.props;

    deleteTaskChecklistItem(id).then(() => {
      setNotification(__('tasks.notification.checklist.deleted'));

      this.props.readAction(data.id);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data != this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

  render() {
    const { data } = this.state;
    const { collapsed } = this.props;
    const { is_editor } = data;

    const sorted_list = _.sortBy(data.checklist, 'order');

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        {!_.isEmpty(sorted_list) &&
          is_editor &&
          !_.includes(['closed', 'canceled'], data.status) && (
            <div className={styles.heading}>
              {is_editor &&
                !_.includes(['closed', 'canceled'], data.status) && (
                  <span className={styles.grabber} />
                )}
              <span
                className={classNames(
                  styles.checkbox,
                  (!is_editor ||
                    _.includes(['closed', 'canceled'], data.status)) &&
                    styles.noCheckbox
                )}
              />
              <span className={styles.order}>#</span>
              <span className={styles.title}>
                {__('tasks.all-tasks.flyout.add-checklist-item.title')}
              </span>
              <span className={styles.completedBy}>
                {__('tasks.all-tasks.flyout.add-checklist-item.completed-by')}
              </span>
              <span className={styles.issued}>
                {__('tasks.all-tasks.flyout.add-checklist-item.issued')}
              </span>
              <span className={styles.completed}>
                {__('tasks.all-tasks.flyout.add-checklist-item.completed')}
              </span>
              {is_editor &&
                !_.includes(['closed', 'canceled'], data.status) && (
                  <span className={styles.delete} />
                )}
            </div>
          )}
        <div className={styles.scroller} ref='scroll'>
          {/* {_.isEmpty(sorted_list) ? <div className={styles.empty}>{__('meetings.tasks.flyout.there-are-no-checklist-items')}</div> : ''} */}
          {!_.isEmpty(sorted_list) && (
            <ReactSortable
              list={sorted_list}
              setList={this._changeOrder}
              onEnd={() =>
                reorderTaskChecklistItems(data.id, _.map(sorted_list, 'id'))
              }
              animation={200}
              delayOnTouchStart={true}
              handle={'.' + styles.grabber}
            >
              {_.map(sorted_list, (row, i) => (
                <div
                  className={classNames(
                    styles.row,
                    row.completed_at && styles.blue
                  )}
                  key={row.id}
                >
                  {is_editor &&
                    !_.includes(['closed', 'canceled'], data.status) && (
                      <div className={styles.grabber}>
                        <GrabberIcon />
                      </div>
                    )}
                  <span
                    className={classNames(
                      styles.checkbox,
                      (!is_editor ||
                        _.includes(['closed', 'canceled'], data.status)) &&
                        styles.disabled,
                      (!is_editor ||
                        _.includes(['closed', 'canceled'], data.status)) &&
                        styles.noCheckbox
                    )}
                  >
                    {!row.completed_at && (
                      <UncheckedIcon
                        className={styles.unchecked}
                        onClick={() =>
                          this._handleUpdateCompletion(row.id, true)
                        }
                      />
                    )}
                    {row.completed_at && (
                      <CheckedIcon
                        className={styles.checked}
                        onClick={() =>
                          this._handleUpdateCompletion(row.id, false)
                        }
                      />
                    )}
                  </span>
                  <span className={styles.order}>
                    {_.padStart(row.order, 2, '0')}
                  </span>
                  <span className={styles.title}>
                    <input
                      readOnly={
                        !is_editor ||
                        _.includes(['closed', 'canceled'], data.status)
                      }
                      onKeyDown={this._handleEnter}
                      onBlur={(e) => this._handleEditTitle(e, row.id)}
                      ref={'title-' + row.id}
                      defaultValue={row.title}
                      placeholder='n/a'
                    />
                  </span>
                  <span className={styles.completedBy}>
                    {row.completed_by || 'n/a'}
                  </span>
                  <span className={styles.issued}>
                    {dateFrom(row.created_at)}
                  </span>
                  <span className={styles.completed}>
                    {row.completed_at ? dateFrom(row.completed_at) : 'n/a'}
                  </span>
                  {is_editor &&
                    !_.includes(['closed', 'canceled'], data.status) && (
                      <span
                        className={styles.delete}
                        onClick={() => this._handleDelete(row.id)}
                      >
                        <Tooltip
                          text={__('projects.tooltips.tasks.delete-checkitem')}
                        >
                          <DeleteIcon />
                        </Tooltip>
                      </span>
                    )}
                </div>
              ))}
            </ReactSortable>
          )}
        </div>
        {is_editor && !_.includes(['closed', 'canceled'], data.status) && (
          <div className={styles.addItem}>
            <button onClick={this._handleAddItem}>
              {__('tasks.notification.checklist.add')}
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default Checklist;
