import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { __, getActiveStage } from '../../core/utils';
import styles from '../../styles/forms/wizard_multiple_task_tags_edit/MultipleTaskTagsEditForm.scss';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import Tags from '../../components/Tags';
import { readStage } from '../../redux/actions/table/stages';
import { listPossibleTags } from '../../redux/actions/general';

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      hideMultipleTaskTagsEditWizard: () =>
        dispatch({
          type: 'table.all_tasks/SET_TABLE_PARAMS',
          params: {
            wizard_multiple_task_tags_edit: undefined,
          },
        }),
    },
  };
};

@reduxForm({
  form: 'wizard_multiple_task_tags_edit.multiple_task_tags_edit',
})
@connect(null, mapDispatchToProps)
class MultipleTaskTagsEditForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stage_tags: [],
    };
  }

  componentDidMount() {
    readStage(getActiveStage()).then(({ response }) => {
      listPossibleTags('project', response.data.project_id).then(
        ({ response }) => {
          this.setState({ stage_tags: response.data });
        }
      );
    });
  }

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <span className={styles.desc}>
          {__(
            'all-tasks.multiple-actions.multiple-tag-edit-wizard.description.action-will-add-additional-tags'
          )}
        </span>
        <Field
          name='tags'
          component={Tags}
          label={__('Tags')}
          options={this.state.stage_tags}
        />
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={this.props.actions.hideMultipleTaskTagsEditWizard}
          />
          <Button
            lightBlue
            medium
            submit
            right
            middleText={__('Save')}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default MultipleTaskTagsEditForm;
