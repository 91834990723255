import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/move_selected_meeting_items_wizard/MoveSelectedMeetingItemsForm.scss';
import Button from '../../components/Button';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import * as _ from 'lodash';
import RadioGroup from '../../components/RadioGroup';

@withRouter
@subscribe(Localization, 'localization')
@reduxForm({
  form: 'move_selected_meeting_items_wizard.move_selected_meeting_items',
})
class MoveSelectedMeetingItemsForm extends Component {
  render() {
    const { options } = this.props;

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.form}>
          <Field
            name='move_action'
            component={RadioGroup}
            options={[
              {
                label: __('move-meeting-item.move-before'),
                value: 'before',
              },
              {
                label: __('move-meeting-item.move-after'),
                value: 'after',
              },
            ]}
          />

          <Field
            name='selected_item_id'
            component={Select}
            searchable
            noSort
            label={__('move-meeting-item.selected-item')}
            options={options}
          />
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('all_meetings', {
                move_selected_meeting_items_wizard: undefined,
              })
            }
          />
          <Button
            lightBlue
            medium
            right
            submit
            middleText={__('button.move')}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default MoveSelectedMeetingItemsForm;
