import React, { useState, useEffect } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/add_foreign_editors_wizard/AddForeignEditorsForm.scss';
import ArrowRightMiddleIcon from '../../assets/images/arrow-right-middle-15x15.svg';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import * as _ from 'lodash';
import { __, getActiveStage } from '../../core/utils';
import PeopleChooser from '../../components/PeopleChooser';
import { listPossibleSpecificationSetForeignEditors } from '../../redux/actions/table/specification_sets';

const AddForeignEditorsForm = (props) => {
  return (
    <Form onSubmit={props.handleSubmit} className={styles.wrapper}>
      <Field
        name="foreign_editors"
        component={PeopleChooser}
        label={__('table.columns.foreign-editors')}
        searchable
        options={props.possibleForeignEditors}
      />

      <div className={styles.footer}>
        <Button
          lightGray
          medium
          left
          middleText={__('button.cancel')}
          onClick={props.actions.hideAddForeignEditorsWizard}
        />
        <Button
          gray
          medium
          submit
          right
          leftText={__('button.add')}
          rightIcon={ArrowRightMiddleIcon}
          spinning={props.submitting}
        />
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    table: state.table['specification_sets'],
    values: getFormValues('add_foreign_editor_wizard.add_foreign_editor')(
      state
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      hideAddForeignEditorsWizard: () =>
        dispatch({
          type: 'table.specification_sets/SET_TABLE_PARAMS',
          params: {
            add_foreign_editors_wizard_active: undefined,
          },
        }),
    },
  };
};

export default reduxForm({
  form: 'add_foreign_editor_wizard.add_foreign_editor',
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(subscribe(Localization, 'localization')(AddForeignEditorsForm))
);
