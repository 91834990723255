import React from 'react';
import styles from '../styles/containers/TitleBar.scss';
import { __, redirect } from '../core/utils';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import _ from 'lodash';
import { connect } from 'react-redux';
import classNames from 'classnames';
import ArrowDownSmallIcon from '../assets/images/arrow-down-small-15x15.svg';
import Tooltip from '../components/Tooltip';
import { hideDropdown } from '../redux/actions/general/dropdown';
import Dropdown from '../components/Dropdown';

const TitleBar = ({ title, sidebar, loading, toolbar = false }) => {
  const RenderTitle = () => {
    return (
      <div className={styles.wrapper}>
        {_.size(sidebar.items) > 1 ? (
          <Dropdown
            top={25}
            name={title}
            readOnly={!sidebar.hidden}
            wrapperClassName={styles.dropdownMenuWrapper}
            header={
              <div className={styles.dropdownMenuHeader}>
                {__(
                  'titlemenu.header.' + _.replace(_.lowerCase(title), ' ', '')
                )}
              </div>
            }
            content={
              <div className={styles.dropdownMenuContent}>
                {_.map(sidebar.items, (item, i) => (
                  <div
                    className={classNames(
                      styles.item,
                      item.active && styles.activeItem
                    )}
                    key={i}
                    onClick={() => {
                      redirect(item.link());

                      hideDropdown();
                    }}
                  >
                    <Tooltip text={item.title}>
                      <span>{item.title}</span>
                    </Tooltip>

                    <div className={styles.dot} />
                  </div>
                ))}
              </div>
            }
          >
            <div className={styles.label}>
              {title}

              {sidebar.hidden && <ArrowDownSmallIcon />}
            </div>
          </Dropdown>
        ) : (
          title
        )}

        <div className={classNames(styles.preloader, loading && styles.active)}>
          <div className={styles.spinner}>
            <div></div>
          </div>
        </div>
      </div>
    );
  };

  if (!toolbar) {
    return <RenderTitle />;
  }

  return (
    <div className={styles.toolbar}>
      <RenderTitle />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    sidebar: state.general.sidebar,
  };
}

export default connect(
  mapStateToProps,
  null
)(subscribe(Localization, 'localization')(TitleBar));
