import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/edit_specifications_status_wizard/EditSpecificationsStatusForm.scss';
import Button from '../../components/Button';
import { __, setTableParams } from '../../core/utils';
import ArrowRightMiddleIcon from '../../assets/images/arrow-right-middle-15x15.svg';
import Select from '../../components/Select';
import { withRouter } from 'react-router';

@withRouter
@reduxForm({
  form: 'edit_specifications_status_wizard.edit_specifications_status',
})
class EditSpecificationsStatusForm extends Component {
  render() {
    const statuses = [
      {
        value: 'active',
        label: __('working_set_specifications.status.active'),
        color: '#9F4FA1',
      },
      {
        value: 'active_25',
        label: __('working_set_specifications.status.active_at_25_percent'),
        color: '#8168B3',
      },
      {
        value: 'active_50',
        label: __('working_set_specifications.status.active_at_50_percent'),
        color: '#6383C5',
      },
      {
        value: 'active_75',
        label: __('working_set_specifications.status.active_at_75_percent'),
        color: '#459EB6',
      },
      {
        value: 'ready_for_delivery',
        label: __('working_set_specifications.status.ready_for_delivery'),
        color: '#96C236',
      },
      {
        value: 'canceled',
        label: __('working_set_specifications.status.canceled'),
        color: '#B0B1B7',
      },
    ];

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.form}>
          <Field
            name='status'
            component={Select}
            label={__('Status')}
            options={statuses}
          />
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('specification_sets', {
                edit_specifications_status_wizard: undefined,
              })
            }
          />
          <Button
            gray
            medium
            submit
            right
            leftText={__('button.start')}
            rightIcon={ArrowRightMiddleIcon}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default EditSpecificationsStatusForm;
