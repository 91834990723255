import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/EditSpecificationsResponsibleUserWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  mapStateToProps,
  setTableParams,
  transformToListPairs,
  validateForm,
} from '../core/utils';
import { withRouter } from 'react-router';
import { setNotification } from '../redux/actions/general/notification';
import UserIcon from '../assets/images/user-16x16.svg';
import { listPossibleSpecificationResponsibleUsers } from '../redux/actions/table/working_set_specifications';
import EditSpecificationsResponsibleUserForm from '../forms/edit_specifications_responsible_user_wizard/EditSpecificationsResponsibleUserForm';
import {
  editSpecificationsResponsibleUser,
  readSpecificationSetItems,
} from '../redux/actions/table/specification_sets';
import { hideAlertbox, showAlertbox } from '../redux/actions/general/alertbox';
import { SubmissionError } from 'redux-form';

@withRouter
@mapStateToProps((state) => ({
  selected_specification_ids:
    state.table.specification_sets.selected_specification_ids,
}))
class EditSpecificationsResponsibleUserWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possible_specification_responsible_users: [],
    };
  }
  @autobind
  _handleSubmit(formData) {
    if (!formData.responsible_user_id) {
      throw new SubmissionError({ responsible_user_id: ['Field required'] });
    }

    return showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __(
        'specification-set-items.alert.update-specifications-responsible-user-confirmation'
      ),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes'),
          onClick: () => {
            return validateForm(
              editSpecificationsResponsibleUser(
                this.props.selected_specification_ids,
                formData.responsible_user_id
              ).then(() => {
                setNotification(
                  __(
                    'wizard.specification-set-items.message.specifications-responsible-user-updated'
                  )
                );

                this.props.onClose();

                readSpecificationSetItems(
                  this.props.match.params.specification_set
                );

                hideAlertbox();

                setTableParams('specification_sets', {
                  selected_rows: [],
                });
              })
            );
          },
        },
      ],
    });
  }

  componentDidMount() {
    listPossibleSpecificationResponsibleUsers(
      this.props.match.params.stage
    ).then(({ response }) => {
      this.setState({
        possible_specification_responsible_users: transformToListPairs(
          response.data
        ),
      });
    });
  }

  render() {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__(
                'wizard.specification-set-items.title.edit-responsible_user-for-selected-specifications'
              )}
            </span>
            <span>
              {__(
                'wizard.specification-set-items.subtitle.edit-responsible_user-for-selected-specifications'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <UserIcon />
          </div>
        </div>
        <div className={styles.body}>
          <EditSpecificationsResponsibleUserForm
            onSubmit={this._handleSubmit}
            possibleSpecificationResponsibleUsers={
              this.state.possible_specification_responsible_users
            }
          />
        </div>
      </>
    );
  }
}

export default EditSpecificationsResponsibleUserWizard;
