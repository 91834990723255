import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/AddLinkedSpecificationWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  getActiveProject,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import AddLinkedSpecificationIcon from '../assets/images/add-linked-specification-64x64.svg';
import { withRouter } from 'react-router';
import AddLinkedSpecificationForm from '../forms/add_linked_specification_wizard/AddLinkedSpecificationForm';
import {
  addLinkedSpecificationCode,
  readWorkingSetSpecification,
} from '../redux/actions/table/working_set_specifications';
import { setNotification } from '../redux/actions/general/notification';
import { readSpecificationSetItems } from '../redux/actions/table/specification_sets';
import { readProjectGeneral } from '../redux/actions/projects';

@mapStateToProps((state) => ({
  store: _.get(state.table, 'working_set_specifications'),
}))
@withRouter
class AddLinkedSpecificationWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project_language: null,
    };
  }

  @autobind
  _handleSubmit(formData) {
    return validateForm(
      addLinkedSpecificationCode(
        this.props.store.add_linked_specification_wizard,
        formData
      ).then(() => {
        const specification_set = this.props.match.params.specification_set;

        specification_set
          ? readSpecificationSetItems(specification_set).then(() =>
              this.props.onClose()
            )
          : readWorkingSetSpecification(this.props.store.flyout.id).then(
              ({ response }) => {
                setNotification('A linked specification has been added.');

                setTableParams('working_set_specifications', {
                  flyout: response.data,
                });
                this.props.onClose();
              }
            );
      })
    );
  }

  componentDidMount() {
    readProjectGeneral(getActiveProject()).then(({ response }) => {
      this.setState({ project_language: response.data.main_language });
    });
  }

  render() {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__(
                'wizard.working-set-specifications.title.add-linked-specification'
              )}
            </span>
            <span>
              {__(
                'wizard.working-set-specifications.subtitle.add-linked-specification'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <AddLinkedSpecificationIcon />
          </div>
        </div>
        <div className={styles.body}>
          {this.state.project_language && (
            <AddLinkedSpecificationForm
              onSubmit={this._handleSubmit}
              project_language={this.state.project_language}
              initialValues={{
                included_in_price: '0',
                quantity: 1,
              }}
            />
          )}
        </div>
      </>
    );
  }
}

export default AddLinkedSpecificationWizard;
