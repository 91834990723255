import store from '../../../core/store';

export const listPossibleMeetingTasks = (stage_id) => {
  return store.dispatch({
    action: 'table.all_meetings/LIST_POSSIBLE_MEETING_TASKS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/possible_meeting_tasks',
  });
};

export const createMeeting = (stage_id, formData) => {
  return store.dispatch({
    action: 'table.all_meetings/CREATE_MEETING',
    method: 'POST',
    endpoint: '/stages/' + stage_id + '/meetings',
    data: formData,
  });
};

export const listAllMeetings = (stage_id, status) => {
  return store.dispatch({
    action: 'table.all_meetings/LIST_ALL_MEETINGS',
    method: 'GET',
    endpoint:
      '/stages/' + stage_id + '/meetings' + (status ? '?status=' + status : ''),
    main: true,
  });
};

export const readMeeting = (meeting_id) => {
  return store.dispatch({
    action: 'table.all_meetings/READ_MEETING',
    method: 'GET',
    endpoint: '/meetings/' + meeting_id,
    has404: true,
  });
};

export const readMeetingPrintDetails = (meeting_id) => {
  return store.dispatch({
    action: 'table.all_meetings/READ_MEETING_PRINT_DETAILS',
    method: 'GET',
    endpoint: '/meetings/' + meeting_id + '/print',
  });
};

export const listPossibleMeetingParticipants = (stage_id) => {
  return store.dispatch({
    action: 'table.all_meetings/LIST_POSSIBLE_MEETING_PARTICIPANTS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/possible_meeting_participants',
  });
};

export const listPossibleMeetingAdministrators = (stage_id) => {
  return store.dispatch({
    action: 'table.all_meetings/LIST_POSSIBLE_MEETING_ADMINISTRATORS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/possible_meeting_administrators',
  });
};

export const listPossibleMeetingFollowers = (stage_id) => {
  return store.dispatch({
    action: 'table.all_meetings/LIST_POSSIBLE_MEETING_FOLLOWERS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/possible_meeting_followers',
  });
};

export const updateMeeting = (meeting_id, formData) => {
  return store.dispatch({
    action: 'table.all_meetings/UPDATE_MEETING',
    method: 'PUT',
    endpoint: '/meetings/' + meeting_id,
    data: formData,
  });
};

export const updateMeetingToolbar = (meeting_id, formData) => {
  return store.dispatch({
    action: 'table.all_meetings/UPDATE_MEETING_TOOLBAR',
    method: 'PUT',
    endpoint: '/meetings/' + meeting_id + '/toolbar',
    data: formData,
  });
};

export const readMeetingItems = (meeting_id) => {
  return store.dispatch({
    action: 'table.all_meetings/READ_MEETING_ITEMS',
    method: 'GET',
    endpoint: '/meetings/' + meeting_id + '/items',
    has404: true,
  });
};

export const deleteMeetingItems = (meeting_item_ids) => {
  return store.dispatch({
    action: 'table.all_meetings/DELETE_MEETING_ITEMS',
    method: 'DELETE',
    endpoint: '/meetings/items',
    data: {
      meeting_items: meeting_item_ids,
    },
  });
};

export const reorderMeetingItems = (meeting_id, items) => {
  return store.dispatch({
    action: 'table.all_meetings/REORDER_MEETING_ITEMS',
    method: 'PUT',
    endpoint: '/meetings/' + meeting_id + '/items/reorder',
    data: {
      items,
    },
  });
};

export const updateMeetingItemDuration = (item_id, duration) => {
  return store.dispatch({
    action: 'table.all_meetings/UPDATE_MEETING_ITEM_DURATION',
    method: 'PUT',
    endpoint: '/meetings/items/' + item_id + '/duration',
    data: {
      duration,
    },
  });
};

export const updateMeetingDuration = (meeting_id, duration) => {
  return store.dispatch({
    action: 'table.all_meetings/UPDATE_MEETING_DURATION',
    method: 'PUT',
    endpoint: '/meetings/' + meeting_id + '/duration',
    data: {
      duration,
    },
  });
};

export const updateMeetingTitle = (meeting_id, title) => {
  return store.dispatch({
    action: 'table.all_meetings/UPDATE_MEETING_TITLE',
    method: 'PUT',
    endpoint: '/meetings/' + meeting_id + '/title',
    data: {
      title,
    },
  });
};

export const createMeetingGroupItem = (meeting_id, order) => {
  return store.dispatch({
    action: 'table.all_meetings/CREATE_MEETING_GROUP_ITEM',
    method: 'POST',
    endpoint: '/meetings/' + meeting_id + '/groups',
    data: {
      order,
    },
  });
};

export const createMeetingTopicItem = (meeting_id, order) => {
  return store.dispatch({
    action: 'table.all_meetings/CREATE_MEETING_TOPIC_ITEM',
    method: 'POST',
    endpoint: '/meetings/' + meeting_id + '/topics',
    data: {
      order,
    },
  });
};

export const createMeetingTaskItem = (meeting_id, order) => {
  return store.dispatch({
    action: 'table.all_meetings/CREATE_MEETING_TASK_ITEM',
    method: 'POST',
    endpoint: '/meetings/' + meeting_id + '/tasks',
    data: {
      order,
    },
  });
};

export const updateMeetingItemTitle = (item_id, title) => {
  return store.dispatch({
    action: 'table.all_meetings/UPDATE_MEETING_ITEM_TITLE',
    method: 'PUT',
    endpoint: '/meetings/items/' + item_id + '/title',
    data: {
      title,
    },
  });
};

export const listPossibleExistingMeetingTasks = (meeting_id) => {
  return store.dispatch({
    action: 'table.all_meetings/LIST_POSSIBLE_EXISTING_MEETING_TASKS',
    method: 'GET',
    endpoint: '/meetings/' + meeting_id + '/possible_existing_meeting_tasks',
  });
};

export const addTasksToMeeting = (meeting_id, formData) => {
  return store.dispatch({
    action: 'table.all_meetings/ADD_TASKS_TO_MEETING',
    method: 'POST',
    endpoint: '/meetings/' + meeting_id + '/tasks/existing',
    data: formData,
  });
};

export const copyMeetingItemsToMeeting = (meeting_id, formData) => {
  return store.dispatch({
    action: 'table.all_meetings/COPY_MEETING_ITEMS_TO_MEETING',
    method: 'POST',
    endpoint: '/meetings/' + meeting_id + '/items',
    data: formData,
  });
};

export const updateMeetingItemDescription = (
  item_id,
  description,
  language
) => {
  return store.dispatch({
    action: 'table.all_meetings/UPDATE_MEETING_ITEM_DESCRIPTION',
    method: 'PUT',
    endpoint: '/meetings/items/' + item_id + '/description',
    data: {
      description,
      language,
    },
  });
};

export const createMeetingItemComment = (item_id, comment) => {
  return store.dispatch({
    action: 'table.all_meetings/CREATE_MEETING_ITEM_COMMENT',
    method: 'POST',
    endpoint: '/meetings/items/' + item_id + '/comments',
    data: {
      comment,
    },
  });
};

export const uploadMeetingItemFiles = (item_id, files) => {
  return store.dispatch({
    action: 'table.all_meetings/UPLOAD_MEETING_ITEM_FILES',
    method: 'POST',
    endpoint: '/meetings/items/' + item_id + '/files',
    data: {
      files,
    },
  });
};

export const deleteMeetingItemFile = (item_id, file) => {
  return store.dispatch({
    action: 'table.all_meetings/UPLOAD_MEETING_ITEM_FILES',
    method: 'DELETE',
    endpoint: '/meetings/items/' + item_id + '/files',
    data: file,
  });
};

export const convertMeetingItemToTask = (item_id) => {
  return store.dispatch({
    action: 'table.all_meetings/CONVERT_MEETING_ITEM_TO_TASK',
    method: 'PUT',
    endpoint: '/meetings/items/' + item_id + '/convert/task',
  });
};

export const updateMeetingUserPreferences = (meeting_id, formData) => {
  return store.dispatch({
    action: 'table.all_meetings/UPDATE_MEETING_USER_PREFERENCES',
    method: 'PUT',
    endpoint: '/meetings/' + meeting_id + '/preferences',
    data: formData,
  });
};

export const listExistingMeetings = (stage_id) => {
  return store.dispatch({
    action: 'table.all_meetings/LIST_EXISTING_MEETINGS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/existing_meetings',
  });
};

export const addUnregisteredMeetingParticipant = (meeting_id, formData) => {
  return store.dispatch({
    action: 'table.all_meetings/ADD_UNREGISTERED_MEETING_PARTICIPANT',
    method: 'POST',
    endpoint: '/meetings/' + meeting_id + '/unregistered_participants',
    data: formData,
  });
};

export const addUnregisteredMeetingFollower = (meeting_id, formData) => {
  return store.dispatch({
    action: 'table.all_meetings/ADD_UNREGISTERED_MEETING_FOLLOWER',
    method: 'POST',
    endpoint: '/meetings/' + meeting_id + '/unregistered_followers',
    data: formData,
  });
};

export const updateUnregisteredMeetingParticipant = (member_id, formData) => {
  return store.dispatch({
    action: 'table.all_meetings/UPDATE_UNREGISTERED_MEETING_PARTICIPANT',
    method: 'PUT',
    endpoint: '/meetings/unregistered_participants/' + member_id,
    data: formData,
  });
};

export const updateUnregisteredMeetingFollower = (member_id, formData) => {
  return store.dispatch({
    action: 'table.all_meetings/UPDATE_UNREGISTERED_MEETING_FOLLOWER',
    method: 'PUT',
    endpoint: '/meetings/unregistered_followers/' + member_id,
    data: formData,
  });
};

export const deleteUnregisteredMeetingParticipant = (member_id) => {
  return store.dispatch({
    action: 'table.all_meetings/DELETE_UNREGISTERED_MEETING_PARTICIPANT',
    method: 'DELETE',
    endpoint: '/meetings/unregistered_participants/' + member_id,
  });
};

export const deleteUnregisteredMeetingFollower = (member_id) => {
  return store.dispatch({
    action: 'table.all_meetings/DELETE_UNREGISTERED_MEETING_FOLLOWER',
    method: 'DELETE',
    endpoint: '/meetings/unregistered_followers/' + member_id,
  });
};

export const sendMeetingReport = (meeting_id, formData) => {
  return store.dispatch({
    action: 'table.all_meetings/SEND_MEETING_REPORT',
    method: 'POST',
    endpoint: '/meetings/' + meeting_id + '/report/send',
    data: formData,
  });
};

export const generateMeetingReport = (meeting_id, formData) => {
  return store.dispatch({
    action: 'table.all_meetings/GENERATE_MEETING_REPORT',
    method: 'POST',
    endpoint: '/meetings/' + meeting_id + '/report/print',
    data: formData,
  });
};

export const validateFollowUpMeetingBasicAttributes = (
  meeting_id,
  formData
) => {
  return store.dispatch({
    action: 'table.all_meetings/VALIDATE_FOLLOW_UP_MEETING_BASIC_ATTRIBUTES',
    method: 'POST',
    endpoint: '/meetings/' + meeting_id + '/followup/validate',
    data: formData,
  });
};

export const readFollowUpMeetingDetails = (meeting_id) => {
  return store.dispatch({
    action: 'table.all_meetings/READ_FOLLOW_UP_MEETING_DETAILS',
    method: 'GET',
    endpoint: '/meetings/' + meeting_id + '/followup',
  });
};

export const createFollowUpMeeting = (meeting_id, formData) => {
  return store.dispatch({
    action: 'table.all_meetings/CREATE_FOLLOW_UP_MEETING',
    method: 'POST',
    endpoint: '/meetings/' + meeting_id + '/followup',
    data: formData,
  });
};
