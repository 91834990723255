import * as React from 'react';
import styles from '../styles/wizards/AddSpecificationSetTemplateWizard.scss';
import { __, setTableParams, validateForm } from '../core/utils';
import BOQIcon from '../assets/images/boq-24x24.svg';
import { withRouter } from 'react-router';
import { setNotification } from '../redux/actions/general/notification';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import AddLongDescriptionIcon from '../assets/images/add-long-description-from-boq-16x16.svg';
import {
  readWorkingSetSpecification,
  updateSpecificationRevisionDescription,
} from '../redux/actions/table/working_set_specifications';
import EditSpecificationRevisionDescriptionForm from '../forms/edit_specification_revision_description_wizard/EditSpecificationRevisionDescriptionForm';
import { connect } from 'react-redux';

const EditSpecificationRevisionDescriptionWizard = ({ onClose, id }) => {
  const _handleSubmit = (formData) => {
    const description = formData.description || '';

    return validateForm(
      updateSpecificationRevisionDescription(id, description).then(() => {
        readWorkingSetSpecification(id).then(({ response }) => {
          setTableParams('working_set_specifications', {
            flyout: response.data,
          });
        });

        onClose();

        setNotification(
          __(
            'working-set-specifications.revisions.specification-revision-description-saved'
          )
        );
      })
    );
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>
            {__('wizard.edit-specification-revision-description.title')}
          </span>
          <span>
            {__('wizard.edit-specification-revision-description.subtitle')}
          </span>
        </div>
        <div className={styles.right}>
          <AddLongDescriptionIcon />
        </div>
      </div>
      <div className={styles.body}>
        <EditSpecificationRevisionDescriptionForm
          onSubmit={_handleSubmit}
          onClose={onClose}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    id: state.table.working_set_specifications
      .edit_specification_revision_description_wizard,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(
    subscribe(
      Localization,
      'localization'
    )(EditSpecificationRevisionDescriptionWizard)
  )
);
