import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/edit_specifications_responsible_user_wizard/EditSpecificationsResponsibleUserForm.scss';
import Button from '../../components/Button';
import { __, setTableParams } from '../../core/utils';
import ArrowRightMiddleIcon from '../../assets/images/arrow-right-middle-15x15.svg';
import Select from '../../components/Select';
import { withRouter } from 'react-router';

@withRouter
@reduxForm({
  form: 'edit_specifications_responsible_user_wizard.edit_specifications_responsible_user',
})
class EditSpecificationsResponsibleUserForm extends Component {
  render() {
    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.form}>
          <Field
            name='responsible_user_id'
            component={Select}
            label={__('Responsible user')}
            options={this.props.possibleSpecificationResponsibleUsers}
          />
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('specification_sets', {
                edit_specifications_responsible_user_wizard: undefined,
              })
            }
          />
          <Button
            gray
            medium
            submit
            right
            leftText={__('button.start')}
            rightIcon={ArrowRightMiddleIcon}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default EditSpecificationsResponsibleUserForm;
