import store from '../../../core/store';

export const listTimesheets = () => {
  return store.dispatch({
    action: 'table.timesheets/LIST_TIMESHEETS',
    method: 'GET',
    endpoint: '/user_timesheets',
    main: true,
  });
};

export const createTimesheet = (formData) => {
  return store.dispatch({
    action: 'table.timesheets/CREATE_TIMESHEET',
    method: 'POST',
    endpoint: '/user_timesheets',
    data: formData,
  });
};

export const listPossibleWorkSites = () => {
  return store.dispatch({
    action: 'table.timesheets/LIST_POSSIBLE_WORK_SITES',
    method: 'GET',
    endpoint: '/work_sites/list_possible',
  });
};
