import store from '../../../core/store';

export const listPossibleSpecificationProjects = () => {
  return store.dispatch({
    action:
      'table.working_set_specifications/LIST_POSSIBLE_SPECIFICATION_PROJECTS',
    method: 'GET',
    endpoint: '/specifications/possible_projects',
  });
};

export const listPossibleSpecificationStages = (project_id) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/LIST_POSSIBLE_SPECIFICATION_STAGES',
    method: 'GET',
    endpoint: '/projects/' + project_id + '/possible_stages',
  });
};

export const listPossibleSpecificationTeams = (stage_id) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/LIST_POSSIBLE_SPECIFICATION_STAGES',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/possible_specification_teams',
  });
};

export const listPossibleResponsibleSpecificationTeams = (stage_id) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/LIST_POSSIBLE_RESPONSIBLE_SPECIFICATION_STAGES',
    method: 'GET',
    endpoint:
      '/stages/' + stage_id + '/possible_responsible_specification_teams',
  });
};

export const addProductToProject = (product_id, formData) => {
  return store.dispatch({
    action: 'table.products/ADD_PRODUCT_TO_PROJECT',
    method: 'POST',
    endpoint: '/products/' + product_id + '/add_to_project',
    data: formData,
  });
};

export const listWorkingSetSpecifications = (stage_id) => {
  return store.dispatch({
    action: 'table.working_set_specifications/LIST_WORKING_SET_SPECIFICATIONS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/specifications/working',
    main: true,
  });
};

export const readWorkingSetSpecification = (specification_id) => {
  return store.dispatch({
    action: 'table.working_set_specifications/READ_WORKING_SET_SPECIFICATIONS',
    method: 'GET',
    endpoint: '/specifications/working/' + specification_id,
    has404: true,
  });
};

export const readSharedSpecification = (specification_id) => {
  return store.dispatch({
    action: 'table.working_set_specifications/READ_SHARED_SPECIFICATIONS',
    method: 'GET',
    endpoint: '/specifications/shared/' + specification_id,
    has404: true,
  });
};

export const updateWorkingSetSpecification = (specification_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/UPDATE_WORKING_SET_SPECIFICATION',
    method: 'PUT',
    endpoint: '/specifications/' + specification_id,
    data: formData,
  });
};

export const deleteWorkingSetSpecifications = (specification_ids) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/DELETE_WORKING_SET_SPECIFICATIONS',
    method: 'DELETE',
    endpoint: '/specifications',
    data: {
      specifications: specification_ids,
    },
  });
};

export const validateSpecificationBasicAttributes = (stage_id, formData) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/VALIDATE_SPECIFICATION_BASIC_ATTRIBUTES',
    method: 'POST',
    endpoint:
      '/stages/' + stage_id + '/specifications/basic_attributes/validate',
    data: formData,
  });
};

export const getNextSpecificationCode = (stage_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/GET_NEXT_SPECIFICATION_CODE',
    method: 'POST',
    endpoint: '/stages/' + stage_id + '/specifications/next_code',
    data: formData,
  });
};

export const checkSpecificationCode = (team_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/CHECK_SPECIFICATION_CODE',
    method: 'POST',
    endpoint: '/teams/' + team_id + '/specifications/check_code',
    data: formData,
  });
};

export const createSpecification = (stage_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/CREATE_SPECIFICATION',
    method: 'POST',
    endpoint: '/stages/' + stage_id + '/specifications',
    data: formData,
  });
};

export const uploadSpecificationThumbnails = (specification_id, images) => {
  return store.dispatch({
    action: 'table.working_set_specifications/UPLOAD_SPECIFICATION_THUMBNAILS',
    method: 'POST',
    endpoint: '/specifications/' + specification_id + '/thumbnails',
    data: {
      images,
    },
  });
};

export const deleteSpecificationThumbnail = (specification_id) => {
  return store.dispatch({
    action: 'table.working_set_specifications/DELETE_SPECIFICATION_THUMBNAIL',
    method: 'DELETE',
    endpoint: '/specifications/' + specification_id + '/thumbnail',
  });
};

export const uploadSpecificationFiles = (specification_id, files) => {
  return store.dispatch({
    action: 'table.working_set_specifications/UPLOAD_SPECIFICATION_FILES',
    method: 'POST',
    endpoint: '/specifications/' + specification_id + '/files',
    data: {
      files,
    },
  });
};

export const updateSpecificationFileCategory = (specification_id, formData) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/UPDATE_SPECIFICATION_FILE_CATEGORY',
    method: 'POST',
    endpoint: '/specifications/' + specification_id + '/file/category',
    data: formData,
  });
};

export const deleteSpecificationFile = (
  specification_id,
  category,
  attachment_id
) => {
  return store.dispatch({
    action: 'table.working_set_specifications/DELETE_SPECIFICATION_FILE',
    method: 'DELETE',
    endpoint: '/specifications/' + specification_id + '/file',
    data: {
      category,
      attachment_id,
    },
  });
};

export const uploadSpecificationImages = (
  specification_id,
  category,
  images
) => {
  return store.dispatch({
    action: 'table.working_set_specifications/UPLOAD_SPECIFICATION_IMAGES',
    method: 'POST',
    endpoint: '/specifications/' + specification_id + '/images',
    data: {
      images,
      category,
    },
  });
};

export const deleteSpecificationImage = (
  specification_id,
  category,
  attachment_id
) => {
  return store.dispatch({
    action: 'table.working_set_specifications/DELETE_SPECIFICATION_IMAGE',
    method: 'DELETE',
    endpoint: '/specifications/' + specification_id + '/image',
    data: {
      category,
      attachment_id,
    },
  });
};

export const reorderSpecificationImages = (
  specification_id,
  category,
  images
) => {
  return store.dispatch({
    action: 'table.working_set_specifications/REORDER_SPECIFICATION_IMAGES',
    method: 'PUT',
    endpoint: '/specifications/' + specification_id + '/images/order',
    data: {
      category,
      images,
    },
  });
};

export const deleteSpecificationProductNote = (specification_id, note_id) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/DELETE_SPECIFICATION_PRODUCT_NOTE',
    method: 'DELETE',
    endpoint: '/specifications/' + specification_id + '/notes',
    data: {
      note_id,
    },
  });
};

export const reorderSpecificationProductNotes = (specification_id, notes) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/REORDER_SPECIFICATION_PRODUCT_NOTES',
    method: 'PUT',
    endpoint: '/specifications/' + specification_id + '/notes/order',
    data: {
      notes,
    },
  });
};

export const addSpecificationProductNote = (specification_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/ADD_SPECIFICATION_NOTE',
    method: 'POST',
    endpoint: '/specifications/' + specification_id + '/notes',
    data: formData,
  });
};

export const editSpecificationProductNote = (specification_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/EDIT_SPECIFICATION_NOTE',
    method: 'PUT',
    endpoint: '/specifications/' + specification_id + '/notes',
    data: formData,
  });
};

export const addSpecificationProductAttributeGroup = (
  specification_id,
  formData
) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/ADD_SPECIFICATION_PRODUCT_ATTRIBUTE_GROUP',
    method: 'POST',
    endpoint: '/specifications/' + specification_id + '/attribute_groups',
    data: formData,
  });
};

export const updateSpecificationProductAttributesGroupFields = (
  specification_product_attribute_group_id,
  formData
) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/UPDATE_SPECIFICATION_PRODUCT_ATTRIBUTES_GROUP_FIELDS',
    method: 'PUT',
    endpoint:
      '/specifications/attributes/groups/' +
      specification_product_attribute_group_id +
      '/fields',
    data: formData,
  });
};

export const deleteSpecificationProductAttributeField = (
  specification_product_attribute_field_id
) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/DELETE_SPECIFICATION_PRODUCT_ATTRIBUTE_FIELD',
    method: 'DELETE',
    endpoint:
      '/specifications/attributes/' + specification_product_attribute_field_id,
  });
};

export const addSpecificationProductAttributeGroupField = (
  specification_product_attribute_group_id,
  formData
) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/ADD_SPECIFICATION_PRODUCT_ATTRIBUTE_GROUP_FIELD',
    method: 'POST',
    endpoint:
      '/specifications/attributes/groups/' +
      specification_product_attribute_group_id +
      '/fields',
    data: formData,
  });
};

export const updateSpecificationProductAttributeGroupField = (
  specification_product_attribute_field_id,
  formData
) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/UPDATE_SPECIFICATION_PRODUCT_ATTRIBUTE_GROUP_FIELD',
    method: 'PUT',
    endpoint:
      '/specifications/attributes/' + specification_product_attribute_field_id,
    data: formData,
  });
};

export const readSpecificationProductAttributesGroup = (
  specification_product_attribute_group_id
) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/READ_SPECIFICATION_PRODUCT_ATTRIBUTES_GROUP',
    method: 'GET',
    endpoint:
      '/specifications/additional_attributes/groups/' +
      specification_product_attribute_group_id,
  });
};

export const deleteSpecificationProductAttributesGroup = (
  specification_product_attribute_group_id
) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/DELETE_SPECIFICATION_PRODUCT_ATTRIBUTES_GROUP',
    method: 'DELETE',
    endpoint:
      '/specifications/attributes/groups/' +
      specification_product_attribute_group_id,
  });
};

export const updateSpecificationProductAttributesGroup = (
  specification_product_attribute_group_id,
  formData
) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/UPDATE_SPECIFICATION_PRODUCT_ATTRIBUTES_GROUP',
    method: 'PUT',
    endpoint:
      '/specifications/attributes/groups/' +
      specification_product_attribute_group_id,
    data: formData,
  });
};

export const updateSpecificationPrice = (specification_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/UPDATE_SPECIFICATION_PRICE',
    method: 'PUT',
    endpoint: '/specifications/' + specification_id + '/price',
    data: formData,
  });
};

export const updateSpecificationProjectNote = (specification_id, formData) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/UPDATE_SPECIFICATION_PROJECT_NOTE',
    method: 'PUT',
    endpoint: '/specifications/' + specification_id + '/project_note',
    data: formData,
  });
};

export const updateSpecificationTags = (specification_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/UPDATE_SPECIFICATION_TAGS',
    method: 'PUT',
    endpoint: '/specifications/' + specification_id + '/tags',
    data: formData,
  });
};

export const updateSpecificationToolbar = (specification_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/UPDATE_SPECIFICATION_TOOLBAR',
    method: 'PUT',
    endpoint: '/specifications/' + specification_id + '/toolbar',
    data: formData,
  });
};

export const updateSpecificationBasicData = (specification_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/UPDATE_SPECIFICATION_BASIC_DATA',
    method: 'PUT',
    endpoint: '/specifications/' + specification_id + '/basic',
    data: formData,
  });
};

export const updateSpecificationCode = (specification_code_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/UPDATE_SPECIFICATION_CODE',
    method: 'PUT',
    endpoint: '/specification_codes/' + specification_code_id,
    data: formData,
  });
};

export const listSpecificationDeliveryApprovers = (team_id) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/LIST_SPECIFICATION_DELIVERY_APPROVERS',
    method: 'GET',
    endpoint: '/teams/' + team_id + '/specification_delivery_approvers',
  });
};

export const createSpecificationDelivery = (team_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/CREATE_SPECIFICATION_DELIVERY',
    method: 'POST',
    endpoint: '/teams/' + team_id + '/specification_deliveries',
    data: formData,
  });
};

export const addToExistingSpecificationDelivery = (
  specification_delivery_id,
  formData
) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/ADD_TO_EXISTING_SPECIFICATION_DELIVERY',
    method: 'PUT',
    endpoint:
      '/specification_deliveries/' +
      specification_delivery_id +
      '/specifications',
    data: formData,
  });
};

export const listExistingSpecificationDeliveries = (team_id) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/LIST_EXISTING_SPECIFICATION_DELIVERIES',
    method: 'GET',
    endpoint: '/teams/' + team_id + '/existing_specification_deliveries',
  });
};

export const updateSpecificationRevisionDescription = (
  specification_id,
  description
) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/UPDATE_SPECIFICATION_REVISION_DESCRIPTION',
    method: 'PUT',
    endpoint: '/specifications/' + specification_id + '/revision_description',
    data: {
      description,
    },
  });
};

export const uploadSpecificationDrawing = (specification_id, letter, file) => {
  return store.dispatch({
    action: 'table.working_set_specifications/UPLOAD_SPECIFICATION_DRAWING',
    method: 'POST',
    endpoint: '/specifications/' + specification_id + '/drawings',
    data: {
      letter,
      file,
    },
  });
};

export const deleteSpecificationDrawing = (specification_drawing_id) => {
  return store.dispatch({
    action: 'table.working_set_specifications/DELETE_SPECIFICATION_DRAWING',
    method: 'DELETE',
    endpoint: '/specification_drawings/' + specification_drawing_id,
    data: {
      specification_drawing_id,
    },
  });
};

export const updateSpecificationDrawingLetter = (
  specification_drawing_id,
  letter
) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/UPDATE_SPECIFICATION_DRAWING_LETTER',
    method: 'PUT',
    endpoint: '/specification_drawings/' + specification_drawing_id,
    data: {
      letter,
    },
  });
};

export const setMainSpecification = (specification_id) => {
  return store.dispatch({
    action: 'table.working_set_specifications/SET_MAIN_SPECIFICATION',
    method: 'PUT',
    endpoint: '/specifications/' + specification_id + '/main',
  });
};

export const listPossibleLinkedSpecificationCodes = (specification_code_id) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/LIST_POSSIBLE_LINKED_SPECIFICATION_CODES',
    method: 'GET',
    endpoint:
      '/specification_codes/' +
      specification_code_id +
      '/possible_linked_specification_codes',
  });
};

export const listPossibleLinkedSpecifications = (specification_code_ids) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/LIST_POSSIBLE_LINKED_SPECIFICATIONS',
    method: 'POST',
    endpoint: '/specification_codes/possible_linked_specifications',
    data: {
      specification_code_ids,
    },
  });
};

export const addLinkedSpecificationCode = (specification_code_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/ADD_LINKED_SPECIFICATION_CODE',
    method: 'POST',
    endpoint:
      '/specification_codes/' +
      specification_code_id +
      '/linked_specification_codes',
    data: formData,
  });
};

export const deleteLinkedSpecificationCode = (
  specification_code_id,
  formData
) => {
  return store.dispatch({
    action: 'table.working_set_specifications/DELETE_LINKED_SPECIFICATION_CODE',
    method: 'DELETE',
    endpoint:
      '/specification_codes/' +
      specification_code_id +
      '/linked_specification_codes',
    data: formData,
  });
};

export const updateLinkedSpecificationCode = (
  specification_code_id,
  formData
) => {
  return store.dispatch({
    action: 'table.working_set_specifications/UPDATE_LINKED_SPECIFICATION_CODE',
    method: 'PUT',
    endpoint:
      '/specification_codes/' +
      specification_code_id +
      '/linked_specification_codes',
    data: formData,
  });
};

export const reorderSpecificationProductAttributeGroups = (
  specification_id,
  groups
) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/REORDER_SPECIFICATION/PRODUCT_ATTRIBUTE_GROUPS',
    method: 'PUT',
    endpoint: '/specifications/' + specification_id + '/attribute_groups/order',
    data: {
      groups,
    },
  });
};

export const reorderSpecificationProductAttributeFields = (
  specification_product_attribute_group_id,
  fields
) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/REORDER_SPECIFICATION_PRODUCT_ATTRIBUTE_FIELDS',
    method: 'PUT',
    endpoint:
      '/specifications/attributes/groups/' +
      specification_product_attribute_group_id +
      '/fields/order',
    data: {
      fields,
    },
  });
};

export const listMyCompanySpecificationAttributeTemplateGroups = () => {
  return store.dispatch({
    action:
      'table.working_set_specifications/LIST_MY_COMPANY_SPECIFICATION_ATTRIBUTE_TEMPLATE_GROUPS',
    method: 'GET',
    endpoint: '/companies/my/specification_attribute_template_groups',
  });
};

export const listSpecificationProductAttributeGroupFields = (
  specification_product_attribute_group_id
) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/LIST_SPECIFICATION_PRODUCT_ATTRIBUTE_GROUP_FIELDS',
    method: 'GET',
    endpoint:
      '/specifications/attributes/groups/' +
      specification_product_attribute_group_id +
      '/fields',
  });
};

export const saveSpecificationProductAttributeGroupAsTemplate = (
  specification_product_attribute_group_id,
  formData
) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/SAVE_SPECIFICATION_PRODUCT_ATTRIBUTE_GROUP_AS_TEMPLATE',
    method: 'POST',
    endpoint:
      '/specifications/attributes/groups/' +
      specification_product_attribute_group_id +
      '/template',
    data: formData,
  });
};

export const listPossibleSpecificationNotes = (specification_id) => {
  return store.dispatch({
    action: 'table.products/LIST_POSSIBLE_SPECIFICATION_NOTES',
    method: 'GET',
    endpoint:
      '/specifications/' + specification_id + '/possible_specification_notes',
  });
};

export const listNoteSpecificationCodes = (stage) => {
  return store.dispatch({
    action: 'table.products/LIST_NOTE_SPECIFICATIONS',
    method: 'GET',
    endpoint: '/specifications/' + stage + '/note_specification_codes',
  });
};

export const listPossibleSpecificationResponsibleUsers = (
  stage_id,
  user_id
) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/LIST_POSSIBLE_SPECIFICATION_RESPONSIBLE_USERS',
    method: 'POST',
    endpoint:
      '/stages/' + stage_id + '/possible_specification_responsible_users',
    data: {
      user_id,
    },
  });
};

export const printWorkingSetSpecifications = (
  stage_id,
  specification_ids,
  formData
) => {
  return store.dispatch({
    action: 'table.working_set_specifications/PRINT_WORKING_SET_SPECIFICATIONS',
    method: 'POST',
    endpoint: '/stages/' + stage_id + '/specifications/working/print',
    data: {
      ...formData,
      specifications: specification_ids,
    },
  });
};

export const copySpecification = (specification_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/COPY_SPECIFICATION',
    method: 'POST',
    endpoint: '/specifications/' + specification_id + '/copy',
    data: formData,
  });
};

export const copySpecifications = (formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/COPY_SPECIFICATIONS',
    method: 'POST',
    endpoint: '/specifications/copy',
    data: formData,
  });
};

export const validateCopySpecificationDetails = (formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/COPY_SPECIFICATION_DETAILS',
    method: 'POST',
    endpoint: '/specifications/copy/validate',
    data: formData,
  });
};

export const multipleEditSpecificationTags = (specification_ids, tags) => {
  return store.dispatch({
    action: 'table.working_set_specifications/MULTIPLE_EDIT_SPECIFICATION_TAGS',
    method: 'PUT',
    endpoint: '/specifications/tags',
    data: {
      tags,
      specifications: specification_ids,
    },
  });
};

export const updateSpecificationReadyForDelivery = (specification_id, data) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/UPDATE_SPECIFICATION_READY_FOR_DELIVERY',
    method: 'PUT',
    endpoint: '/specifications/' + specification_id + '/ready_for_delivery',
    data: data,
  });
};

export const deleteWSSpecification = (specification_id) => {
  return store.dispatch({
    action: 'table.working_set_specifications/DELETE_WS_SPECIFICATION',
    method: 'DELETE',
    endpoint: '/specifications/' + specification_id,
  });
};

export const getCountOfSpecifications = (stage_id, data) => {
  return store.dispatch({
    action:
      'table.working_set_specifications/UPDATE_SPECIFICATION_READY_FOR_DELIVERY',
    method: 'PUT',
    endpoint: '/specifications/' + stage_id + '/count',
    data: data,
  });
};

export const updateSpecificationQuantity = (specification_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/UPDATE_SPECIFICATION_QUANTITY',
    method: 'PUT',
    endpoint: '/specifications/' + specification_id + '/quantity',
    data: formData,
  });
};
