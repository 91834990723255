import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/MoveMeetingItemWizard.scss';
import autobind from 'autobind-decorator';
import { __, mapStateToProps, setTableParams } from '../core/utils';
import MoveIcon from '../assets/images/move-64x64.svg';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual';
import Localization from '../helpers/Localization';
import _ from 'lodash';
import {
  readMeetingItems,
  reorderMeetingItems,
} from '../redux/actions/table/all_meetings';
import MoveSelectedMeetingItemsForm from '../forms/move_selected_meeting_items_wizard/MoveMeetingItemForm';

@mapStateToProps((state) => ({
  sorted_items: _.get(
    state.table,
    'all_meetings.move_selected_meeting_items_wizard.sorted_items'
  ),
  selected_rows: _.get(
    state.table,
    'all_meetings.move_selected_meeting_items_wizard.selected_rows'
  ),
  store: state.table['all_meetings'],
}))
@subscribe(Localization, 'localization')
@withRouter
class MoveSelectedMeetingItemsWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
    };
  }

  @autobind
  _handleSubmit(formData) {
    const { sorted_items, selected_rows, match } = this.props;
    const meeting_id = match.params.meeting;

    let options = _.map(sorted_items, 'id');
    let movingItems = [];

    // Collect the indices of selected rows
    let selectedIndices = selected_rows
      .map((id) => _.indexOf(options, id))
      .sort((a, b) => a - b);

    // Remove the selected items from the options array
    selectedIndices.reverse().forEach((index) => {
      movingItems.unshift(options.splice(index, 1)[0]);
    });

    const targetIndex = _.indexOf(options, formData.selected_item_id);

    const insertionIndex =
      targetIndex +
      (formData.move_action === 'before' ? 0 : 1) -
      (selectedIndices[0] < targetIndex ? movingItems.length : 0);

    // Insert selected rows at the desired position
    options.splice(insertionIndex, 0, ...movingItems);

    reorderMeetingItems(meeting_id, options).then(() => {
      setTableParams('all_meetings', {
        forceUpdate: true,
      });

      this.props.onClose();
    });
  }

  render() {
    const { sorted_items } = this.props;

    const options = _.map(sorted_items, (item) => {
      return {
        value: item.id,
        label:
          item.type == 'task'
            ? '—\u00A0' + item.task_title
            : item.type == 'topic'
            ? '—\u00A0' + item.title
            : item.title,
        blackMarkedValue: item.type === 'group',
      };
    });

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>{__('meeting.items.move-selected-items.title')}</span>
            <span>{__('meeting.items.move-selected-items.description')}</span>
          </div>
          <div className={styles.right}>
            <MoveIcon />
          </div>
        </div>
        <div className={styles.body}>
          <MoveSelectedMeetingItemsForm
            onSubmit={this._handleSubmit}
            initialValues={{
              move_action: 'after',
            }}
            options={options}
          />
        </div>
      </>
    );
  }
}

export default MoveSelectedMeetingItemsWizard;
