import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/add_linked_specification_wizard/AddLinkedSpecificationForm.scss';
import Button from '../../components/Button';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import { listPossibleLinkedSpecificationCodes } from '../../redux/actions/table/working_set_specifications';
import * as _ from 'lodash';
import Input from '../../components/Input';
import RadioGroup from '../../components/RadioGroup';

@withRouter
@subscribe(Localization, 'localization')
@reduxForm({ form: 'add_linked_specification_wizard.add_linked_specification' })
@mapStateToProps((state) => ({
  store: _.get(state.table, 'working_set_specifications'),
}))
class AddLinkedSpecificationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possible_linked_specifications: [],
    };
  }

  componentDidMount() {
    listPossibleLinkedSpecificationCodes(
      this.props.store.add_linked_specification_wizard
    ).then(({ response }) => {
      this.setState({ possible_linked_specifications: response.data });
    });
  }

  render() {
    const { store, project_language } = this.props;
    const { specification_groups, specification_subgroups } =
      this.props.localization;

    const possible_linked_specifications = _.map(
      this.state.possible_linked_specifications,
      (specification) => {
        const specification_subgroup =
          _.get(specification_subgroups, [
            specification.specification_subgroup_id,
          ]) || {};
        const specification_group =
          _.get(
            specification_groups,
            _.get(specification_subgroup, 'specification_group_id')
          ) || {};

        const code =
          getLocalized(specification_group.code, project_language) +
          specification_subgroup.code +
          _.padStart(specification.code, 2, '0');
        const title = _.filter(
          [
            _.get(specification.description, store.language_id),
            specification.model,
            specification.brand,
          ],
          _.identity
        ).join(' / ');

        return {
          value: specification.id,
          label: _.filter([code, title], _.identity).join(': '),
        };
      }
    );

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.form}>
          <Field
            name='specification_code_id'
            component={Select}
            searchable
            label={__(
              'specifications.working-set.flyout.add-linked-specification.specification'
            )}
            options={possible_linked_specifications}
          />
          <Field
            name='description'
            label={__(
              'specifications.working-set.flyout.add-linked-specification.description'
            )}
            component={Input}
          />
          {/*<div className={styles.flex}>*/}
          {/*    <div className={styles.f1}>*/}
          {/*        <Field name="quantity" label={__('specifications.working-set.add-linked-specification.quantity')} component={Input} numeric decimal />*/}
          {/*    </div>*/}
          {/*    <div className={styles.f1}>*/}
          {/*        <div className={styles.label}>{__('specifications.working-set.add-linked-specification.included-in-price')}</div>*/}
          {/*        <Field name="included_in_price" component={RadioGroup} options={[*/}
          {/*            {*/}
          {/*                label: __('specifications.working-set.add-linked-specification.included-in-price.no'),*/}
          {/*                value: '0',*/}
          {/*            }, {*/}
          {/*                label: __('specifications.working-set.add-linked-specification.included-in-price.yes'),*/}
          {/*                value: '1',*/}
          {/*            }*/}
          {/*        ]} />*/}
          {/*    </div>*/}
          {/*</div>*/}
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('working_set_specifications', {
                add_linked_specification_wizard: undefined,
              })
            }
          />
          <Button
            lightBlue
            medium
            right
            submit
            middleText={__('button.add')}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default AddLinkedSpecificationForm;
