import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import Table from '../../components/Table';
import { connect } from 'react-redux';
import { createSidebar } from '../../redux/actions/general/sidebar';
import { setPlusActions } from '../../redux/actions/general/plus';
import {
  activateProjects,
  deactivateProjects,
  listAllProjects,
} from '../../redux/actions/table/all_projects';
import { __, dateFrom, redirect } from '../../core/utils';
import { listActiveModules } from '../../redux/actions/general/active_modules';
import Tooltip from '../../components/Tooltip';
import * as _ from 'lodash';
import { setNotification } from '../../redux/actions/general/notification';
import DeactivateIcon from '../../assets/images/deactivate-15x15.svg';
import ActivateIcon from '../../assets/images/activate-15x15.svg';
import styles from '../../styles/views/projects/AllProjects.scss';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';

const mapStateToProps = (state) => {
  return {
    table: state.table['all_projects'],
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.all_projects/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class AllProjects extends Component {
  componentDidMount() {
    createSidebar({
      title: __('submenu.title.projects'),
      items: [
        {
          title: __('submenu.title.all-projects'),
          link: () => '/projects',
          active: true,
        },
        {
          title: __('submenu.title.shared-project-models'),
          link: () => '/projects/shared',
          active: false,
        },
      ],
    });

    (!this.props.auth.company ||
      _.includes(['administrator', 'owner'], this.props.auth.role)) &&
      setPlusActions([
        {
          title: __('button.create-new-project'),
          onClick: () =>
            this.props.actions.setTableParams({
              wizard_active: true,
            }),
        },
      ]);
  }

  _handleOpacity(value, row) {
    return !row.deactivated ? (
      value
    ) : (
      <div className={styles.hidden}>{value}</div>
    );
  }

  render() {
    const columns = [
      {
        key: 'code',
        name: __('table.columns.project-number'),
        width: 80,
        value: (value, row) => this._handleOpacity(value, row),
      },
      {
        key: 'name',
        name: __('table.columns.project-title'),
        required: true,
        default: true,
        value: (value, row) => this._handleOpacity(value, row),
      },
      {
        key: 'description',
        name: __('table.columns.project-scope'),
        sortable: false,
        value: (value, row) => this._handleOpacity(value, row),
      },
      {
        key: 'current_stage',
        name: __('table.columns.current-stage'),
        sortable: false,
        value: (current_stage, row) =>
          this._handleOpacity(
            current_stage.order + ' - ' + current_stage.name,
            row
          ),
      },
      {
        key: 'company',
        name: __('table.columns.company'),
        value: (value, row) => this._handleOpacity(value, row),
      },
      {
        key: 'created_at',
        name: __('table.columns.created'),
        value: (value, row) => this._handleOpacity(dateFrom(value), row),
        width: 80,
      },
      {
        key: 'updated_at',
        name: __('table.columns.modified'),
        value: (value, row) => this._handleOpacity(dateFrom(value), row),
        width: 80,
      },
    ];

    const gridOptions = {
      section: __('submenu.title.all-projects'),
      image: (id, row) => row.image_url,
      title: (row) => row.name,
      subTitle: (row) => row.description,
      content: (row) => [
        'created ' + dateFrom(row.created_at) + ' by ' + row.company,
      ],
    };

    const singleActions = (project_id) => {
      const deactivated = _.get(
        _.find(this.props.table.data, { id: project_id }),
        'deactivated'
      );
      const can_administrate = _.get(
        _.find(this.props.table.data, { id: project_id }),
        'can_administrate'
      );

      if (can_administrate) {
        return deactivated
          ? [
              <Tooltip
                text={__(
                  'project-list.all-projects.select-all-items.tooltip.activate-project'
                )}
                key={_.uniqueId()}
              >
                <ActivateIcon
                  onClick={(e) => {
                    e.stopPropagation();

                    activateProjects([project_id]).then(() => {
                      setNotification(
                        __('The selected project has been activated.')
                      );
                      listAllProjects();

                      this.props.actions.setTableParams({
                        actioned_row: undefined,
                      });
                    });
                  }}
                />
              </Tooltip>,
            ]
          : [
              <Tooltip
                text={__(
                  'project-list.all-projects.select-all-items.tooltip.deactivate-project'
                )}
                key={_.uniqueId()}
              >
                <DeactivateIcon
                  onClick={(e) => {
                    e.stopPropagation();

                    showAlertbox({
                      color: 'red',
                      title: __('general.alert.are-you-sure'),
                      description:
                        'Do you really want to deactivate the selected project?\n\nThe project will not be accessible anymore.',
                      buttons: [
                        {
                          color: 'lightGray',
                          text: __('general.alert.no-close'),
                          onClick: 'close',
                        },
                        {
                          color: 'gray',
                          text: 'Yes, Deactivate',
                          onClick: () => {
                            deactivateProjects([project_id]).then(() => {
                              hideAlertbox();

                              setNotification(
                                __('The selected project has been deactivated.')
                              );
                              listAllProjects();

                              this.props.actions.setTableParams({
                                actioned_row: undefined,
                              });
                            });
                          },
                        },
                      ],
                    });
                  }}
                />
              </Tooltip>,
            ];
      } else {
        return [];
      }
    };

    const groupActions = (project_ids) => [
      <Tooltip
        text={__(
          'project-list.all-projects.select-all-items.tooltip.activate-projects'
        )}
        key={_.uniqueId()}
      >
        <ActivateIcon
          onClick={(e) => {
            e.stopPropagation();

            if (
              _.size(
                _.filter(
                  this.props.table.data,
                  (project) =>
                    _.includes(project_ids, project.id) &&
                    !project.can_administrate
                )
              ) >= 1
            ) {
              setNotification({
                text: __(
                  "You can not activate the selected projects, as you don't have permission to do so on one or more projects."
                ),
                type: 'warning',
              });
            } else {
              activateProjects(project_ids).then(() => {
                setNotification(
                  __('The selected projects have been activated.')
                );
                listAllProjects();

                this.props.actions.setTableParams({
                  selected_rows: undefined,
                });
              });
            }
          }}
        />
      </Tooltip>,
      <Tooltip
        text={__(
          'project-list.all-projects.select-all-items.tooltip.deactivate-projects'
        )}
        key={_.uniqueId()}
      >
        <DeactivateIcon
          onClick={(e) => {
            e.stopPropagation();

            if (
              _.size(
                _.filter(
                  this.props.table.data,
                  (project) =>
                    _.includes(project_ids, project.id) &&
                    !project.can_administrate
                )
              ) >= 1
            ) {
              setNotification({
                text: __(
                  "You can not deactivate the selected projects, as you don't have permission to do so on one or more projects."
                ),
                type: 'warning',
              });
            } else {
              showAlertbox({
                color: 'red',
                title: __('general.alert.are-you-sure'),
                description:
                  'Do you really want to deactivate the selected projects?\n\nProjects will not be accessible anymore.',
                buttons: [
                  {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close',
                  },
                  {
                    color: 'gray',
                    text: 'Yes, Deactivate',
                    onClick: () => {
                      deactivateProjects(project_ids).then(() => {
                        hideAlertbox();

                        setNotification(
                          __('The selected projects have been deactivated.')
                        );
                        listAllProjects();

                        this.props.actions.setTableParams({
                          selected_rows: undefined,
                        });
                      });
                    },
                  },
                ],
              });
            }
          }}
        />
      </Tooltip>,
    ];

    return (
      <Table
        listAction={listAllProjects}
        onRowClick={(id) => {
          const deactivated = _.get(
            _.find(this.props.table.data, { id }),
            'deactivated'
          );

          if (!deactivated) {
            redirect('/projects/' + id + '/stages');
          }
        }}
        name='all_projects'
        title={__('submenu.title.all-projaaaects')}
        columns={columns}
        singleActions={singleActions}
        groupActions={groupActions}
        gridOptions={gridOptions}
        defaultView='grid'
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default AllProjects;
