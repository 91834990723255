import * as React from 'react';
import { Component } from 'react';
import Table from '../../components/Table';
import { connect } from 'react-redux';
import { createSidebar } from '../../redux/actions/general/sidebar';
import { __, redirect, setURLParam } from '../../core/utils';
import { listActiveModules } from '../../redux/actions/general/active_modules';
import * as _ from 'lodash';
import { listAllSharedModels } from '../../redux/actions/table/all_shared_models';

const mapStateToProps = (state) => {
  return {
    table: state.table['all_shared_models'],
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.all_shared_models/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class AllSharedModels extends Component {
  componentDidMount() {
    createSidebar({
      title: __('submenu.title.all-shared-models'),
      items: [
        {
          title: __('submenu.title.all-shared-models'),
          link: () => '/shared_with_me',
          active: true,
        },
      ],
    });
  }

  render() {
    const columns = [
      {
        key: 'project',
        name: __('table.all-shared-models.columns.project'),
        required: true,
        default: true,
        value: (value, row) => value,
      },
      {
        key: 'stage',
        name: __('table.all-shared-models..columns.stage'),
        required: true,
        default: true,
        value: (value, row) => value,
      },
      {
        key: 'identifier',
        name: __('table.all-shared-models..columns.identifier'),
        width: 80,
        value: (value, row) => value,
      },
      {
        key: 'name',
        name: __('table.all-shared-models.columns.name'),
        required: true,
        default: true,
        value: (value, row) => value,
      },
    ];

    // const gridOptions = {
    //   section: __('submenu.title.all-projects'),
    //   image: (id, row) => row.image_url,
    //   title: (row) => row.name,
    //   subTitle: (row) => row.description,
    //   content: (row) => [
    //     'created ' + dateFrom(row.created_at) + ' by ' + row.company,
    //   ],
    // };

    return (
      <Table
        listAction={() => listAllSharedModels()}
        onRowClick={(id, row) => {
          redirect(row.url);
        }}
        name='all_shared_models'
        title={__('submenu.title.all-shared-models')}
        columns={columns}
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default AllSharedModels;
