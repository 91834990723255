import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { enableAutosave } from '../../redux/actions/general/autosave';
import styles from '../../styles/forms/working_set_specifications/BasicDataForm.scss';
import Input from '../../components/Input';
import {
  __,
  getActiveStage,
  getLocalized,
  mapStateToProps,
  setTableParams,
  transformToListPairs,
  redirect,
} from '../../core/utils';
import classNames from 'classnames';
import Image from '../../components/Image';
import DefaultImage from '../../assets/images/default.png';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import Tooltip from '../../components/Tooltip';
import PhotoIcon from '../../assets/images/photo-15x15.svg';
import EditIcon from '../../assets/images/edit-16x16.svg';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import { setNotification } from '../../redux/actions/general/notification';
import autobind from 'autobind-decorator';
import * as _ from 'lodash';
import { showLightbox } from '../../redux/actions/general/lightbox';
import {
  deleteSpecificationThumbnail,
  listPossibleResponsibleSpecificationTeams,
  listPossibleSpecificationResponsibleUsers,
  listWorkingSetSpecifications,
  readWorkingSetSpecification,
  uploadSpecificationThumbnails,
} from '../../redux/actions/table/working_set_specifications';
import Select from '../../components/Select';
import {
  createAddressBookCompany,
  listPossibleAddressBookManufacturers,
  listPossibleAddressBookSuppliers,
} from '../../redux/actions/table/address_book_companies';
import PeopleChooser from '../../components/PeopleChooser';
import { createAddressBookContact } from '../../redux/actions/table/address_book_contacts';

@reduxForm({
  form: 'working_set_specifications.basic_data',
  enableReinitialize: true,
})
@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  store: state.table.working_set_specifications,
  values: getFormValues('working_set_specifications.basic_data')(state),
  application_language_id: state.auth.language_id,
}))
class BasicDataForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possible_address_book_manufacturers: [],
      possible_address_book_suppliers: [],
      possible_specification_responsible_users: [],
      possible_specification_responsible_teams: [],
    };
  }

  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  @autobind
  _handleUploadThumbnail(e) {
    const specification_id = this.props.store.flyout.id;

    if (e.target.files) {
      setNotification(
        __(
          'working-set-specifications.basic-data-form.thumbnail-image-uploaded-wait'
        )
      );

      setNotification(
        __('working-set-specifications.basic-data-form.wait-image-uploaded')
      );

      uploadSpecificationThumbnails(specification_id, e.target.files)
        .then(() => {
          readWorkingSetSpecification(specification_id).then(({ response }) => {
            setTableParams('working_set_specifications', {
              flyout: response.data,
            });
          });

          listWorkingSetSpecifications(getActiveStage());
        })
        .catch(() => {
          setNotification({
            text: __(
              'working-set-specifications.basic-data-form.thumbnail-image-not-uploaded.double-check-file-formats-sizes'
            ),
            type: 'warning',
          });
        });
    }
  }

  componentDidMount() {
    const { flyout } = this.props.store;

    if (!flyout.product_id) {
      listPossibleAddressBookManufacturers(
        flyout.manufacturer_company_id || undefined
      ).then(({ response }) => {
        this.setState({ possible_address_book_manufacturers: response.data });
      });
    }

    listPossibleAddressBookSuppliers(
      flyout.supplier_company_id || undefined
    ).then(({ response }) => {
      this.setState({ possible_address_book_suppliers: response.data });
    });

    !flyout.is_shared &&
      listPossibleSpecificationResponsibleUsers(
        flyout.stage_id,
        flyout.responsible_user_id
      ).then(({ response }) => {
        this.setState({
          possible_specification_responsible_users: response.data,
        });
      });

    listPossibleResponsibleSpecificationTeams(flyout.stage_id).then(
      ({ response }) => {
        this.setState({
          possible_specification_responsible_teams: _.map(
            response.data,
            (team) => ({
              value: team.id,
              label:
                team.company.name +
                ' [' +
                [
                  team.stage_discipline.code,
                  team.stage_discipline.discipline_id == null
                    ? team.stage_discipline.description + '*'
                    : _.get(this.props.localization, [
                        'disciplines',
                        team.stage_discipline.discipline_id,
                        'name',
                      ]),
                ].join(': ') +
                ']',
            })
          ),
        });
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { flyout } = this.props.store;

    if (flyout.id != prevProps.store.flyout.id) {
      if (!flyout.product_id) {
        listPossibleAddressBookManufacturers(
          flyout.manufacturer_company_id || undefined
        ).then(({ response }) => {
          this.setState({ possible_address_book_manufacturers: response.data });
        });
      }

      listPossibleAddressBookSuppliers(
        flyout.supplier_company_id || undefined
      ).then(({ response }) => {
        this.setState({ possible_address_book_suppliers: response.data });
      });

      listPossibleResponsibleSpecificationTeams(flyout.stage_id).then(
        ({ response }) => {
          this.setState({
            possible_specification_responsible_teams: _.map(
              response.data,
              (team) => ({
                value: team.id,
                label:
                  team.company.name +
                  ' [' +
                  [
                    team.stage_discipline.code,
                    team.stage_discipline.discipline_id == null
                      ? team.stage_discipline.description + '*'
                      : _.get(this.props.localization, [
                          'disciplines',
                          team.stage_discipline.discipline_id,
                          'name',
                        ]),
                  ].join(': ') +
                  ']',
              })
            ),
          });
        }
      );
    }

    if (
      flyout.supplier_company_id !=
        prevProps.store.flyout.supplier_company_id &&
      flyout.supplier_contact_id
    ) {
      const selected_address_book_supplier_company =
        _.find(this.state.possible_address_book_suppliers, [
          'id',
          _.get(this.props.values, 'supplier_company_id'),
        ]) || {};

      if (
        !_.find(selected_address_book_supplier_company.contacts, [
          'id',
          flyout.supplier_contact_id,
        ])
      ) {
        this.props.change('supplier_contact_id', null);
      }
    }
  }

  @autobind
  _createNewManufacturer(value) {
    const { flyout } = this.props.store;

    createAddressBookCompany({ name: value, is_manufacturer: 1 }).then(
      ({ response }) => {
        listPossibleAddressBookManufacturers(flyout.manufacturer_company_id)
          .then(({ response }) => {
            this.setState({
              possible_address_book_manufacturers: response.data,
            });
          })
          .then(() => {
            this.props.change(
              'manufacturer_company_id',
              _.get(response.data, 'id')
            );

            this.props.handleSubmit();
          });
      }
    );
  }

  @autobind
  _createNewRepresentative(value) {
    const { flyout } = this.props.store;

    createAddressBookCompany({ name: value, is_supplier: 1 }).then(
      ({ response }) => {
        listPossibleAddressBookSuppliers(flyout.manufacturer_company_id)
          .then(({ response }) => {
            this.setState({ possible_address_book_suppliers: response.data });
          })
          .then(() => {
            this.props.change(
              'supplier_company_id',
              _.get(response.data, 'id')
            );

            this.props.handleSubmit();
          });
      }
    );
  }

  @autobind
  _createNewRepresentativeContact(value) {
    const { flyout } = this.props.store;
    const { supplier_company_id } = this.props.values;

    const firstname = value.split(' ')[0];
    const lastname = value.split(' ')[1] || value.split(' ')[0];

    createAddressBookContact({
      firstname,
      lastname,
      address_book_company_id: supplier_company_id,
    }).then(({ response }) => {
      listPossibleAddressBookSuppliers(flyout.manufacturer_company_id)
        .then(({ response }) => {
          this.setState({ possible_address_book_suppliers: response.data });
        })
        .then(() => {
          this.props.change('supplier_contact_id', _.get(response.data, 'id'));

          this.props.handleSubmit();
        });
    });
  }

  render() {
    const { language_id, flyout } = this.props.store;
    const { specification_groups, specification_subgroups } =
      this.props.localization;
    const specification_subgroup =
      _.get(specification_subgroups, [flyout.specification_subgroup_id]) || {};
    const specification_group =
      _.get(
        specification_groups,
        _.get(specification_subgroup, 'specification_group_id')
      ) || {};
    const specification_name = getLocalized(
      specification_group.name,
      this.props.application_language_id
    );
    const { handleSubmit, collapsed, readOnly } = this.props;
    const {
      possible_address_book_manufacturers,
      possible_address_book_suppliers,
      possible_specification_responsible_users,
      possible_specification_responsible_teams,
    } = this.state;

    const thumbnail_images = _.sortBy(
      _.filter(flyout.images, ['category', 'thumbnail']),
      'order'
    );

    const lightbox_images = _.map(thumbnail_images, (image) => ({
      id: image.id,
      stream_url: image.stream_url,
      title: [image.name, image.ext].join('.'),
    }));

    const selected_address_book_supplier_company =
      _.find(possible_address_book_suppliers, [
        'id',
        flyout.supplier_company_id,
      ]) || {};

    const disabled =
      _.includes(['canceled'], flyout.status) ||
      !_.isNull(flyout.product_id) ||
      readOnly;

    return (
      <Form
        onSubmit={handleSubmit}
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        <div className={styles.fields}>
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='code'
                component={Input}
                disabled={true}
                label={__('working-set-specifications.basic-data-form.code')}
                rightIcon={EditIcon}
                onRightIconClick={() =>
                  flyout.can_change_code &&
                  setTableParams('working_set_specifications', {
                    edit_specification_code_wizard: true,
                  })
                }
                rightIconTooltip={
                  flyout.can_change_code
                    ? __('specifications.working-set.flyout.edit-code')
                    : flyout.main
                    ? flyout.can_deliver
                      ? __(
                          'specifications.working-set.flyout.code-cannot-changed-specification-already-current set'
                        )
                      : __(
                          'specifications.working-set.flyout.code-cannot-changed-specification-delivery process'
                        )
                    : __(
                        'specifications.working-set.flyout.code-can-changed-only-main-specification-option'
                      )
                }
                rightIconDisabled={!flyout.can_change_code}
              />
            </div>
            <div className={styles.f1} />
            <div className={classNames(styles.f2, styles.specGroup)}>
              <span>
                {__('specifications.working-set.flyout.spec-group-name')}
              </span>
              <span>{specification_name}</span>
            </div>
          </div>
          <Field
            name={'description.' + language_id}
            component={Input}
            label={__('working-set-specifications.basic-data-form.description')}
            maxLength={70}
            disabled={disabled}
          />
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='model'
                component={Input}
                label={__('working-set-specifications.basic-data-form.model')}
                disabled={disabled}
              />
            </div>
            <div className={styles.f1}>
              {flyout.product_id ? (
                <Field
                  name='brand'
                  component={Input}
                  label={__(
                    'working-set-specifications.basic-data-form.manufacturer'
                  )}
                  disabled={true}
                />
              ) : (
                <Field
                  noneValue
                  disabled={disabled}
                  name='manufacturer_company_id'
                  component={Select}
                  searchable
                  label={__(
                    'working-set-specifications.basic-data-form.manufacturer'
                  )}
                  options={transformToListPairs(
                    possible_address_book_manufacturers
                  )}
                  callbackSetNewValue={(value) => {
                    this._createNewManufacturer(value);
                  }}
                  redirectTo={(id) => {
                    redirect(`/address_book/companies?id=${id}`);
                  }}
                />
              )}
            </div>
          </div>
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                noneValue
                name='supplier_company_id'
                disabled={disabled}
                component={Select}
                searchable
                label={__(
                  'working-set-specifications.basic-data-form.representative'
                )}
                options={transformToListPairs(possible_address_book_suppliers)}
                callbackSetNewValue={(value) => {
                  this._createNewRepresentative(value);
                }}
                redirectTo={(id) => {
                  redirect(`/address_book/companies?id=${id}`);
                }}
              />
            </div>
            <div className={styles.f1}>
              <Field
                noneValue
                disabled={
                  (this.props.values &&
                    !this.props.values.supplier_company_id) ||
                  disabled
                }
                name='supplier_contact_id'
                component={Select}
                searchable
                label={__(
                  'working-set-specifications.basic-data-form.representative-contact'
                )}
                options={transformToListPairs(
                  selected_address_book_supplier_company.contacts
                )}
                callbackSetNewValue={(value) => {
                  this._createNewRepresentativeContact(value);
                }}
                redirectTo={(id) => {
                  redirect(`/address_book/contacts?id=${id}`);
                }}
              />
            </div>
          </div>
          {!flyout?.is_shared && (
            <div className={styles.flex}>
              <div className={styles.f1}>
                <Field
                  name='created_by'
                  disabled={true}
                  component={PeopleChooser}
                  label={__(
                    'working-set-specifications.basic-data-form.created-by'
                  )}
                  options={[flyout.created_by]}
                />
              </div>
              <div className={styles.f1}>
                <Field
                  name='updated_by'
                  disabled={true}
                  component={PeopleChooser}
                  label={__(
                    'working-set-specifications.basic-data-form.last-modified-by'
                  )}
                  options={[flyout.updated_by]}
                />
              </div>
              <div className={styles.f2}>
                <Field
                  disabled={disabled}
                  name='responsible_user_id'
                  component={Select}
                  searchable
                  label={__(
                    'working-set-specifications.basic-data-form.responsible'
                  )}
                  options={transformToListPairs(
                    possible_specification_responsible_users
                  )}
                />
              </div>
              <div className={styles.f2}>
                <Field
                  disabled={disabled}
                  name='team_id'
                  searchable={true}
                  component={Select}
                  label={__(
                    'specifications.working-set.add-new.discipline-team'
                  )}
                  options={possible_specification_responsible_teams}
                />
              </div>
            </div>
          )}
        </div>
        <div className={classNames(styles.f1, styles.image)}>
          <Image default={DefaultImage} src={flyout.thumb_url} />
          <div
            className={classNames(
              styles.actions,
              !_.isEmpty(thumbnail_images) && styles.clickable
            )}
            onClick={() =>
              !_.isEmpty(thumbnail_images)
                ? showLightbox(lightbox_images, _.first(thumbnail_images).id)
                : undefined
            }
          >
            {_.isNull(flyout.product_id) && (
              <Tooltip
                text={__(
                  'working-set-specifications.basic-data-form.upload-thumbnail-image'
                )}
              >
                <PhotoIcon
                  onClick={(e) => {
                    this.refs.thumbnail.click();

                    e.stopPropagation();
                  }}
                />
              </Tooltip>
            )}
            {_.isNull(flyout.product_id) && (
              <input
                type='file'
                multiple={true}
                onClick={(e) => e.stopPropagation()}
                ref='thumbnail'
                onChange={this._handleUploadThumbnail}
                accept='image/x-png,image/jpeg'
              />
            )}
            {flyout.thumb_url && _.isNull(flyout.product_id) && (
              <>
                <div className={styles.separator} />
                <Tooltip
                  text={__(
                    'working-set-specifications.basic-data-form.delete-thumbnail-image'
                  )}
                >
                  <DeleteIcon
                    onClick={(e) => {
                      e.stopPropagation();

                      showAlertbox({
                        color: 'red',
                        title: __('general.alert.are-you-sure'),
                        description: __(
                          'working-set-specifications.basic-data-form.are-you-sure-remove-specification-thumbnail'
                        ),
                        buttons: [
                          {
                            color: 'lightGray',
                            text: __('general.alert.no-close'),
                            onClick: 'close',
                          },
                          {
                            color: 'gray',
                            text: __('general.alert.yes-delete'),
                            onClick: () => {
                              deleteSpecificationThumbnail(flyout.id).then(() =>
                                readWorkingSetSpecification(flyout.id).then(
                                  ({ response }) => {
                                    hideAlertbox();

                                    setNotification(
                                      __(
                                        'working-set-specifications.basic-data-form.specification-thumbnail-deleted'
                                      )
                                    );

                                    setTableParams(
                                      'working_set_specifications',
                                      {
                                        flyout: response.data,
                                      }
                                    );
                                  }
                                )
                              );
                            },
                          },
                        ],
                      });
                    }}
                  />
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </Form>
    );
  }
}

export default BasicDataForm;
