import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import styles from '../../styles/views/profile/Security.scss';
import { createSidebar } from '../../redux/actions/general/sidebar';
import {
  __,
  getLocalizedListValue,
  mapStateToProps,
  validateForm,
} from '../../core/utils';
import { listActiveModules } from '../../redux/actions/general/active_modules';
import {
  disableTwoFactorAuthentication,
  enableTwoFactorAuthentication,
  readProfile,
  updatePassword,
} from '../../redux/actions/profile';
import autobind from 'autobind-decorator';
import ChangePasswordForm from '../../forms/profile/ChangePasswordForm';
import { setNotification } from '../../redux/actions/general/notification';
import Button from '../../components/Button';
import AppStoreBadgeIcon from '../../assets/images/app-store-badge-1680x498.svg';
import GooglePlayBadgeIcon from '../../assets/images/google-play-badge-1680x498.svg';
import QRCode from 'qrcode.react';
import SecurityForm from '../../forms/profile/SecurityForm';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import TitleBar from '../../containers/TitleBar';

@mapStateToProps((state) => ({
  profile: state.profile,
}))
class Security extends Component {
  constructor(props) {
    super(props);

    this.state = {
      instructions: false,
    };
  }

  componentDidMount() {
    listActiveModules();

    createSidebar({
      title: __('submenu.title.profile-settings'),
      items: [
        {
          title: __('submenu.title.personal-details'),
          link: () => '/profile/details',
        },
        {
          title: __('submenu.title.security'),
          link: () => '/profile/security',
          active: true,
        },
        {
          title: __('submenu.title.notifications'),
          link: () => '/profile/notifications',
        },
        {
          title: __('submenu.title.notifications.settings'),
          link: () => '/profile/notifications/settings',
        },
      ],
    });

    readProfile();
  }

  @autobind
  _handleChangePasswordSubmit(formData) {
    return validateForm(
      updatePassword(formData).then(() =>
        setNotification(__('profile-settings.security.password-updated'))
      )
    );
  }

  @autobind
  _handleTwoFactorCodeSubmit(formData) {
    return validateForm(
      enableTwoFactorAuthentication(formData).then(() => {
        setNotification(
          __('profile-settings.security.two-factor-authentication-enabled')
        );

        this.setState({ instructions: false });
      })
    );
  }

  @autobind
  _disableTwoFactorAuthentication() {
    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description:
        'Do you really want to disable two-factor authentication?\n\nIf you do so, you should also remove code generator for VOLUM3 from your Google Authenticator app.',
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: 'Yes, Disable',
          onClick: () => {
            disableTwoFactorAuthentication().then(() => {
              hideAlertbox();

              setNotification(
                __(
                  'profile-settings.security.two-factor-authentication-disabled'
                )
              );
              readProfile();
            });
          },
        },
      ],
    });
  }

  render() {
    const { profile } = this.props;
    const { instructions } = this.state;

    return (
      <>
        <TitleBar toolbar title={__('security')} />
        <div className={styles.header}>
          <div className={styles.title}>
            {profile.title && _.upperFirst(profile.title) + ' '}
            {profile.firstname} {profile.lastname}
          </div>
          <div className={styles.subtitle}>
            {!_.isEmpty(profile.disciplines) &&
              _.map(profile.disciplines, (discipline) =>
                getLocalizedListValue(
                  this.props.localization.disciplines,
                  discipline,
                  'profession'
                )
              ).join(', ') + ' · '}
            {profile.company} · {profile.email}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            {__('profile-settings.security.password-settings')}
          </div>
          <ChangePasswordForm onSubmit={this._handleChangePasswordSubmit} />
          <div className={styles.title}>
            {__('profile-settings.security.two-factor-authentication')}
          </div>
          <div className={styles.text}>
            {__(
              'profile-settings.security.secure-account-two-factor-authentication.prevents-devices-logging-account'
            )}
            <strong className={styles.underline}>
              {__('profile-settings.security.even-they-know-password')}
            </strong>
            .<br />
            <br />
            {__(
              'profile-settings.security.login-account-authentication-code-phone-tablet'
            )}
            <div className={styles.info}>
              {__(
                'profile-settings.security-two-factor-authentication-account-currently'
              )}{' '}
              {profile.two_factor ? (
                <span className={styles.enabled}>
                  {__(
                    'profile-settings.security.two-factor-authentication.enabled'
                  )}
                </span>
              ) : (
                <span>
                  {__(
                    'profile-settings.security.two-factor-authentication.disabled'
                  )}
                </span>
              )}
              .
            </div>
            {profile.two_factor == true && (
              <Button
                medium
                red
                middleText={__(
                  'profile-settings.security.disable-two-factor-authentication'
                )}
                onClick={this._disableTwoFactorAuthentication}
              />
            )}
            {profile.two_factor == false && (
              <>
                {!instructions && (
                  <Button
                    medium
                    green
                    middleText={__('button.enable-two-factor-authentication')}
                    onClick={() => this.setState({ instructions: true })}
                  />
                )}
                {instructions && (
                  <div className={styles.instructions}>
                    <div className={styles.step}>
                      {__(
                        'profile-settings.security.two-factor-authentication.install-google-authenticator-mobile-tablet'
                      )}
                      <div className={styles.badges}>
                        <a
                          href='https://apps.apple.com/us/app/google-authenticator/id388497605'
                          target='_blank'
                        >
                          <AppStoreBadgeIcon />
                        </a>
                        <a
                          href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
                          target='_blank'
                        >
                          <GooglePlayBadgeIcon />
                        </a>
                      </div>
                    </div>
                    <div className={styles.step}>
                      {__(
                        'profile-settings.security.two-factor-authentication.scan-qr-code-google-authenticator-app'
                      )}
                      {profile.two_factor_url && (
                        <QRCode
                          className={styles.qrCode}
                          value={profile.two_factor_url}
                        />
                      )}
                    </div>
                    <div className={styles.step}>
                      {__(
                        'profile-settings.security.two-factor-authentication.enter-code-from-authenticator-app-below'
                      )}
                      <SecurityForm
                        onSubmit={this._handleTwoFactorCodeSubmit}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Security;
