import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/working_set_specifications/Notes.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import autobind from 'autobind-decorator';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../../core/utils';
import CloseMediumIcon from '../../assets/images/close-middle-15x15.svg';
import EditIcon from '../../assets/images/edit-16x16.svg';
import { ReactSortable } from 'react-sortablejs';
import GrabberIcon from '../../assets/images/grabber-16x16.svg';
import { setNotification } from '../../redux/actions/general/notification';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import Tooltip from '../../components/Tooltip';
import {
  deleteSpecificationProductNote,
  readWorkingSetSpecification,
  reorderSpecificationProductNotes,
  updateSpecificationProjectNote,
} from '../../redux/actions/table/working_set_specifications';
import ProjectNoteForm from '../../forms/working_set_specifications/ProjectNoteForm';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import LogoIcon from '../../assets/images/logo-50x50.svg';

@mapStateToProps((state) => ({
  store: state.table.working_set_specifications,
}))
class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
    };
  }

  @autobind
  _changeNoteOrder(category, notes) {
    const { flyout } = this.props.store;

    setTableParams('working_set_specifications', {
      flyout: {
        ...flyout,
        notes: _.map(flyout.notes, (note, i) => {
          return note.category_id == category
            ? {
                ...note,
                order: _.findIndex(notes, ['id', note.id]),
              }
            : note;
        }),
      },
    });
  }

  @autobind
  _changeNoteCategoryOrder(categories) {
    const { flyout } = this.props.store;

    setTableParams('working_set_specifications', {
      flyout: {
        ...flyout,
        notes: _.map(
          _.transform(
            categories,
            (result, category) => {
              _.each(_.sortBy(flyout.notes, 'order'), (note) => {
                if (note.category_id == category.value) {
                  result.push(note);
                }
              });
            },
            []
          ),
          (note, i) => ({
            ...note,
            order: i,
          })
        ),
      },
    });
  }

  @autobind
  _toggleCollapseCategory(category) {
    this.setState({
      expanded: _.xor(this.state.expanded, [category]),
    });
  }

  @autobind
  _handleSubmit(formData) {
    return validateForm(
      updateSpecificationProjectNote(this.props.store.flyout.id, formData).then(
        () => {
          setNotification(
            __(
              'working-set-specifications-flyout.specification-project-note-updated'
            )
          );

          this.props._refetchData().then(({ response }) => {
            setTableParams('working_set_specifications', {
              flyout: response.data,
            });
          });
        }
      )
    );
  }

  componentDidMount() {
    const { notes } = this.props.store.flyout;

    this.setState({
      expanded: _.map(_.groupBy(notes, 'category_id'), (data, category_id) => {
        return Number(category_id);
      }),
    });
  }

  componentDidUpdate(prevProps) {
    const { notes } = this.props.store.flyout;

    if (
      // _.get(prevProps, 'store.flyout.notes') &&
      // _.get(this.props, 'store.flyout.notes') &&
      !_.isEqual(
        _.map(_.get(prevProps, 'store.flyout.notes'), 'id'),
        _.map(_.get(this.props, 'store.flyout.notes'), 'id')
      )
    ) {
      this.setState({
        expanded: _.map(
          _.groupBy(notes, 'category_id'),
          (data, category_id) => {
            return Number(category_id);
          }
        ),
      });
    }
  }

  render() {
    const { collapsed, store, readOnly } = this.props;
    const { flyout, language_id } = store;
    const { expanded } = this.state;

    let notes = _.sortBy(flyout.notes, 'order');

    let note_categories = _.sortBy(
      _.transform(
        notes,
        (result, note) => {
          if (!result.find((data) => data.value == note.category_id)) {
            result.push({
              label: getLocalized(note.category_name, language_id),
              value: note.category_id,
              order: note.order,
            });
          }
        },
        []
      ),
      'order'
    );

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        <ProjectNoteForm
          readOnly={readOnly}
          onSubmit={this._handleSubmit}
          initialValues={{
            project_note: flyout.project_note || {},
          }}
        />

        <ReactSortable
          list={note_categories}
          setList={this._changeNoteCategoryOrder}
          animation={200}
          disabled={readOnly}
          delayOnTouchStart={true}
          onEnd={() => {
            reorderSpecificationProductNotes(
              flyout.id,
              _.map(flyout.notes, 'id')
            ).then(() => {
              setNotification(
                __('product-attributes-group-order.saved.notification')
              );
            });
          }}
          handle={'.' + styles.grabber}
        >
          {_.map(note_categories, (category) => {
            const filtered_notes = _.sortBy(
              _.filter(notes, ['category_id', category.value]),
              'order'
            );

            return (
              <div className={styles.category} key={category.value}>
                <div
                  className={styles.title}
                  onClick={() => this._toggleCollapseCategory(category.value)}
                >
                  <div className={styles.grabber}>
                    <GrabberIcon />
                  </div>
                  <ArrowDownMiddleIcon
                    className={classNames(
                      styles.collapse,
                      !_.includes(expanded, category.value) && styles.collapsed
                    )}
                  />
                  {category.label}
                </div>
                <ReactSortable
                  disabled={readOnly}
                  list={filtered_notes}
                  setList={(list) =>
                    this._changeNoteOrder(category.value, list)
                  }
                  animation={200}
                  delayOnTouchStart={true}
                  onEnd={() => {
                    reorderSpecificationProductNotes(flyout.id, [
                      ..._.map(filtered_notes, 'id'),
                      ..._.sortBy(
                        _.filter(notes, ['category_id', !category.value]),
                        'order'
                      ),
                    ]).then(() => {
                      setNotification(
                        __(
                          'working-set-specifications.instructions.specification-instructions-order-saved'
                        )
                      );
                    });
                  }}
                  handle={'.' + styles.grabber}
                  className={styles.content}
                >
                  {_.includes(expanded, category.value) &&
                    _.map(filtered_notes, (note) => {
                      return (
                        <div
                          className={classNames(styles.flex, styles.row)}
                          key={note.id}
                        >
                          <div className={styles.grabber}>
                            <GrabberIcon />
                          </div>
                          <div className={styles.note}>
                            <span>&mdash;</span>

                            <span>{getLocalized(note.text, language_id)}</span>
                          </div>

                          <div className={styles.action}>
                            <div
                              className={classNames(
                                styles.bubble,
                                note.company_id ? styles.company : styles.system
                              )}
                            >
                              {note.company_id ? (
                                <Tooltip
                                  text={__(
                                    'specifications.current-set.flyout.notes.my-company-note'
                                  )}
                                >
                                  MY
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  text={__(
                                    'specifications.current-set.flyout.notes.system-note'
                                  )}
                                >
                                  <LogoIcon />
                                </Tooltip>
                              )}
                            </div>

                            {(note.company_id || !readOnly) && (
                              <div className={styles.edit}>
                                <EditIcon
                                  onClick={() =>
                                    setTableParams(
                                      'working_set_specifications',
                                      {
                                        edit_specification_product_note_wizard: true,
                                        edit_specification_product_note_data:
                                          note,
                                      }
                                    )
                                  }
                                />
                              </div>
                            )}

                            {!readOnly && (
                              <div className={styles.remove}>
                                <CloseMediumIcon
                                  onClick={() =>
                                    showAlertbox({
                                      color: 'red',
                                      title: __('general.alert.are-you-sure'),
                                      description: __(
                                        'working-set-specifications.instructions.are-you-sure-remove-this-clause'
                                      ),
                                      buttons: [
                                        {
                                          color: 'lightGray',
                                          text: __('general.alert.no-close'),
                                          onClick: 'close',
                                        },
                                        {
                                          color: 'gray',
                                          text: __('general.alert.yes-delete'),
                                          onClick: () =>
                                            deleteSpecificationProductNote(
                                              flyout.id,
                                              note.id
                                            ).then(() => {
                                              hideAlertbox();

                                              setNotification(
                                                __(
                                                  'working-set-specifications.instructions.selected-clause-deleted'
                                                )
                                              );

                                              setTableParams(
                                                'working_set_specifications',
                                                {
                                                  flyout: {
                                                    ...flyout,
                                                    notes: _.reject(notes, [
                                                      'id',
                                                      note.id,
                                                    ]),
                                                  },
                                                }
                                              );
                                            }),
                                        },
                                      ],
                                    })
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </ReactSortable>
              </div>
            );
          })}
        </ReactSortable>

        {!readOnly && !flyout?.is_shared && (
          <div
            className={classNames(
              styles.addRow,
              _.size(notes) == 0 && styles.left
            )}
          >
            <button
              type='button'
              onClick={() =>
                setTableParams('working_set_specifications', {
                  add_specification_product_note_wizard: true,
                })
              }
            >
              {__('button.add-clause')}
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default Notes;
