import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/working_set_specifications/WorkingSetSpecificationsFlyout.scss';
import {
  __,
  getActiveStage,
  getLocalized,
  mapStateToProps,
  redirect,
  setTableParams,
  toBoolean,
  validateForm,
} from '../../core/utils';
import CloseBigIcon from '../../assets/images/close-big-15x15.svg';
import Tooltip from '../../components/Tooltip';
import classNames from 'classnames';
import GlobeIcon from '../../assets/images/globe-16x16.svg';
import * as _ from 'lodash';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import Select from '../../components/Select';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import { Element, ScrollLink, scrollSpy } from 'react-scroll';
import autobind from 'autobind-decorator';
import BasicDataForm from '../../forms/working_set_specifications/BasicDataForm';
import { setNotification } from '../../redux/actions/general/notification';
import {
  readSharedSpecification,
  updateSpecificationBasicData,
} from '../../redux/actions/table/working_set_specifications';
import Files from './Files';
import Attributes from './Attributes';
import ReadOnlyAttributes from './read_only/Attributes';
import Notes from './Notes';
import { SubmissionError } from 'redux-form';
import Drawings from './Drawings';
import { withRouter } from 'react-router';
import History from './History';
import SpecificationFromProductIcon from '../../assets/images/specification-from-product-24x24.svg';
import SpecificationByArchitectIcon from '../../assets/images/specification-by-architect-24x24.svg';
import PrintIcon from '../../assets/images/print-16x16.svg';
import Quantities from './Quantities';
import { readSpecificationSetItems } from '../../redux/actions/table/specification_sets';

@mapStateToProps((state) => ({
  store: state.table.working_set_specifications,
  auth: state.auth,
  tab_opened: _.get(state.table, ['working_set_specifications', 'tab']),
}))
@withRouter
@subscribe(Localization, 'localization')
class SharedSpecificationsFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language_switcher: false,
      collapsed_tabs: ['properties'], //fix for scrollSpy not working on init
      highlighted_tab: null,
    };

    setTableParams('working_set_specifications', {
      language_id: _.findKey(
        props.store.flyout.languages,
        (primary) => primary == true
      ),
    });

    this.highlight_timeout = null;

    this.tab_refs = [];
  }

  componentDidMount() {
    scrollSpy.update();

    this.setState({
      collapsed_tabs: [],
    });

    if (this.props.tab_opened) {
      setTimeout(() => {
        this.tab_refs[this.props.tab_opened] &&
          this.tab_refs[this.props.tab_opened].click();

        setTableParams('working_set_specifications', {
          tab: undefined,
        });
      }, 500);
    }
  }

  @autobind
  _toggleCollapseTab(tab) {
    this.setState({
      collapsed_tabs: _.xor(this.state.collapsed_tabs, [tab]),
    });
  }

  @autobind
  _highlightSection(section) {
    this.setState({ highlighted_tab: section }, () => {
      if (this.highlight_timeout) clearTimeout(this.highlight_timeout);

      this.highlight_timeout = setTimeout(() => {
        this.setState({ highlighted_tab: null });
      }, 200);
    });
  }

  @autobind
  _toggleCollapseTabs() {
    let all_tabs = [
      'properties',
      'attributes',
      'quantity',
      'drawings',
      'images',
      'clauses',
      'files',
      'history',
    ];

    if (_.size(this.state.collapsed_tabs) == _.size(all_tabs)) {
      this.setState({
        collapsed_tabs: [],
      });
    } else {
      this.setState({
        collapsed_tabs: all_tabs,
      });
    }

    this.refs.scroll.scrollTop = 0;
  }

  @autobind
  _refetchData() {
    readSharedSpecification(this.props.store.flyout.id).then(({ response }) => {
      setTableParams('working_set_specifications', {
        flyout: { ...response.data, is_shared: true },
      });
    });
  }

  @autobind
  _handleBasicDataSubmit(formData) {
    formData = {
      ...formData,
      description: Object.assign({}, formData.description),
      title: Object.assign({}, formData.title),
    };

    return validateForm(
      updateSpecificationBasicData(this.props.store.flyout.id, formData).then(
        () => {
          this._refetchData();

          setNotification(
            __(
              'working-set-specifications-flyout.specification-properties-updated'
            )
          );
        }
      )
    ).catch(({ errors }) => {
      const { language_id } = this.props.store;

      if (_.has(errors, 'description')) {
        throw new SubmissionError({
          description: {
            [language_id]: errors.description,
          },
        });
      }
    });
  }

  @autobind
  _handleOnClose() {
    setTimeout(() => {
      setTableParams('working_set_specifications', {
        clicked_row: undefined,
      });
    }, 100);

    const specification_set = this.props.match.params.specification_set;

    if (specification_set) {
      readSpecificationSetItems(specification_set).then(() => {
        //readSpecificationSetDynamicColumns(specification_set);
      });
    }
  }

  render() {
    const { auth } = this.props;
    const { language_id, flyout } = this.props.store;
    const { readOnly } = this.props;
    const { language_switcher, collapsed_tabs, highlighted_tab } = this.state;

    const { specification_groups, specification_subgroups } =
      this.props.localization;

    const languages = _.map(_.keys(flyout.languages), (language_id) => ({
      label: _.get(this.props.localization.languages, [language_id, 'name']),
      code: _.get(this.props.localization.languages, [language_id, 'code']),
      value: language_id,
    }));

    const specification_subgroup =
      _.get(specification_subgroups, [flyout.specification_subgroup_id]) || {};
    const specification_group =
      _.get(
        specification_groups,
        _.get(specification_subgroup, 'specification_group_id')
      ) || {};

    const specification_code =
      getLocalized(specification_group.code, flyout.project_language, 1) +
      specification_subgroup.code +
      _.padStart(flyout.code, 2, '0');

    const TabLink = ScrollLink((props) => (
      <span
        ref={(ref) => (this.tab_refs[props.target] = ref)}
        onClick={(e) => {
          this.setState({
            collapsed_tabs: _.reject(
              collapsed_tabs,
              (section) => section == props.target
            ),
          });

          this._highlightSection(props.target);

          props.onClick(e);
        }}
        className={props.className}
      >
        {props.children}
      </span>
    ));

    const Tab = (props) => (
      <TabLink
        target={props.target}
        activeClass={styles.active}
        to={props.target}
        spy={true}
        smooth={true}
        duration={500}
        container={this.refs.scroll}
      >
        {props.children}
      </TabLink>
    );

    const specification_title = _.filter(
      [_.get(flyout.description, language_id), flyout.model, flyout.brand],
      _.identity
    ).join(' / ');

    let total_tabs = 11;

    if (_.size(flyout.options) > 1) {
      total_tabs += 1;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            <div>
              {flyout.product_id ? (
                <Tooltip
                  text={__(
                    'specifications.working-set-specifications.specification-created-from-product'
                  )}
                >
                  <SpecificationFromProductIcon className={styles.type} />
                </Tooltip>
              ) : (
                <Tooltip
                  text={__(
                    'specifications.working-set-specifications.specification'
                  )}
                >
                  <SpecificationByArchitectIcon className={styles.type} />
                </Tooltip>
              )}
              {'↳ '}
              <strong>
                {specification_code}
                {!_.isEmpty(specification_title) ? ':' : ''}
              </strong>
            </div>
            <Tooltip
              text={__(
                'specifications.working-set.flyout.close-specification-flyout'
              )}
            >
              <CloseBigIcon
                className={styles.close}
                onClick={this._handleOnClose}
              />
            </Tooltip>
          </div>

          <div className={styles.actions}>
            <Tooltip
              text={__(
                'specifications.working-set-specifications.flyout.print-specification'
              )}
            >
              <PrintIcon
                onClick={(e) => {
                  e.stopPropagation();

                  setTableParams('working_set_specifications', {
                    print_specifications_wizard: 'print_selected',
                    specification_ids: [flyout.id],
                  });
                }}
              />
            </Tooltip>
          </div>
        </div>
        <div className={styles.toolbar}>
          <Tooltip
            placement='left'
            text={__(
              'working-set-specifications-flyout.specification-in-working-set'
            )}
          >
            <div className={classNames(styles.baloon, styles.set)}>
              {__('specifications.working-set.flyout.working-set')}
            </div>
          </Tooltip>

          {!flyout.main && (
            <Tooltip
              text={__(
                'working-set-specifications-flyout.alternative-specification'
              )}
            >
              <div className={classNames(styles.baloon, styles.set)}>
                {__('specifications.working-set.flyout.alternative')}
              </div>
            </Tooltip>
          )}

          {language_id && (
            <div className={styles.language}>
              <Select
                input={{
                  value: language_id,
                  onChange: (language_id) =>
                    setTableParams('working_set_specifications', {
                      language_id,
                    }),
                  onBlur: () => this.setState({ language_switcher: false }),
                }}
                meta={{
                  active: language_switcher,
                }}
                label={__(
                  'working-set-specifications-flyout.specification-language'
                )}
                options={languages}
                left={-75}
                top={40}
                noSort
                dropdown
                icon={<GlobeIcon className={styles.globeIcon} />}
                wrapper={(handleOpen, activeItem) => (
                  <Tooltip
                    text={__(
                      'working-set-specifications-flyout.change-specification-language'
                    )}
                  >
                    <div
                      className={styles.languageSwitcher}
                      onClick={() => this.setState({ language_switcher: true })}
                    >
                      <GlobeIcon className={styles.icon} />
                      {_.get(activeItem, 'code')}
                      <ArrowDownSmallIcon className={styles.arrow} />
                    </div>
                  </Tooltip>
                )}
              />
            </div>
          )}
        </div>
        <div className={styles.tabsWrapper}>
          <ArrowDownMiddleIcon
            className={classNames(
              styles.collapseAll,
              _.size(collapsed_tabs) == total_tabs && styles.collapsed
            )}
            onClick={this._toggleCollapseTabs}
          />
          <div className={styles.tabs}>
            <Tab target='properties'>
              {__('working-set-specifications.flyout.tabs.properties')}
            </Tab>
            <Tab target='attributes'>
              {__('working-set-specifications.flyout.tabs.attributes')}
            </Tab>
            <Tab target='clauses'>
              {__('working-set-specifications.flyout.tabs.instructions')}
            </Tab>
            <Tab target='quantity'>
              {__('working-set-specifications.flyout.tabs.quantity')}
            </Tab>
            <Tab target='drawings'>
              {__('working-set-specifications.flyout.tabs.drawings')}{' '}
              {!_.isEmpty(flyout.drawings) &&
                ' (' + _.size(flyout.drawings) + ')'}
            </Tab>
            {/* <Tab target='images'>
              {__('working-set-specifications.flyout.tabs.images')}{' '}
              {!_.isEmpty(flyout.images) && ' (' + _.size(flyout.images) + ')'}
            </Tab> */}
            <Tab target='files'>
              {__('working-set-specifications.flyout.tabs.files')}
              {(!_.isEmpty(flyout.files) || !_.isEmpty(flyout.images)) &&
                ' (' + (_.size(flyout.files) + _.size(flyout.images)) + ')'}
            </Tab>
            <Tab target='history'>
              {__('working-set-specifications.flyout.tabs.history')}
            </Tab>
          </div>
        </div>
        <div className={styles.content} ref='scroll'>
          <Element
            name='properties'
            className={classNames(
              styles.section,
              highlighted_tab == 'properties' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('properties')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'properties') && styles.collapsed
                )}
              />
              {__('working-set-specifications.flyout.tabs.properties')}
            </div>
            <BasicDataForm
              readOnly={readOnly}
              collapsed={_.includes(collapsed_tabs, 'properties')}
              onSubmit={this._handleBasicDataSubmit}
              initialValues={{
                ..._.pick(flyout, [
                  'model',
                  'description',
                  'supplier_company_id',
                  'supplier_contact_id',
                  'responsible_user_id',
                  'team_id',
                ]),
                brand: flyout.product_id ? flyout.brand : undefined,
                created_by: [flyout.created_by],
                updated_by: [flyout.updated_by],
                manufacturer_company_id: !flyout.product_id
                  ? flyout.manufacturer_company_id
                  : undefined,
                code:
                  getLocalized(
                    _.get(specification_groups, [
                      _.get(specification_subgroups, [
                        flyout.specification_subgroup_id,
                        'specification_group_id',
                      ]),
                      'code',
                    ]),
                    flyout.project_language
                  ) +
                  _.get(specification_subgroups, [
                    flyout.specification_subgroup_id,
                    'code',
                  ]) +
                  _.padStart(flyout.code, 2, '0'),
              }}
            />
          </Element>
          <Element
            name='attributes'
            className={classNames(
              styles.section,
              highlighted_tab == 'attributes' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('attributes')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'attributes') && styles.collapsed
                )}
              />
              {__('working-set-specifications.flyout.tabs.attributes')}
            </div>
            {flyout.product_id ||
            _.includes(['canceled'], flyout.status) ||
            readOnly ? (
              <ReadOnlyAttributes
                collapsed={_.includes(collapsed_tabs, 'attributes')}
                initialValues={_.sortBy(flyout.attribute_groups, 'priority')}
              />
            ) : (
              <Attributes
                collapsed={_.includes(collapsed_tabs, 'attributes')}
                _refetchData={this._refetchData}
                isShared={flyout.is_shared}
              />
            )}
          </Element>
          <Element
            name='clauses'
            className={classNames(
              styles.section,
              highlighted_tab == 'clauses' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('clauses')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'clauses') && styles.collapsed
                )}
              />
              {__('working-set-specifications.flyout.tabs.instructions')}
            </div>
            <Notes
              collapsed={_.includes(collapsed_tabs, 'clauses')}
              readOnly={readOnly}
              _refetchData={this._refetchData}
            />
          </Element>
          <Element
            name='quantity'
            className={classNames(
              styles.section,
              highlighted_tab == 'quantity' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('quantity')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'quantity') && styles.collapsed
                )}
              />
              {__('working-set-specifications.flyout.tabs.quantity')}
            </div>
            <Quantities
              readOnly={readOnly}
              collapsed={_.includes(collapsed_tabs, 'quantity')}
              readAction={this.props.readAction}
              isShared={flyout.is_shared}
              _refetchData={this._refetchData}
            />
          </Element>
          <Element
            name='drawings'
            className={classNames(
              styles.section,
              highlighted_tab == 'drawings' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('drawings')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'drawings') && styles.collapsed
                )}
              />
              {__('working-set-specifications.flyout.tabs.drawings')}
            </div>
            <Drawings
              collapsed={_.includes(collapsed_tabs, 'drawings')}
              readOnly={readOnly}
              _refetchData={this._refetchData}
            />
          </Element>
          <Element
            name='files'
            className={classNames(
              styles.section,
              highlighted_tab == 'files' && styles.highlighted
            )}
            _refetchData={this._refetchData}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('files')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'files') && styles.collapsed
                )}
              />
              {__('working-set-specifications.flyout.tabs.files-and-images')}
              {(!_.isEmpty(flyout.files) || !_.isEmpty(flyout.images)) &&
                ' (' + (_.size(flyout.files) + _.size(flyout.images)) + ')'}
            </div>
            <Files
              collapsed={_.includes(collapsed_tabs, 'files')}
              readOnly={readOnly}
            />
          </Element>
          <Element
            name='history'
            className={classNames(
              styles.section,
              highlighted_tab == 'history' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('history')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'history') && styles.collapsed
                )}
              />
              {__('task.flyout.history')}
            </div>
            <History
              collapsed={_.includes(collapsed_tabs, 'history')}
              data={flyout}
            />
          </Element>
        </div>
      </div>
    );
  }
}

export default SharedSpecificationsFlyout;
