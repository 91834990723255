import store from '../../../core/store';

export const listAllSharedModels = () => {
  return store.dispatch({
    action: 'table.all_shared_models/LIST_ALL_SHARED_MODELS',
    method: 'GET',
    endpoint: '/stages/shared_models',
    main: true,
  });
};
